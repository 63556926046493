import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class Disclaimer extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {

    return (

      <React.Fragment>
        
        <h2>Disclaimer</h2>
        <div id="dialog" className="ui-dialog-content ui-widget-content">
          <div>
            <h4 id="terms-into">Use of Activate Application</h4>
            <p>
              To access the Activate Application, you must read and accept the Terms and Conditions by clicking on the Accept button below.
            </p>
            <p>
              The information in this Application is intended solely for use by the public, who accept full responsibility for their use of it.
            </p>
            <p>
              While the department endeavours to ensure that the content of the Application is both current and accurate, errors can occur.
            </p>
          </div>
          <div>
            <h4 id="terms-conditions">Terms and conditions of use</h4>
            <p>
              The Activate Application (the ‘Service’) is provided and administered by the NSW Department of Industry—Lands & Water (the ‘department’). The Service is a public access application for the public to view Crown Land.
            </p>
            <p>
              This Service is subject to the following conditions.
            </p>
          </div>
          <div>
            <h4 id="terms-acceptance">1. Acceptance</h4>
              <p>
                1.1. By clicking ‘I have read and accept the terms and conditions of the site’ you confirm that you understand and agree to these terms and conditions.
              </p>
              <p>
                1.2. These terms and conditions supersede and replace any terms of use you may have previously accepted governing your use of the Service.
               </p>
              <p>
                1.3. The department may amend these terms and conditions at any time. You will be required to accept the new terms and conditions before you will be able to access the Service.
               </p>
          </div>
          <div>
            <h4 id="terms-responibility">2. Your responsibility</h4>
            <p>
              2.1. You must:
            </p>
            <ul>
              <li>use the Service only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the Service by any third party.</li>
              <li>not use any software (for example, bots, scraper tools) or other automatic devices to access, monitor or copy the Service or its contents.</li>
              <li>not use any device, software or routine to interfere or attempt to interfere with the proper working of the Service.</li>
              <li>not use any device, software or routine to abuse the Service or emulate human interaction and operation.</li>
              <li>not post or transmit via the Service any unlawful, defamatory, obscene, offensive or scandalous material, or any material that constitutes or encourages conduct that would contravene a law.</li>
            </ul>
          </div>
          <div>
            <h4 id="terms-disclaimer">3. Disclaimer</h4>
            <p>
              3.1. You are permitted to access the Service on the basis that NSW Department of Industry, the State of New South Wales and its employees and agents will have no liability (including but not limited to liability by reason of negligence) to you for any loss, damage, cost or expense whether direct, indirect consequential or special, incurred by, or arising by reason of, any person using or relying on the Service and whether caused by reason of any error, omission or misrepresentation in the Service or otherwise.
            </p>
            <p>
              3.2. The department does not warrant that the Service is error-free, or that any defects in the Service will be rectified, or that you will have continuous access to the Service.
            </p>
            <p>
              3.3. The availability of the Service may be dependent upon your software, hardware, functionality, location, site conditions and network bandwidth. The department does not guarantee the compatibility or reliability of such variables.
            </p>
          </div>
          <div>
            <h4 id="terms-intellectual">4. Intellectual property</h4>
            <p>
              4.1. All intellectual property, including copyright and trademark, in the Service is the property of the department or its licensors.
            </p>
            <p>
              4.2. You must not remove, obscure or alter any copyright or proprietary rights notices which may be contained within the Service.
            </p>
            <p>
              Copyright in the material accessible through the Service is owned by the department or our licensors.
            </p>
            <p>
              4.3. Unless otherwise stated, the material included in the Service is provided under a Creative Commons Attribution (CC-BY) license and may be freely used provided you attribute the department as follows: Copyright NSW Department of Industry. For current information see <a href="https://www.industry.nsw.gov.au/lands/reserves/portal" target="_blank" rel="noreferrer">https://www.industry.nsw.gov.au/lands/reserves/portal</a>
            </p>
            <p>
              4.4. You must promptly report to the department any infringement or suspected infringement of any copyright or other intellectual property rights referred to in this section.
            </p>
          </div>
          <div>
            <h4 id="terms-privacy">5. Privacy</h4>
            <p>
              5.1. The departments’ privacy practices are regulated by the New South Wales Privacy and Personal Information Protection Act 1998. For more information contact the Office of the NSW Privacy Commissioner (<a href="www.lawlink.nsw.gov.au/privacynsw" target="_blank" rel="noreferrer">www.lawlink.nsw.gov.au/privacynsw</a>).
            </p>
            <p>
              5.2. The department will only collect personal information for a lawful purpose that directly relates to our primary function as a NSW Government agency and for obtaining feedback about the effectiveness of our services. The department will not collect any more information than is necessary for it to fulfil these functions.
            </p>
            <p>
              5.3. The department will not disclose your personal information to anyone without your consent unless legally required to do so.
            </p>
            <p>
              5.4. The supply of personal information is voluntary, however your failure to supply the information requested may result in your enquiry not being addressed.
            </p>
            <p>
              5.5. You may request access to your personal information held by the department by contacting:</p>
            <p>
              Governance & Information Access Unit<br></br>
              NSW Department of Industry<br></br>
              GPO Box 7060<br></br>
              Sydney NSW 2001<br></br>
              Phone +61 2 9995 0911<br></br>
              <a href="mailto:privacy@industry.nsw.gov.au">privacy@industry.nsw.gov.au</a>
            </p>
          </div>
          <div>
            <h4 id="terms-disputes">6. Disputes</h4>
            <p>
              If any dispute arises out of these terms and conditions, you agree to notify the department and provide 30 days to resolve the dispute before commencing court proceedings.
            </p>
          </div>
          <div>
            <h4 id="terms-entire">7. Entire agreement</h4>
            <p>
              These terms and conditions constitute the entire agreement between the department and you in connection with the Service.
            </p>
          </div>
          <div>
            <h4 id="terms-severance">8. Severance</h4>
            <p>
              If any of these terms are held to be invalid, unenforceable or illegal for any reason, the remaining terms will continue in full force.
            </p>
          </div>
          <div>
            <h4 id="terms-governing">9. Governing law</h4>
            <p>
              These terms of use are governed by the laws of New South Wales and you agree to submit to the exclusive jurisdiction of the courts of New South Wales.
            </p>
          </div>
        </div>
        <div className="disclaimerbutton">
          <Button className="headerButton" onClick={this.props.handleDisclaimer}>
            Accept
          </Button>
        </div>
                
      </React.Fragment>
    )
  }
}

export default Disclaimer;
