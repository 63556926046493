/* This is the search form used on the Explore page */

import React, { Component } from 'react';
import { Form, Button, Col, Row, ProgressBar } from 'react-bootstrap';
import { layerConfig } from "../config/layerConfig";
import { SearchBar } from './SearchBar';


class FindForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      selected: []
    }

  }

  componentDidMount(){
      // console.log("search form mounted")
  }

  componentWillUnmount() {
      // console.log("search form will unmount")
  }

  render() {

    let searchMessages = [];
    if (this.props.searchMessages) {
      searchMessages = this.props.searchMessages.map((message, id) =>
        <li key={id}>{message}</li>
      );
    }
    let progress = null;
    if (this.props.nearbyBuffers && this.props.nearbyBuffers.length > 0) {
      progress = (this.props.queryResponseCount / this.props.nearbyBuffers.length) * 100;
    } else if (this.props.overlapFeatureCount && this.props.overlapFeatureCount > 0) {
      progress = (this.props.queryResponseCount / this.props.overlapFeatureCount) * 100;
    }

    // Adjust the Status Type dropdown depending on the value of the Class Subtype dropdown
    let selectOptions = {
      "any": [],
      "Crown Parcel": ["Any", "Crown Land", "Crown Land / Status Unknown", "Non Crown Land", "Undefined"],
      "Crown Road": ["Any", "Crown Road", "Shared Crown / Council Road", "Undefined"],
      "Crown Waterway": []
    }

    let statusTypes = selectOptions[this.props.classSubtype].map((item, id) =>
      <option key={id} value={item}>{item}</option>
    );

    let paddingClass = "pl-1 mr-0";

    let areaField;
    this.state.mode === 'public' ? areaField = layerConfig().public.clcLayer.areaField : areaField = layerConfig().internal.clcLayer.areaField;
  
    return (
      <div id="findPane" className="mt-4">
        
        <SearchBar
          clearGeocodeResults = {this.props.clearGeocodeResults}
          geocodeOptions={this.props.geocodeOptions}
          handleGeocodeChange={this.props.handleGeocodeChange}
          handleGeocodeOptionsUpdate={this.props.handleGeocodeOptionsUpdate}
          mode={this.props.mode}
          selectedGeocode={this.props.selectedGeocode}
        ></SearchBar>
        <hr></hr>

        {this.props.overlayLayer ?
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="pl-0">
              <Button
                onClick={() => this.props.toggleState('useOverlayLayer')}
                variant=""
                className="pr-1"
              >
                { this.props.useOverlayLayer ? 
                  <img className="mr-1 resultsIcon"
                    src="./img/tickbox.svg"
                    alt="tick"
                  />
                : 
                  <img className="mr-1 resultsIcon"
                    src="./img/tickbox_empty.svg"
                    alt="untick"
                  />
                }
                <span className="">Within Identified Overlap layer</span>
              </Button>
            </Col>
          </Row>
        : null}

        
        <Form id="FindForm" className="form pt-2 pb-5">
          
          <React.Fragment>
            <h2>
            <img className="mr-1 findicon"
                  src="./img/find.svg"
                  alt="filter"
              />
              Filter</h2>
            <Button
              id="btnClear"
              className="pl-0 pr-0 mt-4"
              variant="turquoiseText"
              onClick={() => this.props.resetSettings()}
              disabled={this.props.searchPending}
            >
              <img className="mr-1 resultsIcon"
                  src="./img/clear.svg"
               //   onMouseOver={e => (e.currentTarget.src = "./img/clear_blue.svg")}
               //   onMouseOut={e => (e.currentTarget.src ="./img/clear.svg")}
                  alt="clear"
              />
              CLEAR
            </Button>
              
          <hr/>
          <p className="instructionalText mt-4">Find Crown Estate areas within the current map view based on specific criteria.</p>
          
          {this.props.mode === 'internal' ? 
            <React.Fragment>
              <Form.Group>
                <Form.Row className={paddingClass}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Label>Class Subtype:</Form.Label>
                    <Form.Control as="select" data-field="classSubtype" name="classSubtype" value={this.props.classSubtype} onChange={this.props.handleSelectChange} disabled={this.props.searchPending}>
                      <option value="any">Any</option>
                      <option value="Crown Parcel">Crown Parcel</option>
                      <option value="Crown Road">Crown Road</option>
                      <option value="Crown Waterway">Crown Waterway</option>
                    </Form.Control>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Label>Status Type:</Form.Label>
                    <Form.Control as="select" data-field="statusType" name="statusType" value={this.props.statusType} onChange={this.props.handleSelectChange} disabled={this.props.searchPending || statusTypes.length === 0}>
                      {statusTypes}
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Row className={"mt-4 " + (this.props.page !== 'explore' ? "left-15" : "")}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <h2>Reserve:</h2>
                    <Form.Control
                      as="select"
                      data-field={layerConfig().internal.clcLayer.reserveField}
                      name="reserve"
                      value={this.props.reserve}
                      onChange={this.props.handleSelectChange}
                      disabled={this.props.searchPending}
                    >
                      <option value="any">Any</option>
                      <option value="yes">Yes - reserve present</option>
                      <option value="no">No - reserve not present</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form.Group>
            </React.Fragment>
          : null}

          {areaField ? 
            <Form.Group>
              <Form.Row className={"mt-4 " + (this.props.page !== 'explore' ? "left-15" : "")}>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <h2>Size (square metres):</h2>
                </Col>
              </Form.Row>

              <Form.Row className={(this.props.page !== 'explore' ? "pl-1" : "")}>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Form.Control
                    as="input"
                    type="number"
                    min={0}
                    max={this.props.areaMax}
                    name="areaMin"
                    placeholder="Min"
                    data-field={areaField}
                    data-numeric={true}
                    value={this.props.areaMin || ''}
                    onChange={this.props.handleSelectChange}
                    disabled={this.props.searchPending}
                    className={(this.props.page !== 'explore' ? "inputMinSize" : "")}
                  />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <Form.Control
                    as="input"
                    type="number"
                    min={this.props.areaMin}
                    name="areaMax"
                    placeholder="Max"
                    data-field={areaField}
                    data-numeric={true}
                    value={this.props.areaMax || ''}
                    onChange={this.props.handleSelectChange}
                    disabled={this.props.searchPending}
                    className={(this.props.page !== 'explore' ? "inputMaxSize" : "")}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          : null}

          {this.props.mode === 'internal' ? 
            <Form.Group>
              <Form.Row className={"mt-4 " + (this.props.page !== 'explore' ? "left-15" : "")}>
                <Col md={12}>
                  <h2>Functional Economic Driver:</h2>
                </Col>
              </Form.Row>
              <Form.Row className={(this.props.page !== 'explore' ? "left-15" : "")}>
                <Col xs={6} sm={6} md={6} lg={6}>
                  
                  <Form.Control as="select"  name="fer" value={this.props.fer} onChange={this.props.handleSelectChange} disabled={this.props.searchPending}>
                    <option value="any">Any</option>
                      {layerConfig().internal.clcLayer.ferFields.map((e, key) => {
                        return <option key={key} value={e.name}>{e.label}</option>;
                      })}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Form.Group>
          : null }

            <Form.Group className="mt-4">
              <h2>Filter by Proximity</h2>
            </Form.Group>

            <Form.Group>
              <Form.Row className={paddingClass}>
                <Col sm={4}>
                  <label>Within</label>
                  <Form.Control id="txtDistance" as="input" type="number" min="1" max="10" name="nearbyDistance" value={this.props.nearbyDistance} onChange={this.props.handleSelectChange} disabled={this.props.searchPending}/>
                  <label>km of</label>
                </Col>
                <Col sm={4}>
                  <Form.Control as="select" name="nearbyLayer" value={this.props.nearbyLayer} onChange={this.props.handleNearbyLayerChange} disabled={this.props.searchPending}>
                    <option value="">Layer</option>
                    <option value="poiLayer">Points of interest</option>
                    <option value="npwsLayer">National Parks</option>

                  </Form.Control>
                </Col>
                <Col sm={4}>
                  <Form.Control as="select" data-layer={this.props.nearbyLayer} name="nearbyType" value={this.props.nearbyType} onChange={this.props.handleSelectChange} disabled={this.props.searchPending}>
                    <option value="">Type</option>
                    {this.props.nearbyTypes.map((e, key) => {
                      return <option key={key} value={e.value}>{e.alias}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Form.Group>
          </React.Fragment>
        </Form>

      

        {this.props.searchPending ? (
            <React.Fragment>
              <div id="loading" className="d-flex justify-content-center">
                <img className="mt-2" height="30px" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} />
              </div>
              <ul id="searchMessages">
                {searchMessages}
              </ul>

              {progress ? (
                <ProgressBar now={progress}/>
              ) : null}

            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row className={"mt-3 pl-3 btnSubmitFind " + (this.props.mode !== 'internal' ? "public" : "")}>

                <Col xs={12}>
                  
                  <Button
                    id="btnSubmitFind"
                    type="submit"
                    value="Submit"
                    className="btn btn-block clcButton mt-3" 
                    disabled={!this.props.searchReady || this.props.searchPending}
                    onClick={this.props.handleSearchSubmit}
                  >
                    FILTER
                  </Button>
                </Col>
                
              </Row>
              
            </React.Fragment>
            
            
          )}
          
      </div>
    );
  }
}

export { FindForm };
