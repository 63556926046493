import React, { Component } from 'react';
import { Modal, Container, Col, Row, Accordion, Card} from 'react-bootstrap';
import { sourcesConfig } from '../config/sourcesConfig.js';

class Sources extends Component {

  render() {

    let contents = sourcesConfig().sources.map((source, index) => {
      return (
        <React.Fragment key={"index" + index}>
          <h4 id={source.id}>{source.title}</h4>

          {source.children.map((child, index2) => {
            return (
              <React.Fragment key={"index2" + index2}>
                <Accordion.Toggle as={Card.Header} eventKey={index + "-" + index2+1}>
                  {child.title}
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={index + "-" + index2+1}>
                  <Card.Body>
                    <Container>
                      <Row className="description">
                      <Col xs={7}>
                          <p>{child.description}</p>
                          <h4>Terms of Use</h4>
                          <p className="licenceinfo">{child.licenseInformation}</p>
                        </Col>
                        <Col xs={5}>
                          <h4>Source</h4>
                          <p>{child.source}</p>
                          <h4>Last Updated</h4>
                          <p>{child.lastUpdated}</p>
                          <h4>Update Frequency</h4>
                          <p>{child.frequencyUpdated}</p>
                        </Col>
                        
                      </Row>
                      {child.url ? 
                        <Row>
                          <Col xs={12}>
                            <h4>More Information</h4>
                            <p><a target="_blank" href={child.url} rel="noreferrer">{child.url}</a></p>
                          </Col>
                        </Row>
                      : null }
                    </Container>
                    
                  </Card.Body>
                </Accordion.Collapse>
              </React.Fragment>
            )
          })}
        </React.Fragment>
      )
    })

    let sidebar = sourcesConfig().sources.map((source, index) => {
      return <li key={index}><a href={`#${source.id}`}>{source.title}</a></li>
    })
    return (
      <React.Fragment>
        <Modal
          id="sourcesModal"
          show={this.props.showSourcesModal}
          onHide={this.props.toggleSourcesModal}
          className="text-center"
        >
          <div className="modal-header2 modal-header--sticky">
            <button type="button" id="btnCloseSource" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggleSourcesModal}>
              <span aria-hidden="true" onClick={this.props.toggleSourcesModal}>&times;</span>
            </button>
          </div>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={3}>
                  <h4>Jump to:</h4>
                  {sidebar}
                </Col>
                <Col xs={9}>
                    <h1>Data Sources</h1>
                    <p>Acivate contains open data from a variety of authoritative sources. For more information see the Categories and Layers below.</p>
                    <Accordion>
                      {contents}
                    </Accordion>
                </Col>
              </Row>              
            </Container>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      
    )
  }
}

export default Sources ;
