const quickMapsConfig = () => {

    return {

        quickMaps: {
            community: {
                layers: [
                    {
                        title: "Community Profile - LGA"
                    }
                ]
            },

            crownFunding: {
                layers: [
                    {
                        title: "Crown Reserve Improvement Fund 2022"
                    }
                ]
            },

            watercourse: {
                layers: [
                    {
                        title: "Hydrography: Named Watercourse - LS"
                    },
                    {
                        title: "Hydrography: Named Watercourse - SS"
                    }
                ]
            },

            tourism: {
                layers: [
                    {
                        title: "Tourism Overnight Expenditure - Tourism Regions"
                    }
                ]
            },

            bushFire: {
                layers: [
                    {
                        title: "Bushfire Prone Land 2021 (Legend)"
                    },
                    {
                        title: "Bushfire Prone Land 2021 (Feature Layer)"
                    },
                    {
                        title: "Bushfire Prone Land 2021 (Vector Tile)"
                    },
                    {
                        title: "Bushfire Extent 2019 - 2020"
                    },
                    {
                        title: "Wild Fire History 2001 - 2016"
                    }
                ]
            },

            electorates: {
                layers: [
                    {
                        title: "State Electoral Districts"
                    }
                ]
            },

            nationalParks: {
                layers: [
                    {
                        title: "National Park and Wildlife Services Reserve"
                    }
                ]
            },


            floodPlanning: {
                layers: [
                    {
                        title: "Hazard: Flood Planning"
                    },
                    {
                        title: "Hydrography: Named Watercourse - LS"
                    }
                ]
            },

            localGovernmentAreas: {
                layers: [
                    {
                        title: "Local Government Areas"
                    }
                ]
            }
        }
    }
}

export { quickMapsConfig }
