import { layerConfig } from '../config/layerConfig.js';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.22' })

const loadExploreMenuLayers = (view) => {
  if (!view) { return null}
  loadModules(["esri/layers/GroupLayer", "esri/layers/FeatureLayer", "esri/layers/VectorTileLayer", "esri/layers/MapImageLayer"])
    .then(([GroupLayer, FeatureLayer, VectorTileLayer, MapImageLayer]) => {
      let map = view.map;

      // Load the hard-coded Explore Menu layers
      layerConfig().exploreLayers.reverse().forEach(layerConfig => {  
        layerConfig.childLayers.reverse().forEach(childLayer => {
          if (!map.layers.items.find(o => o.title === childLayer.title)) {
            if (childLayer.layerType === 'groupLayer'){
              let gLayer = new GroupLayer(childLayer);
              gLayer.exploreMenuLayer = true;
              if (childLayer.metadata) {
                gLayer.metadata = childLayer.metadata
              }
              gLayer.listMode = 'hide';
              map.add(gLayer);

              // Add the sublayers for this group layer
              childLayer.subLayers.forEach(subLayer => {
                let newLayer;
                if (subLayer.layerType === 'featureLayer'){
                  newLayer = new FeatureLayer(subLayer)
                } else if (subLayer.layerType === 'vectorTileLayer'){
                  newLayer = new VectorTileLayer(subLayer)
                } else if (subLayer.layerType === 'mapImageLayer'){
                  newLayer = new MapImageLayer(subLayer)
                } else {
                  console.error("Layer type is not supported", subLayer.title)
                }

                if (newLayer){
                  newLayer.exploreMenuLayer = true;
                  gLayer.add(newLayer);
                }
              })
            } else {
              console.error("Layer type is not supported", childLayer.title)
            }
          }            
        })
      })
    })
    .catch(err => {
      // handle any errors
      console.log("error",err);
    });
}

export { loadExploreMenuLayers };
