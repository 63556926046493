import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';

class OverlayLayer extends Component {
    constructor(props) {
      super(props);
      this.state = {
          activeLayerId: null,
          selectedOption: null
      }

    //   this.onSelectChange = this.onSelectChange.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.lookupLayerName = this.lookupLayerName.bind(this);
    }
  
    componentDidMount(){
        console.log("overlay layer mounted")
    }
  
    componentWillUnmount() {
        console.log("overlay layer will unmount")
    }

    lookupLayerName(id){
        // Retrieve the layer names from the map, based on the ID
        let name = id;
        for (let i =0; i < this.props.map.layers.length; i++){
            let layer = this.props.map.layers.items[i];
            if (layer.type === 'feature' && layer.id === id){
                name = layer.title
            } else if (layer.type === 'group'){
                for (let j=0; j < layer.layers.length; j++){
                    let layer2 = layer.layers.items[j];
                    if (layer2.id === id) {
                        name = layer2.title
                    }
                }
            }
        }

        return name;
    }

    handleChange = (selectedOption) => {
        let oldLayerId = null;
        if (this.state.selectedOption) {
            oldLayerId = this.state.selectedOption.value;
        }
        let newLayerId = selectedOption.value;
        this.setState({ selectedOption});
        this.props.updateOverlayLayers(oldLayerId, newLayerId);
    }

    render(){
        
        // The list of available overlay layers is the full list of feature layers, minus the currently-active layers
        let availableLayers = this.props.featureLayers.filter( el => {
            return !this.props.activeOverlayLayers.includes( el );
        });

        const options = availableLayers.map(layer => {
            let name = this.lookupLayerName(layer);
            return { value: layer, label: name}
        })
        const { selectedOption } = this.state;

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontSize: 14,
              fontWeight: 100,
              color: '#323232',
              ':hover': {
                borderColor: 'red'
              }
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),

        }
        // const customStyles = {
        //     control: base => ({
        //       ...base,
        //       borderColor: 'gray',
          
        //       ':hover': {
        //         borderColor: 'red'
        //       }
        //     })
        //   }

        return (
            <React.Fragment>
                <Form.Group className="pt-1 pl-0 pr-0 mb-4">
                    <Form.Row>
                        <Col sm={12}>
                            <label>{this.props.label}</label>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={options}
                                placeholder="Select the layer"
                                
                                // force dropdowns to have high z-index
                                menuPortalTarget={document.body}
                                styles={ customStyles }
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>

            </React.Fragment>
        )
    }
}


export { OverlayLayer };