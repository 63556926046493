import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

// This message appears if the display is smaller than md (ie 768px).
// It copies the style of the landing page - customise as required.
 
const MobileMessage = () => {
 return (
    <Container fluid id="mobileMessageContainer" className="d-md-none">
        <Row>
            <Col lg={8} className="text-center">
                <div id="mobileMessage">
                    <h4>Activate</h4>
                    <h2>This screen size is too small.</h2>
                    <p>Activate is optimised for larger screens, such as desktop, laptop or tablet.</p>
                </div>
            </Col>
        </Row>
    </Container>
    );
};

export default MobileMessage;