import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SearchBar } from './components/SearchBar';
import { Nav } from 'react-bootstrap';
import Header  from "./components/Header";
import {QuickMaps} from "./components/QuickMaps";
import Disclaimer from "./components/Disclaimer";
import Sources from './components/Sources';
import MobileMessage from './components/MobileMessage';
import Pdf from "./components/Activate_UserGuide.pdf";
import Pdf_internal from "./components/Activate_UserGuide_internal.pdf";

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab:'explore'
    };

  }

  handleTabSelect=(selectedTab)=>{
    this.setState({selectedTab:selectedTab});
  }
  redirectToTarget = (hash) => {
    console.log("redirecting to Explore page")
    if (hash) {
      // The hash is used to force an initial search when the Explore page loads
      this.props.history.push(`/explore#` + hash)
    } else {
      this.props.history.push(`/explore?firstRun`)
    }
  }

  redirectToQuickMaps = (quickMap) => {
    console.log("redirecting to Explore page with Quick maps")
    // The hash is used to force an initial search when the Explore page loads
    this.props.history.push({
      pathname:`/explore`,
      search:'?quickmap='+quickMap
    });
  }

  render() {

    // The Lot search information is found on page 10 of the PDF. Super hacky but what else ????
    let pdfLink;
    if (this.props.mode === 'internal'){
      pdfLink = Pdf_internal + "#page=10"
    } else {
      pdfLink = Pdf + "#page=10"
    }     

    let contentToShow;
    if(this.state.selectedTab ==='explore'){

      contentToShow=(
          <React.Fragment>
            <p className="mt-2">Explore the NSW Crown Estate in your community:</p>
            <SearchBar
              clearGeocodeResults={this.props.clearGeocodeResults}
              geocodeLatLong={this.props.geocodeLatLong}
              geocodeOptions={this.props.geocodeOptions}
              handleGeocodeChange={this.props.handleGeocodeChange}
              handleGeocodeOptionsUpdate={this.props.handleGeocodeOptionsUpdate}
              handleSelectChange={this.props.handleSelectChange}
              searchReady={this.props.searchReady}
              selectedGeocode={this.props.selectedGeocode}
            />
            <p className="instructionalText mt-4">To search for a specific Lot see <a href={pdfLink} target="_blank">the User Guide</a>.</p>

          <Row className="mt-5 pl-3 btnSubmitExplore">
            <Button
              id="btnSubmitExplore"
              type="submit"
              value="Search"
              className="btn btn-block clcButton mt-3"
              disabled={!this.props.searchReady || this.props.searchPending}
              onClick={() => this.redirectToTarget()}
            >
              GET STARTED
            </Button>
          </Row>
        </React.Fragment>

      )
    } else if (this.state.selectedTab ==='quickMaps'){
      contentToShow=(
        <div className="mt-2">
          <QuickMaps
            handleQuickMapSubmit={this.redirectToQuickMaps.bind(this)}
          />
          <Row className="">
            <Col xs={12}>
              <Button id="btnExplore" className="btnExplore" onClick={() => this.redirectToTarget()}>
                <img id="imgExploreMap" src="./img/compass.png" alt="compass" className="mr-1"/>
                EXPLORE MAP
              </Button>
            </Col>
          </Row>
        </div>
      )
    }

    let landingContent = (
      
        <Row className="">
          <Col lg={8} className="">
            <div id="searchWrapper_find">
              <Nav
                id="mainNav"
                onSelect={(selectedKey) =>{
                  this.handleTabSelect(selectedKey);
                }}
                activeKey={this.state.selectedTab}
              >
                <Nav.Item >
                  <Nav.Link eventKey="explore" className=" h1 navLink ">Explore</Nav.Link>
                </Nav.Item>
                <Nav.Item >
                  <Nav.Link eventKey="quickMaps" className=" h1 navLink">Quick Maps</Nav.Link>
                </Nav.Item>
              </Nav>
              {contentToShow}
            </div>
          </Col>
        </Row>
    )

    // Override the landing content for a public user, or a logged-out internal user
    if (this.props.mode === 'public') {
      if (!this.props.acceptedDisclaimer) {
        landingContent=(
          <React.Fragment>
            <Row>
              <Col lg={8} className="text-center">
                <div id="searchWrapper_disclaimer">
                  <Disclaimer
                    handleDisclaimer={this.props.handleDisclaimer}
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
      
    } else {
      if (!this.props.isLoggedIn) {
        landingContent = (
          <React.Fragment>
            <Row>
              <Col lg={8} className="text-center">
                <div id="searchWrapper_welcome">
                  <h2>Office of the NSW Crown Land Commissioner</h2>
                  <p>The Crown Land Commissioner has developed a Geographical Information System to provide greater transparency and spatial information to the Crown estate. In collaboration with other departments meaningful data has been obtained to assist in delivering economic, social, cultural and environmental benefits for the people of NSW.</p>
                  <p>Please note, access to Activate is currently restricted to the Commissioner’s Office. For further information visit the <a className="turquoiseText" target="_blank" rel="noreferrer" href="https://www.industry.nsw.gov.au/lands/what-we-do/crown-land-commissioner">Crown Land Commissioner</a> website.</p>
                  <Button className="headerButton" onClick={this.props.login}>Log in</Button>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    }

    let message;
    if (this.props.error){
      message = "There was a problem connecting to ArcGIS Server. Please contact your administrator.";
    } else {
      message = "Loading...";
    }

    return (

      <React.Fragment>
        <Header
          mode={this.props.mode}
          page = "landing"
          isLoggedIn={this.props.isLoggedIn}
          logout = {this.props.logout}
          resetSettings = {this.props.resetSettings}
          showSourcesModal = {this.props.showSourcesModal}
          toggleSourcesModal={this.props.toggleSourcesModal}
        />

        {/* show a message if the screen is smaller than md, otherwise show the other content */}
        <MobileMessage/>

        <Sources
          toggleSourcesModal={this.props.toggleSourcesModal}
          showSourcesModal={this.props.showSourcesModal}
        ></Sources>

        {(this.props.isLoggedIn || (this.props.mode === 'public' && this.props.acceptedDisclaimer)) && !this.props.isLoaded ? 
          <Container fluid id="loadingPanel" className="d-none d-md-flex">
            <div className="row h-100 w-100 justify-content-center align-items-center">
              <h4 id="landingMessage">{message}</h4>
            </div>
          </Container>
        : 

          <Container fluid id="findContainer" className={(this.props.isLoggedIn ? "loggedIn d-none d-md-flex" : "d-none d-md-flex")}>
            {landingContent}
          </Container>
        }
        
      </React.Fragment>
    )
  }
}

export default Landing;
