import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { OverlayLayer } from './OverlayLayer';
import { mapConfig } from '../config/mapConfig';
import axios from 'axios';
import { loadModules } from 'esri-loader';

class OverlayTool extends Component {
    constructor(props) {
      super(props);
      this.state = {
            activeOverlayLayers: [],
            cancelling: false,
            creditEstimate: null,
            error: false,
            estimatingCredits: false,
            featureLayers: [],
            jobId: null,
            processing: false          
          
      }
      this.runOverlayAnalysis = this.runOverlayAnalysis.bind(this);
      this.updateOverlayLayers = this.updateOverlayLayers.bind(this);
      this.checkJobStatus = this.checkJobStatus.bind(this);
      this.retrieveResults = this.retrieveResults.bind(this);
      this.displayResults = this.displayResults.bind(this);
      this.estimateCredits = this.estimateCredits.bind(this);
      this.createParameters = this.createParameters.bind(this);
      this.resetCreditEstimate = this.resetCreditEstimate.bind(this);
      this.cancelJob = this.cancelJob.bind(this);
    }
  
    componentDidMount(){
        try{
            console.log("overlay tool mounted")
            let featureLayers = []
            this.props.map.layers.forEach((layer, idx) => {
                if (layer.type === 'feature' && layer.url){
                    featureLayers.unshift(layer.id);
                } else if (layer.type === 'group'){
                    layer.layers.forEach(layer2 => {
                        if (layer2.type === 'feature' && layer2.url){
                            featureLayers.unshift(layer2.id);
                        }
                    })
                }
            });
            this.setState({featureLayers});
            loadModules(["esri/core/watchUtils"]).then(([watchUtils]) => {
                watchUtils.whenTrue(this.props.view, "stationary", this.resetCreditEstimate);
            })
        } catch(err){
            console.log("There was a problem opening the Overlay tool")
        }
    }
  
    componentWillUnmount() {
        console.log("overlay tool will unmount")
    }

    resetCreditEstimate(){
        this.setState({creditEstimate: null});
    }

    updateOverlayLayers(oldLayerId, newLayerId){
        // Remove the old layer if present, and add the new layer
        let activeOverlayLayers = this.state.activeOverlayLayers;
        const index = activeOverlayLayers.indexOf(oldLayerId);
        if (index > -1) {
            activeOverlayLayers.splice(index, 1);
        }
        activeOverlayLayers.push(newLayerId);
        this.setState({activeOverlayLayers: activeOverlayLayers, error: false, creditEstimate: null});
    }

    runOverlayAnalysis(){
        this.props.clearOverlayLayer();

        this.setState({processing: true, error: false, jobId: null})
        console.log("run overlay analysis")
        let url = mapConfig().spatialAnalysis.submitUrl;
        
        let data = this.createParameters();
        // Parameterise the data object
        var str = "";
        for (var key in data) {
            if (str !== "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(data[key]);
        }

        // Add the parameters then submit the job
        url += "submitJob?" + str;
        axios.get(url)
          .then((response) => {
            if (response.data && response.data.jobId) {
                this.setState({jobId: response.data.jobId}, () => {
                    this.checkJobStatus();
                })
            } else {
                console.error("There was a problem submitting the overlap analysis");
                this.setState({processing: false, error: true, jobId: null})
            }
          })
          .catch((err) => {
            console.error("There was a problem submitting the overlap analysis", err);
            this.setState({processing: false, error: true, jobId: null})
          })

    }

    checkJobStatus(){
        let checkURL = mapConfig().spatialAnalysis.submitUrl + "jobs/" + this.state.jobId + "?f=json&token=" + this.props.token;
        axios.get(checkURL)
          .then((response) => {
            let status = response.data.jobStatus;
            if (status === "esriJobSubmitted" || status === 'esriJobExecuting') {
                setTimeout(() => {
                    this.checkJobStatus(this.state.jobId);
                }, 1000);
            } else if (status === "esriJobFailed") {
                console.error("Spatial Analysis failed");
                response.data.messages.forEach(message => {
                    console.error(message.description)
                })
                this.setState({processing: false, error: true, jobId: null})               
            } else if (status === "esriJobSucceeded") {
                console.log("Woohoo, it worked")
                this.retrieveResults(this.state.jobId);
            } else if (status === "esriJobCancelled") {
                console.log("Job has been cancelled")
                this.setState({cancelling: false, processing: false, error: false, jobId: null})
            } else {
                console.error("unexpected response", response)
                this.setState({cancelling: false, processing: false, error: false, jobId: null})
            }
            
          })
          .catch((err) => {
            console.error("There was a problem checking the overlap analysis", err);
            this.setState({processing: false, error: true, jobId: null})
          })
    }

    retrieveResults(){
        let url = mapConfig().spatialAnalysis.submitUrl + "jobs/" + this.state.jobId + "/results/resultLayer?f=json&token=" + this.props.token;
        axios.get(url)
          .then((response) => {
            if (response.data && response.data.value){
                this.displayResults(response.data.value);
            } else {
                console.error("There was a problem retrieving the analysis results");
                this.setState({processing: false, error: true, jobId: null})
            }
            
          })
          .catch((err) => {
            console.error("There was a problem retrieving the analysis results", err);
            this.setState({processing: false, error: true, jobId: null})
          });
    }

    displayResults(results){
        
        try{
            console.log("Add results to map:", results)

            // Process the features and create a new feature layer from them
            let features = results.featureSet.features;
            let geometryType = results.featureSet.geometryType.replace("esriGeometry",'').toLowerCase();
            features.forEach(feature => {
                feature.geometry.type = geometryType;
                feature.geometry.spatialReference = results.featureSet.spatialReference;
            })

            // process the fields
            let fields = results.layerDefinition.fields.map(field => {
                let fieldType = field.type.replace("esriFieldType","").toLowerCase();
                fieldType = fieldType.replace("smallinteger", 'integer');
                return {
                name: field.name,
                alias: field.alias,
                type: fieldType
                }
            })

            let popupTemplate = {
                title: mapConfig().spatialAnalysis.overlayLayerName,
                content: [{
                    type: "fields", 
                    fieldInfos: results.layerDefinition.fields.map(field => {
                        return {fieldName: field.name, label: field.alias}
                    })
                }]
            }

            // Apply the appropriate renderer depending on the geometry type
            let renderer;
            if (geometryType === 'point') {
                renderer = mapConfig.overlayPoints;
            } else if (geometryType === 'polyline'){
                renderer = mapConfig.overlayLines;
            } else if (geometryType === 'polygon'){
                renderer = mapConfig().overlayPolygons;
            } else {
                console.error("unknown overlay geometry type")
            }
            
            let layerDefinition = {
                source: features,
                fields: fields,
                objectIdField: results.layerDefinition.objectIdField,
                title: mapConfig().spatialAnalysis.overlayLayerName,
                geometryType: geometryType,
                spatialReference: results.featureSet.spatialReference,
                popupTemplate: popupTemplate,
                saveToAgol: true,
                overlayLayer: true,
                renderer: renderer
            }

            // Remove any pre-existing spatial analysis layer
            this.props.updateOverlayLayer(layerDefinition)

            
            this.setState({processing: false, error: false})
        } catch(err) {
            console.error("There was a problem adding the spatial analysis results to the map")
            this.setState({processing: false, error: true, jobId: null})
        }
    }

    estimateCredits(){
        console.log("estimate credits")
        this.setState({estimatingCredits: true});
        
        let data = this.createParameters();

        let params = {
            taskName: 'DeriveNewLocations',
            taskParameters: JSON.stringify(data),
            token: this.props.token,
            f: 'json'
        }

        // Parameterise the params object
        var str = "";
        for (var key in params) {
            if (str !== "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(params[key]);
        }

        // Add the parameters then submit the job
        let url = mapConfig().spatialAnalysis.creditsUrl;
        url += "?" + str;
        axios.get(url)
          .then((response) => {
              let credits = response.data.results[0].value.cost;
              this.setState({processing: false, error: false, creditEstimate: credits, estimatingCredits: false})
          })
          .catch((err) => {
            console.error("There was a problem estimating the credits", err);
            this.setState({processing: false, error: true, jobId: null, creditEstimate: null, estimatingCredits: false})
          })

    }

    createParameters(){
        this.setState({processing: true, error: false, creditEstimate: null});

        let expressions = [{"operator":"","layer":0,"selectingLayer":1,"spatialRel":"intersects"}];
                          
        let inputLayers = this.state.activeOverlayLayers.map(layerId => {
            try{
                // Find the layer, which may be stored in a group layer
                let activeLayer;
                this.props.map.layers.forEach((layer, idx) => {
                    if (layer.id === layerId){
                        activeLayer = layer;
                    } else if (layer.type === 'group'){
                        layer.layers.forEach(layer2 => {
                            if (layer2.id === layerId){
                                activeLayer = layer2;
                            }
                        })
                    }
                });
                let layerObj = {"url": activeLayer.parsedUrl.path, "name": activeLayer.title}
                if (activeLayer.parsedUrl.path.includes(mapConfig().hostedLayerURL) || activeLayer.parsedUrl.path.includes(mapConfig().proxiedLayerURL)){
                    layerObj["serviceToken"] = this.props.token;
                }
                if (activeLayer.definitionExpression) {
                    layerObj.filter = activeLayer.definitionExpression;
                }
                return layerObj;
            
            } catch(err){
                console.error("There was a problem setting the overlay layers");
                this.setState({processing: false, error: true, jobId: null})
                return null
            }
            
        });
        
        let context = {
            "extent": this.props.view.extent
        }

        let data = {
            f: 'json',
            expressions: JSON.stringify(expressions),
            inputLayers: JSON.stringify(inputLayers),
            context: JSON.stringify(context),
            token: this.props.token
        }

        return data;
    }

    cancelJob(){
        this.setState({cancelling: true}, () => {
            let url = mapConfig().spatialAnalysis.submitUrl + "jobs/" + this.state.jobId + "/cancel?f=json&token=" + this.props.token;
            axios.get(url)
            .then((response) => {
                console.log("Cancel response", response)
            })
            .catch((err) => {
                console.error("There was a problem cancelling the job", err);
                this.setState({cancelling: false, processing: false, error: true, jobId: null, creditEstimate: null})
            })
        })
    }

    render(){

        let height = 415;
        if (!this.state.processing && this.state.activeOverlayLayers.length >= 2) {
            if (this.state.creditEstimate) {
                height = 465;
            } else {
                height = 450;
            }
        } else if (this.state.estimatingCredits){
            height = 400;
        } else if (this.state.processing){
            height = 440;
        }
        const divStyle = {
            height: height + 'px',
        };

        return (
            <Draggable
                bounds='#mapDiv'
                defaultPosition={{x: 0, y: 0}}
            >
 
                <div id="overlayToolDiv" style={divStyle} className={"esri-component esri-widget customTool " + (this.props.searchPending ? 'hidden' : '')}>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggleOverlayTool}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Row>
                        <Col xs={12} className="pl-0 pr-0">
                            <h2>Identify Overlap</h2>
                            <p className="pt-2">Generate locations where map layers intersect within map view. If using a point or line layer, assign them to 'Primary Layer'.</p>
                        </Col>
                    </Row>
                    
                    <Form id="overlayForm" className="form pt-2" onSubmit={e => { e.preventDefault(); }}>
                        <OverlayLayer
                            activeOverlayLayers={this.state.activeOverlayLayers}
                            featureLayers={this.state.featureLayers}
                            label="Primary Layer:"
                            map={this.props.map}
                            updateOverlayLayers={this.updateOverlayLayers}
                        >
                        </OverlayLayer>

                        <OverlayLayer
                            activeOverlayLayers={this.state.activeOverlayLayers}
                            featureLayers={this.state.featureLayers}
                            label="Intersecting Layer:"
                            map={this.props.map}
                            updateOverlayLayers={this.updateOverlayLayers}
                        >
                        </OverlayLayer>

                        {!this.state.processing && this.state.activeOverlayLayers.length >= 2 && !this.state.error? 
                        <React.Fragment>
                            <Form.Row className="pl-0">
                                <Col xs={12} className="">
                                    <Button
                                        type="submit"
                                        value="estimateCredits"
                                        className="pl-0 pt-0 noHover"
                                        variant="turquoiseText"
                                        onClick={this.estimateCredits}
                                    >
                                        <img className="mr-1 resultsIcon"
                                            src="./img/credit.svg"
                                            // onMouseOver={e => (e.currentTarget.src = "./img/clear.svg")}
                                            // onMouseOut={e => (e.currentTarget.src ="./img/clear.svg")}
                                            alt="report"
                                        />
                                        ESTIMATE CREDITS
                                    </Button>
                                    
                                </Col>
                            </Form.Row>
                            {this.state.creditEstimate ? 
                                <Form.Row className="pl-0">
                                    <Col xs={12} className="">
                                        Credit estimate: {this.state.creditEstimate}
                                    </Col>
                                </Form.Row>
                            : null}
                            </React.Fragment>

                        : null }
                        
                        {!this.state.cancelling && !this.state.processing ? 
                            <Form.Row>
                                <Col xs={12}>
                                    <Button
                                        id="btnSubmitFilter"
                                        type="submit"
                                        value="Submit"
                                        className="btn btn-block clcButton mt-3"
                                        onClick={this.runOverlayAnalysis}
                                        disabled={this.state.activeOverlayLayers.length < 2 || this.state.error || this.state.processing}
                                    >
                                        GENERATE
                                    </Button>
                                </Col>
                            </Form.Row>
                        : null}
                        
                        {this.state.processing || this.state.estimatingCredits ?
                            <React.Fragment>
                                <div id="loading" className="d-flex justify-content-center">
                                    <img className="" height="30px" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} />
                                </div>

                                {!this.state.estimatingCredits ? 
                            
                                    <Form.Row>
                                        <Col xs={12} className="">
                                            <Button
                                                type="submit"
                                                value="cancel"
                                                className="btn btn-block clcButton mt-3"
                                                onClick={this.cancelJob}
                                                disabled={this.state.cancelling}
                                            >
                                                {this.state.cancelling ? "Cancelling" : "CANCEL"}
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                : null }
                            </React.Fragment>
                        
                        : null } 

                        {this.state.error ? 
                            <Form.Row className="pl-2 pt-1">           
                                <Col xs={12} className="">
                                    <p className={"mb-0 message " + (this.state.error ? 'error' : '')}>
                                        There was a problem with the processing. Please try again
                                    </p>
                                </Col>
                            </Form.Row>
                            
                        : null}   
            
                    </Form>
                </div>
            </Draggable>
        )
    }
}


export { OverlayTool };