/* This is the map on the Explore page */

import React from 'react';
import { mapConfig } from "../config/mapConfig";
import { layerConfig } from "../config/layerConfig";
import { quickMapsConfig } from "../config/quickMapsConfig";
import { loadModules } from 'esri-loader';
import { MeasureTool } from './MeasureTool';
import { FilterTool } from './FilterTool';
import { OverlayTool } from './OverlayTool';
// import { ScreenshotTool } from './ScreenshotTool';
import SaveToAgol  from "./SaveToAgol";
import axios from 'axios';
import { toast } from 'react-toastify';
import { redirectToTarget } from '../modules/utils';
import {loadExploreMenuLayers} from "../modules/exploreMenuLayers";

class MainMap extends React.Component {

    constructor(props) {
        super(props);
        
        this.mapRef = React.createRef();

        this.state = {
            activeMeasureTool: null,
            allLayerCount: null,
            errorLayers: null,
            filterLayer: null,
            mapLayers:[],
            processedLayerCount: null,
            saveToAgolLayer: null,
            showFilter: false,
            showOverlayTool: false,
            showSaveModal: false,
            // screenshotMode: false
        };

        this.handleViewLoad = this.handleViewLoad.bind(this);
        this.handleFail = this.handleFail.bind(this);
        this.handleAttemptedMapExtentChange = this.handleAttemptedMapExtentChange.bind(this);
        this.disableZooming = this.disableZooming.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.layerListAction = this.layerListAction.bind(this);
        this.saveFiltersToLayer = this.saveFiltersToLayer.bind(this);
        this.deleteFilterFromLayer = this.deleteFilterFromLayer.bind(this);
        this.toggleOverlayTool = this.toggleOverlayTool.bind(this);
        this.closeSaveModal = this.closeSaveModal.bind(this);
        // this.toggleScreenshotModal = this.toggleScreenshotModal.bind(this);
        this.changeActiveMeasureTool = this.changeActiveMeasureTool.bind(this);
        this.renameFilterLayer = this.renameFilterLayer.bind(this);
        this.defineActions = this.defineActions.bind(this);
        this.loadLayer = this.loadLayer.bind(this);
        this.createPrintOut = this.createPrintOut.bind(this);

    }

    componentDidMount(){
        // console.log("main map did mount")
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(['esri/WebMap', 'esri/views/MapView', "esri/layers/WMTSLayer", "esri/layers/MapImageLayer", "esri/layers/FeatureLayer"], { css: true })
        .then(([WebMap, MapView, WMTSLayer, MapImageLayer, FeatureLayer]) => {                       
            let map;

            if (this.props.map === null || this.props.map === undefined) {
                if (this.props.mode === 'public'){
                    map = new WebMap({
                        portalItem: {
                            id: mapConfig().public.webmapId						
                        }					
                    });
                } else {
                    map = new WebMap({
                        portalItem: {
                            id: mapConfig().internal.webmapId,
                            portal: {
                              url: mapConfig().webMapBaseUrl
                            }						
                        }					
                    });
                }
                
                map.load()
                .then(status => {
                    if (status.loadStatus && status.loadStatus === 'loaded'){
                        console.log("map loaded");
                    } else {
                        console.error("There was a problem loading the web map")
                        this.props.updateLoadedStatus(false);
                    }
                }).catch(err => {
                    console.error("map load error", err)
                    this.props.updateLoadedStatus(false);
                    return;
                })

                if (this.props.mode !== 'public'){
                    // Load NearMap
                    let nearmapLayer = new WMTSLayer(layerConfig().nearmap);
                    map.add(nearmapLayer);

                    // Load custom map image layers, which are not supported in the webmap
                    let imageLayers = layerConfig().imageLayers;
                    if (imageLayers && imageLayers.length){
                        imageLayers.forEach((imageLayerDef) => {
                            //console.log("Need to add map image layer", imageLayerDef.title)
                            let imageLayer = new MapImageLayer(imageLayerDef);
                            map.add(imageLayer);  // adds the layer to the map
                        });
                    }
                }

                // Save the map to the state, to avoid reloading it when the page changes
                this.props.saveMapToState(map);
            } else {
                map = this.props.map;
                this.props.updateLoadedStatus(true);
            }

            // Wait for the map to load, then hide the loading panel (if applicable)
            if (!this.props.isLoaded) {
                // this.props.updateLoadedStatus(true); 
                map.load()
                    .then(() => {
                        this.props.updateLoadedStatus(true); 
                        // load the basemap to get its layers created
                        return map.basemap.load();
                    })
                    .then(() => {
                      // grab all the layers and verify them
                      const allLayers = map.allLayers.filter(layer => {return layer.url});
                      this.setState({ allLayerCount: allLayers.length, processedLayerCount: 0, errorLayers: []}, () => {
                        const promises = allLayers.map(layer => {
                            this.loadLayer(layer, false);
                            return layer;
                        // return status;
                        });
                        
                        // Hide the loading indicator
                        this.props.updateLoadedStatus(true);
                        return Promise.all(promises.toArray());
                      });
                      
                    });
            } else {
                // Hide the landing page loading panel
                this.props.updateLoadedStatus(true);                      
            }

            this.view = new MapView({
                container: this.mapRef.current,
                map: map,
                extent: mapConfig().extent,
                ui: { components: ["attribution"] }
            });

            this.view.when(this.handleViewLoad);

        });
    }

    async loadLayer(layer, useToken){
        try{
            // Test each layer's URL to verify that it's available.
            let url = layer.url + "?f=json";
            if (useToken){
                url += "&token=" + this.props.token;
            }
            let status = await axios.get(url);
            if (status.data) {
                if (status.data.error){
                    let tokenMessages = ['Token Required.', 'Token Required', "You do not have permissions to access this resource or perform this operation."]
                    if (status.data.error.message && tokenMessages.includes(status.data.error.message) && !useToken) {
                        this.loadLayer(layer, true)
                    } else {
                        let errorLayers = this.state.errorLayers;
                        errorLayers.push(layer.title)
                        let processedLayerCount = this.state.processedLayerCount;
                        processedLayerCount += 1;
                        this.setState({processedLayerCount, errorLayers})
                    }
                } else {
                    let processedLayerCount = this.state.processedLayerCount;
                    processedLayerCount += 1;
                    this.setState({processedLayerCount})
                }
            }

            // Once all layers have been tested, display an error message if any problems were found
            if (this.state.processedLayerCount === this.state.allLayerCount){
                if(this.state.errorLayers.length > 0){

                    let errorList = (
                        <ul>
                            {this.state.errorLayers.map((layer, key) => {
                                return <li key={key}>{layer}</li>
                            })}
                        </ul>
                    )

                    toast.error(
                        <div>
                            <p>The following layers are unavailable:</p>
                            {errorList}
                        </div>
                        , {autoClose: false}
                    );
                    
                } else {
                    console.log("No errors were found with map layers");

                }
                this.props.updateLoadedStatus(true);
            }
        } catch(err){
            console.error("There was a problem checking layer status")
            this.props.updateLoadedStatus(true);
        }
        // return status
    }

    componentWillUnmount() {

        // Remove temporary layers
        try{
            let layerList = [this.props.view.highlightPointGraphicsLayer, this.props.view.highlightPolygonGraphicsLayer,
                this.props.view.geocodeLayer, this.props.view.polygonLayer, this.props.view.centroidLayer, this.props.nearbyPointsLayer, this.props.nearbyPolygonsLayer];
            layerList.forEach(layer => {
                try{
                    if (layer){
                        this.props.map.remove(layer);
                    }
                } catch(err){
                    console.error("There was a problem removing a temporary layer")
                }
            })
        } catch(err){
            console.error("There was a problem removing layers from the view")
        }

    }

    loadQuickMap = async (view) => {
        try{
            if (this.props.quickMap) {
                let quikMapsConfig = quickMapsConfig().quickMaps;
                let layersToLoad = quikMapsConfig[this.props.quickMap].layers;
                if (layersToLoad && layersToLoad.length > 0) {

                    let layersVisibilityUpdated=false;
                    this.state.mapLayers.forEach((groupLayer) => {
                        if (groupLayer.layers) {
                            groupLayer.layers.forEach(layer => {
                                if(layer.title.toLowerCase().indexOf("crown") === -1){
                                    layer.visible = false;
                                }

                            });
                            let existingLayersToShowOnMap = [];
                            layersToLoad.forEach((layerToLoad) => {
                                let existingLayerOnMap = groupLayer.layers.find((existingLayerOnMap) => {
                                    return (existingLayerOnMap.title === layerToLoad.title )
                                });
                                if (existingLayerOnMap) {
                                    existingLayerOnMap.visible = true;
                                    existingLayersToShowOnMap.push(existingLayerOnMap);
                                 }

                            });
                            if (existingLayersToShowOnMap.length > 0) {
                                groupLayer.visible = true;
                                layersVisibilityUpdated=true;
                            } else {
                                if(groupLayer.title.toLowerCase().indexOf("crown") === -1){
                                    groupLayer.visible = false;
                                }

                            }
                        }

                    });

                    if(layersVisibilityUpdated){

                        //OPTIONAL SETTING, if extent zoom and center are defined in config, map will zoom to the configured values.
                       if(quikMapsConfig[this.props.quickMap].extent && quikMapsConfig[this.props.quickMap].extent.center!= null && quikMapsConfig[this.props.quickMap].extent.zoom!= null){
                            view.center=quikMapsConfig[this.props.quickMap].extent.center;
                            view.zoom=quikMapsConfig[this.props.quickMap].extent.zoom;
                       }

                    }
                }
            }
        } catch(error) {
            console.error("Error loading quickMap", error.message);
        }
    }

    handleViewLoad(view) {
        console.log("handle view load")
        let map = view.map;

        this.setState({mapLayers:map.layers});
        this.addWidgets();
        if(this.props.quickMap && this.props.quickMap!=null){
            this.loadQuickMap(view);
        }

        // Save the view to the state. Also send the current page, so we know whether to run a search or not
        if (this.props.saveViewToState) {
           this.props.saveViewToState(view, this.props.page);
        }

        // Prevent map navigation while a search is in progress
        this.view.when(this.disableZooming).then(this.props.displayWidgets)

        loadExploreMenuLayers(view);

    }

    // Prevent map navigation while a search is in progress
    disableZooming(view) {
        view.on(["click", "drag", "double-click", "mouse-wheel", "hold"], this.handleAttemptedMapExtentChange);
        view.on("drag", ["Shift"], this.handleAttemptedMapExtentChange);
        view.on("drag", ["Shift", "Control"], this.handleAttemptedMapExtentChange);
    }
    handleAttemptedMapExtentChange(evt){
        try{
            if (this.props.searchPending){
                evt.stopPropagation();
            }
        } catch(err){
            console.error("There was a problem disabling navigation")
        }
    }

    handleFail(e) {
        console.error("There was an error loading the map", e.message);
        this.setState({ status: 'failed' });
    }

    defineActions(event) {

        var item = event.item;
        item.actionsSections = [];
        
        let layer = item.layer;
        let layerType = layer.type;
        if (layer && !layer.customLayer){
            if (layerType !== 'group'){
            //     if (layer.url || layer.parsedUrl) {
            //         item.actionsSections.push([{
            //             title: "Layer information",
            //             className: "esri-icon-description",
            //             id: "information"
            //         }]);
            //     }

                if (layer.opacity){
                    item.actionsSections.push([
                        // {
                        // title: "Go to full extent",
                        // className: "esri-icon-zoom-out-fixed",
                        // id: "full-extent"
                        // },
                        {
                            title: "Increase opacity",
                            className: "esri-icon-up",
                            id: "increase-opacity"
                        },
                        {
                            title: "Decrease opacity",
                            className: "esri-icon-down",
                            id: "decrease-opacity"
                        }
                    ]);
                }
            }

            if (layerType === 'feature' && layer.fields){
                item.actionsSections.push([{
                    title: "Filter",
                    className: "esri-icon-filter",
                    id: "filter"
                }])
            }

            try{
                if (layer.saveToAgol && this.props.agolPrivileges.find(o => o === "portal:user:createItem")) {
                    item.actionsSections.push([{
                        title: "Save to ArcGIS Online",
                        className: "esri-icon-save",
                        id: "saveToAgol"
                    }])
                }
            } catch(err){
                console.error("Unable to add the Save to ArcGIS Online option")
            }

            // TODO: add the ability to remove the overlay layer from the TOC
            // if (layer.overlayLayer){
            //     item.actionsSections.push([{
            //         title: "Remove overlay layer",
            //         className: "esri-icon-trash",
            //         id: "removeOverlay"
            //     }])
            // }

        }
    }

    addWidgets() {
        loadModules([
            "esri/widgets/Zoom", "esri/widgets/Expand", "esri/widgets/LayerList", "esri/widgets/BasemapGallery", "esri/widgets/BasemapGallery/support/LocalBasemapsSource",
            "esri/Basemap", "esri/widgets/Home", "esri/widgets/Print", "esri/widgets/Locate", "esri/widgets/Legend", "esri/widgets/ScaleBar", "esri/widgets/Measurement"
        ]).then(([Zoom, Expand, LayerList, BasemapGallery, LocalBasemapsSource, Basemap, Home, Print, Locate, Legend, ScaleBar, Measurement]) => {

            try{
                this.props.view.zoomControl = new Zoom({view: this.props.view});
                this.props.view.homeControl = new Home({view: this.props.view,});
                this.props.view.locateControl = new Locate({
                  view: this.props.view,
                  useHeadingEnabled: false,
                  goToOverride: function (view, options) {
                    options.target.scale = 5000; // Override the default map scale
                    return view.goTo(options.target);
                  }
                });

                let layerList = new LayerList({
                    view: this.props.view,
                    listItemCreatedFunction: this.defineActions
                })
                this.props.view.layerControl = new Expand({
                    expandIconClass: "esri-icon-layer-list",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
                    expandTooltip: "Expand LayerList",
                    view: this.props.view,
                    content: layerList
                });

                layerList.on("trigger-action", this.layerListAction)

                this.props.view.basemapControl = new Expand({
                    expandIconClass: "esri-icon-basemap",
                    view: this.props.view,
                    expandTooltip: "Expand Basemaps",
                    content: new BasemapGallery({
                        view: this.props.view,
                        source: new LocalBasemapsSource({
                        basemaps: [
                            Basemap.fromId("topo-vector"),
                            Basemap.fromId("streets-navigation-vector"),
                            Basemap.fromId("satellite"),
                            Basemap.fromId("hybrid"),
                            Basemap.fromId("oceans"),
                            Basemap.fromId("gray-vector"),
                       ]
                      }),
                    })
                });

                let printSettings = mapConfig().printSettings;
                printSettings.view = this.props.view;
                this.props.view.printControl = new Expand({
                    expandIconClass: "esri-icon-printer",
                    expandTooltip: "Expand Printer",
                    view: this.props.view,
                    content: new Print(printSettings)
                });

                let expanded = true;
                // TODO: figure out if any layers are visible when the map opens. If so, only then
                // make the expanded value true, otherwise it should be false. Iterate this list
                // looking for visible layers: https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-LayerList.html#operationalItems

                this.props.view.legendControl = new Expand({
                    expandIconClass: "esri-icon-legend",
                    expandTooltip: "Expand Legend",
                    view: this.props.view,
                    expanded: expanded,
                    content: new Legend({view: this.props.view})
                });

                this.props.view.scaleBar = new ScaleBar({
                  view: this.props.view,
                  unit: "metric"
                });
                this.props.view.ui.add(this.props.view.scaleBar, {
                  position: "bottom-right"
                });

                this.props.view.measurement = new Measurement({
                    view: this.view,
                    areaUnit: "square-kilometers",
                    linearUnit: "kilometers"
                });
                
                this.props.view.sidebarBtn= document.getElementById("collapseBtnContainer");

                this.props.displayWidgets();


            } catch(err){
                console.error("error","There was a problem adding widgets", err.message);
            }
        })
    }

    layerListAction(event) {
        let id = event.action.id;
        let layer = event.item.layer;

        if (id === "information") {
            // Temporarily removing this as it doesn't work well for proxy layers
            // let url = layer.url;
            // if (layer.parsedUrl && layer.parsedUrl.path) {
            //     url = layer.parsedUrl.path;
            // }
            // // Add a token to hosted or proxied layers
            // if (url.indexOf(mapConfig().hostedLayerURL) > -1 || url.indexOf(mapConfig().proxiedLayerURL) > -1){
            //     url += "?token=" + this.props.token;
            // }            

            // window.open(url); 
        } else if (id === "full-extent") {
            this.props.view.goTo(layer.fullExtent);                   
        } else if (id === 'filter'){
            if (!layer.filters){
                layer.filters = [];
            }
            
            // Assign the filterable fields. These are derived from the popup
            let filterFields = layer.fields || [];
            try{
                if (layer.popupTemplate && layer.popupTemplate.fieldInfos){
                    let visibleFields = layer.popupTemplate.fieldInfos.filter(field => {
                        return field.visible;
                    });
                    
                    filterFields = visibleFields.map(field => {
                        let fieldObj = {
                            type: layer.fields.find(o => o.name === field.fieldName).type,
                            name: field.fieldName,
                            alias: field.label
                        }
                        return fieldObj;
                    })
                    // This code will temporarily remove DATE fields due to complexities in date searching
                    // let filterableFields = visibleFields.filter(field => {
                    //     if (layer.fields.find(o => o.name === field.fieldName).type!== 'date'){
                    //         return field;
                    //     }
                    // });
                    // filterFields = filterableFields.map(field => {
                    //     let fieldObj = {
                    //         type: layer.fields.find(o => o.name === field.fieldName).type,
                    //         name: field.fieldName,
                    //         alias: field.label
                    //     }
                    //     return fieldObj;
                    // })
                    
                }
            } catch(err){
                console.error("There was a problem assigning the filter fields", err)
            }
            layer.filterFields = filterFields;

            this.setState({showFilter: true, filterLayer: layer});
        } else if (id === 'saveToAgol'){
            this.setState({showSaveModal: true, saveToAgolLayer: layer})
        } else if (id === 'removeOverlay'){
            console.log("remove overlay layer coming soon")
        } else if (id === "increase-opacity") {
            if (layer.opacity < 1) {
              layer.opacity += 0.25;
            }
          } else if (id === "decrease-opacity") {
            if (layer.opacity > 0) {
              layer.opacity -= 0.25;
            }
          }
    
    }

    async saveFiltersToLayer(filters){
        let filterLayer = this.state.filterLayer;
        filterLayer.filters = filters;
        await this.setState({filterLayer});
    }

    async deleteFilterFromLayer(filterId){
        try{
            let filterLayer = this.state.filterLayer;
            let filters = this.state.filterLayer.filters;
            let filter = filters.find(o => o.id === filterId);
            if (filter){
                filters.splice(filters.indexOf(filter), 1);
                filterLayer.filters = filters;
                this.setState({filterLayer});
            } else {
                console.error("Unable to delete filter")
            }
            
        }catch(err){
            console.error("Unable to delete filter", err)
        }
    }

    toggleFilter(){
        this.setState(showFilter => ({
            showFilter: !showFilter
        }));
    }

    renameFilterLayer(title){
        let filterLayer = this.state.filterLayer;
        filterLayer.title = title;
        this.setState({filterLayer});
    }

    toggleOverlayTool(){
        if (this.state.showOverlayTool){
            this.setState({showOverlayTool: false})
        } else {
            this.setState({showOverlayTool: true})
        }
    }

    updateActiveOverlayLayers(evt){
        console.log("update active overlay layers:", evt.target.value)

    }

    closeSaveModal(){
        this.setState({showSaveModal: false});
    }

    // toggleScreenshotModal(){
    //     if (this.state.screenshotMode){
    //         this.setState({screenshotMode: false})
    //     } else {
    //         this.setState({screenshotMode: true})
    //     }
    //     // Also disable any active measure tool. TODO: make this work with *any* tool?
    //     this.setState({activeMeasureTool: null})
    // }

    changeActiveMeasureTool(tool) {
        this.setState({activeMeasureTool: tool, screenshotMode: false});
    }

    createPrintOut(){
        redirectToTarget('printout', this.props)
    }

    render() {

        let overlayToolButton;
        if (this.props.agolPrivileges.find(o => o === "premium:user:spatialanalysis")){
            overlayToolButton = (
                <div id="overlayToolButton" className="esri-component esri-widget">            
                    <button id="btnOverlay" className="esri-widget--button esri-interactive esri-icon-layers customTool" title="Launch Identify Overlay tool" onClick={this.toggleOverlayTool}></button>
                </div>
            )
        }
        return (
            <React.Fragment>
                <div
                    id="mapDiv"
                    className="base-container"
                    ref={this.mapRef}
                    onLoad={this.handleViewLoad}
                    onError={this.handleFail}
                >
                </div>
                
                {this.props.page === 'explore' ?
                    <React.Fragment>
                        <MeasureTool
                            activeMeasureTool = {this.state.activeMeasureTool}
                            changeActiveMeasureTool = {this.changeActiveMeasureTool}
                            view = {this.props.view}
                            searchPending = {this.props.searchPending}
                        />

                        {overlayToolButton}

                        <div id="screenshotMapButton" className="esri-component esri-widget">            
                            <button id="btnScreenshot" className={"esri-widget--button esri-interactive esri-icon-share2 customTool " + (this.state.screenshotMode ? "active" : '')} title="Create print-out page" onClick={this.createPrintOut}></button>
                        </div> 
                        
                        {this.state.showFilter ? 
                            <FilterTool
                                deleteFilterFromLayer={this.deleteFilterFromLayer}
                                saveFiltersToLayer={this.saveFiltersToLayer}
                                filterLayer={this.state.filterLayer}
                                mapIsUpdating={this.props.mapIsUpdating}
                                renameFilterLayer={this.renameFilterLayer}
                                searchPending = {this.props.searchPending}
                                toggleFilter={this.toggleFilter}                                
                            >
                            </FilterTool>
                        : null }

                        {this.state.showOverlayTool ? 
                            <OverlayTool
                                map = {this.props.map}
                                activeOverlayerLayers={this.state.activeOverlayerLayers}
                                clearOverlayLayer={this.props.clearOverlayLayer}
                                mapIsUpdating={this.props.mapIsUpdating}
                                overlayLayer={this.props.overlayLayer}
                                searchPending = {this.props.searchPending}
                                toggleOverlayTool={this.toggleOverlayTool}
                                token={this.props.token}
                                updateActiveOverlayLayers={this.updateActiveOverlayLayers}
                                updateOverlayLayer={this.props.updateOverlayLayer}
                                view = {this.props.view}
                            />
                        : null}

                        {this.state.showSaveModal ? 
                            <SaveToAgol
                                map = {this.props.map}
                                closeSaveModal={this.closeSaveModal}
                                saveToAgolLayer={this.state.saveToAgolLayer}
                                showSaveModal={this.state.showSaveModal}
                                token={this.props.token}
                                userId={this.props.userId}
                            />
                        : null} 

                        {/* {this.state.screenshotMode ? 
                            <React.Fragment>
                                <ScreenshotTool
                                    screenshotMode = {this.state.screenshotMode}
                                    toggleScreenshotModal = {this.toggleScreenshotModal}
                                    view = {this.props.view}
                                >

                                </ScreenshotTool>
                            </React.Fragment>
                        : null} */}


                    </React.Fragment>
                : null}                
                
            </React.Fragment>
        );
    }

}


export { MainMap }