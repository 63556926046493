
const mapConfig = () => {

  return {

    public: {
      webmapId: '799434e8c1704e39ab68a9f96e9e6128'
    },
    
    internal: {
      webmapId: '39c0d0d917914b93ad35d1929c2c1cc1',
      appId: 'YaaeWtlMmDcR5aN2',
    },

    portalUrl: 'https://clc-activate.maps.arcgis.com',
    hostedLayerURL: "https://services3.arcgis.com/zFHHoguTBljtcdVm/ArcGIS/rest/services",
    proxiedLayerURL: "https://utility.arcgis.com/usrsvcs/servers/",
    serviceNameURL: "https://www.arcgis.com/sharing/rest/portals/zFHHoguTBljtcdVm/isServiceNameAvailable",
    createServiceURL: "https://www.arcgis.com/sharing/rest/content/users/",
    
    extent: {
      xmin: 140.5054443,
      ymin: -37.310973,
      xmax: 153.7279933,
      ymax: -27.779958,
      spatialReference: {
        wkid: 4326
      }
    },

    pointScale: 400000,

    geocodeUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/',
    webMapBaseUrl: 'https://arcgis.com',

    spatialAnalysis: {
      submitUrl: 'https://analysis3.arcgis.com/arcgis/rest/services/tasks/GPServer/DeriveNewLocations/',
      creditsUrl: ' https://analysis3.arcgis.com/arcgis/rest/services/Estimate/GPServer/EstimateCredits/execute',
      overlayLayerName: "Identified Overlap"
    },

    printSettings: {
      printServiceUrl: "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
      allowedLayouts: ["a4-landscape", "a4-portrait", "map-only"],
      allowedFormats: ["pdf", "png32"],
      templateOptions: {
        title: "Crown Lands",
        copyright: "NSW Department of Industry",
        legendEnabled: true
      }
    },

    layerOptions: {
      opacity: 0.5
    },

    clcPolygons: {
      type: "simple",
      symbol: {
        type: "simple-fill",
        color: [250, 175, 5, 0.25],
        outline: { 
          width: 2, 
          color: [250, 175, 5, 1] 
        }
      }
    },

    clcPoints: {
      color: [250, 175, 5, 1],
      size: "14px",
      shape: 'circle',
      outline: {
        color: "#fff",
        width: 1
      }
    },

    reportSelectedPolygons: {
      title: "Selected Crown Estate Areas",
      listMode: "show",
      legendEnabled: true,
      objectIdField: "ObjectID",
      geometryType: "polygon",
      labelingInfo: [{
        symbol: {
          type: "text",
          color: "#fff",
          font: {
            family: "basier_circleregular",
            size: 12,
            weight: "bold"
          },
          haloColor: "#faaf05",
          haloSize: "4px",
        },
        labelPlacement: "above-center",
        labelExpressionInfo: {
          expression: "$feature.CADID"
        }
      }],
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
       }, {
          name: "cadid",
          alias: "ID",
          type: "string"
       }, {
          name: "classSubtype",
          alias: "Crown Land status type",
          type: "string"
       },
       {
          name: "statusType",
          alias: "Crown Account Type",
          type: "string"
       }
      ],
      spatialReference: { wkid: 4326 },
      source: [],
      renderer: {
        type: "simple",
        symbol: {
          type: "simple-fill",
          // outline: { width: 2, color: [255, 195, 43, 1] },
          // color: [255, 195, 43, 0.5]
          outline: { width: 2, color: [250, 175, 5, 1] },
          color: [250, 175, 5, 0.25]
        }
      }
    },
    
    reportNonSelectedPolygons: {
      title: "Crown Estate",
      listMode: "show",
      legendEnabled: true,
      objectIdField: "ObjectID",
      geometryType: "polygon",
      labelingInfo: [{
        symbol: {
          type: "text",
          color: "#fff",
          font: {
            family: "basier_circleregular",
            size: 11,
            weight: "bold"
          },
          haloColor: "#409fa7",
          haloSize: "4px",
        },
        labelPlacement: "above-center",
        labelExpressionInfo: {
          expression: "$feature.CADID"
        }
      }],
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
       }, {
          name: "cadid",
          alias: "ID",
          type: "string"
       }, {
          name: "classSubtype",
          alias: "Crown Land status type",
          type: "string"
       },
       {
          name: "statusType",
          alias: "Crown Account Type",
          type: "string"
       }
      ],
      spatialReference: { wkid: 4326 },
      source: [],
      renderer: {
        type: "simple",
        symbol: {
          type: "simple-fill",
          outline: { width: 1, color: [71, 170, 177, 1] },
          color: [71, 170, 177, 0.5]
        }
      }
    },

    overlayPolygons: {
      type: "simple",
      symbol: {
        type: "simple-fill",
        outline: { width: 3, color: "#8347B1" },
        color: [131, 71, 177, 0.6]
      }
    },

    overlayPoints: {
      type: "simple",
      symbol: {
        type: "simple-marker",
        style: 'circle',
        color: "#8347B1",
        size: "14px",
        outline: {
          color: "#fff",
          width: 1
        }
      }
    },

    overlayLines: {
      type: "simple",
      symbol: {
        type: "simple-line",
          style: "solid",
          color: "#8347B1",
          width: "4px"
      }
    },

    nearbyPoints: {
      type: "simple",
      symbol: {
        type: "simple-marker",
        style: 'circle',
        color: "#002664",
        size: "14px",
        outline: {
          color: "#fff",
          width: 1
        }
      }
    },

    nearbyPolygons: {
      type: "simple",
      symbol: {
        type: "simple-fill",
        style: "diagonal-cross",
        color: "#002664",
        outline: {
          color: "#002664",
          width: "2px"
        }
      }
    },

    geocodePolygons: {
      type: "simple",
      symbol: {
        type: "simple-fill",
        style: "backward-diagonal",
        color: "#d7153a",
        outline: {
          color:"#d7153a",
          width: "2px"
        }
      }
    },

    geocodePolylines: {
      type: "simple",
      symbol: {
        type: "simple-line",
          style: "solid",
          color: "#d7153a",
          width: "4px"
      }
    },

    geocodePoints: {
      type: "simple",
      symbol: {
        type: "simple-marker",
        style: 'circle',
        color: "#d7153a",
        size: "14px",
        outline: {
          color: "#fff",
          width: 1
        }
      }
    },

  }
}

export { mapConfig }
