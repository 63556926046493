/* This is the sidebar on the Explore page */

import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import { FindForm } from "./FindForm";
import { SearchBar } from './SearchBar';
import { ExploreMenu } from './ExploreMenu';
import { SearchResults } from "./SearchResults";
import {Tabs, Tab} from 'react-bootstrap';
import { redirectToTarget } from '../modules/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-regular-svg-icons';

class Sidebar extends Component {

  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount(){
      //console.log("sidebar mounted")
  }

  componentWillUnmount() {
      //console.log("sidebar will unmount")
  }

  handleSearch() {
    this.props.zoomToGeoCode();
    this.props.displayGeocodeResult();
  }

  render() {
    const { sidebarMode } = this.props;

    return (
      <div id="search">
        <div id="hiddenContainer">
          <div id="collapseBtnContainer">
            <div
              type="submit"
              value="Submit"
              variant="closeSidebar"
              onClick={this.props.toggleSidebar}
              className="esri-component esri-home esri-widget--button esri-widget"
              role="button"
              tabIndex="0"
              aria-label="Show search"
              title="Show search">
              <span aria-hidden="true"
                className={this.props.mapClass === 'active' ? "esri-icon-search" : "esri-icon-left-triangle-arrow"}>
              </span>
              <span className="esri-icon-font-fallback-text">Show search</span>
            </div>
          </div>
        </div>

        <div id="searchWrapper">
            <Tabs defaultActiveKey="profile" id="find-explore-tabs" className="mb-3">
              <Tab eventKey="profile" title="Explore">
                {this.props.isLoaded ? 
                  <React.Fragment>
                    <SearchBar
                      clearGeocodeResults={this.props.clearGeocodeResults}
                      geocodeLatLong={this.props.geocodeLatLong}
                      geocodeOptions={this.props.geocodeOptions}
                      handleGeocodeChange={this.props.handleGeocodeChange}
                      handleGeocodeOptionsUpdate={this.props.handleGeocodeOptionsUpdate}
                      handleSelectChange={this.props.handleSelectChange}
                      mode={this.props.mode}
                      searchReady={this.props.searchReady}
                      selectedGeocode={this.props.selectedGeocode}
                    />
        
                    <Row id="exploreTOC" className="pl-3 mt-5 mb-0">
                      <h2>
                      <img className="mr-1 mapicon"
                        src="./img/map.svg"
                        alt="map"
                        />
                        Map layers
                      </h2>
                      <hr/>
                    </Row>
                    <ExploreMenu
                      isLoaded={this.props.isLoaded}
                      map={this.props.map}
                    />
                    

                    <Row className="mt-5 p-3 btnCreateMap">
                      <Button
                        id="btnCreateMap"
                        value="CREATE MAP"
                        className="btn btn-block clcButton mt-3"
                        disabled={!this.props.searchReady || this.props.searchPending}
                        onClick={() => redirectToTarget('printout', this.props)}
                      >
                        CREATE SNAPSHOT
                      </Button>
                    </Row>
                  </React.Fragment>
                :
                  <img id='loadingImgExplore' alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'}/>
                }
              </Tab>
              <Tab eventKey="home" title="Find">
                {sidebarMode === 'search' ?
                  <FindForm
                    areaMax = {this.props.areaMax}
                    areaMin = {this.props.areaMin}
                    autoRunSearch = {this.props.autoRunSearch}
                    classSubtype = {this.props.classSubtype}
                    clearGeocodeResults = {this.props.clearGeocodeResults}
                    features = {this.props.features}
                    fer={this.props.fer}
                    geocodeOptions={this.props.geocodeOptions}
                    handleGeocodeChange={this.props.handleGeocodeChange}
                    handleGeocodeOptionsUpdate={this.props.handleGeocodeOptionsUpdate}
                    handleNearbyLayerChange={this.props.handleNearbyLayerChange}
                    handleSearchSubmit={this.props.handleSearchSubmit}
                    handleSelectChange={this.props.handleSelectChange}
                    geocodeLatLong={this.props.geocodeLatLong}
                    mode={this.props.mode}
                    nearbyBuffers = {this.props.nearbyBuffers}
                    nearbyDistance={this.props.nearbyDistance}
                    nearbyLayer = {this.props.nearbyLayer}
                    nearbyType = {this.props.nearbyType}
                    nearbyTypes = {this.props.nearbyTypes}
                    overlapFeatureCount={this.props.overlapFeatureCount}
                    overlayLayer={this.props.overlayLayer}
                    page = {this.props.page}
                    queryResponseCount = {this.props.queryResponseCount}
                    reserve={this.props.reserve}
                    resetSettings = {this.props.resetSettings}
                    searchMessages={this.props.searchMessages}
                    searchPending={this.props.searchPending}
                    searchReady={this.props.searchReady}
                    selectedGeocode={this.props.selectedGeocode}
                    sidebarMode = {this.props.sidebarMode}
                    statusType = {this.props.statusType}
                    toggleState={this.props.toggleState}
                    useOverlayLayer={this.props.useOverlayLayer}
                  />
                : 
                    <SearchResults
                      addRemoveChanged = {this.props.addRemoveChanged}
                      addRemoveMode = {this.props.addRemoveMode}
                      areaMax = {this.props.areaMax}
                      areaMin = {this.props.areaMin}
                      autoRunSearch = {this.props.autoRunSearch}
                      classSubtype = {this.props.classSubtype}
                      clearFeatures = {this.props.clearFeatures}
                      statusType = {this.props.statusType}
                      exceededTransferLimit={this.props.exceededTransferLimit}
                      extentChanged={this.props.extentChanged}
                      features = {this.props.features}
                      fer={this.props.fer}
                      geocodeForReport={this.props.geocodeForReport}
                      toggleState={this.props.toggleState}
                      handleSearchSubmit={this.props.handleSearchSubmit}
                      history = {this.props.history}
                      map = {this.props.map}
                      mode = {this.props.mode}
                      nearbyBuffers = {this.props.nearbyBuffers}
                      nearbyDistance = {this.props.nearbyDistance}
                      nearbyLayer = {this.props.nearbyLayer}
                      nearbyType = {this.props.nearbyType}
                      nearbyTypeAlias={this.props.nearbyTypeAlias}
                      paginationCurrentPage={this.props.paginationCurrentPage}
                      queryResponseCount = {this.props.queryResponseCount}
                      removeAllSearchResults = {this.props.removeAllSearchResults}
                      removeFromSearchResults = {this.props.removeFromSearchResults}
                      reserve={this.props.reserve}
                      searchMessages={this.props.searchMessages}
                      searchPending={this.props.searchPending}
                      setSidebarMode = {this.props.setSidebarMode}
                      sidebarMode = {this.props.sidebarMode}
                      toggleAddRemoveMode = {this.props.toggleAddRemoveMode}
                      updatePagination = {this.props.updatePagination}
                      useOverlayLayer={this.props.useOverlayLayer}
                      view = {this.props.view}
                      visibleLayers = {this.props.visibleLayers}
                    />
                }
              </Tab>
            </Tabs>
            
          </div>

      </div>
    );
  }
}

export { Sidebar };
