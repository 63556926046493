
const layerConfig = () => {

  return {

    // These special Crown Lands layers are managed separately from the standard layer list. They appear in the Explore sidebar
    // menu and not the regular Layer List widget. Each Explorer Layer contains Child Layers, which must be an ArcGIS Group Layer.
    // Each Group Layer contains an array of Sub-Layers, which are controlled by the Group Layer parent control.
    // The layers must also have an explicit and unique `id` so they can be switched on in the report/printout pages 
    exploreLayers: [
      {
        title: "Crown Estate",
        childLayers: [
          {
            title: "Crown Estate",
            layerType: "groupLayer",
            id: 'grpEstateAll',
            visible: true,
            metadata: "https://www.industry.nsw.gov.au/lands", // external reference
            subLayers: [
              {
                title: "Crown Estate",
                id: "estate_all_fl",
                layerType: "featureLayer",
                minScale: 100000,
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_public/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                    type: "simple-fill",
                    outline: { width: 0, color: [71, 170, 177, 1] },
                    color: [71, 170, 177, 0.5]
                  }
                },
              },
              {
                title: "Crown Estate",
                id: "estate_all_legend",
                layerType: "featureLayer",
                maxScale: 100000,
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/single_polygon/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                    type: "simple-fill",
                    outline: { width: 0, color: [71, 170, 177, 1] },
                    color: [71, 170, 177, 0.5]
                  }
                },
              },
              {
                title: "Crown Estate",
                id: "estate_all_vt",
                layerType: "vectorTileLayer",
                url: "https://vectortileservices3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_VT/VectorTileServer",
                maxScale: 100000
              }
            ]
          },
          {
            title: "Crown Estate by Type",
            layerType: "groupLayer",
            id: 'grpEstateType',
            visible: false,
            subLayers: [
              {
                title: "Crown Estate by Type",
                id: "estate_type_fl",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_public/FeatureServer/0",
                renderer: {
                  type: "unique-value",
                  legendOptions: {
                    title: "Types"
                  },
                  field: "classsubtype_desc",
                  uniqueValueInfos: [{
                    value: "Crown Road",
                    label: "Crown Road",
                    symbol: {
                      type: "simple-fill",
                      color: [73, 80, 84, 0.5],
                      outline: { width: 0, color: [0, 0, 0, 1] },
                    }
                  }, {
                    value: "Crown Parcel",
                    label: "Crown Parcel",
                    symbol: {
                      type: "simple-fill",
                      color: [0, 170, 69, 0.5],
                      outline: { width: 0, color: [0, 0, 0, 1] },
                    }
                  }, {
                    value: "Crown Waterway",
                    label: "Crown Waterway",
                    symbol: {
                      type: "simple-fill",
                      color: [20, 108, 253, 0.5],
                      outline: { width: 0, color: [0, 0, 0, 1] },
                    }
                  }]
                },
              }
            ]
          },
        ]
      },
      {
        title: "Usage Details",
        childLayers: [
          {
            title: "Reserves",
            layerType: "groupLayer",
            metadata: "https://www.industry.nsw.gov.au/lands/what-we-do/crown-land/reserves", // external reference
            visible: false,
            subLayers: [
              {
                title: "Reserve",
                id: "usage_reserve",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/Crown_Reserve/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                      type: "simple-fill",
                      style: "vertical",
                      color: "#8055f1",
                      outline: {
                        color: "#8055f1",
                        width: "1px"
                      }
                  }
                },
                popupTemplate: {
                  title: "Reserve",
                  content: [
                    {
                      type: "text",
                      text: 
                      "<p>The Reserve <b>{crownlregno}</b> is managed by <b>{manager}</b> of NSW.</p>" +
                      "<p>It was established for the purpose of <b>{purpose}</b>.</p>" +
                      "<p>The Regional Crown Lands office to contact about this Reserve is <b>{office}</b>.</p>"
                    },
                    {
                      type: "fields",
                      title: "Details",
                      fieldInfos: [
                        {
                          fieldName: "OBJECTID",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "cadid",
                          label: "CADID"
                        },
                        {
                          fieldName: "crownlregno",
                          label: "Registration Number"
                        },
                        {
                          fieldName: "crownaccountid",
                          label: "Account ID"
                        },
                        {
                          fieldName: "gazetted_date",
                          label: "Gazetted Date"
                        },
                        {
                          fieldName: "reserve_type",
                          label: "Reserve Type"
                        },
                        {
                          fieldName: "management_type",
                          label: "Management Type"
                        },
                        {
                          fieldName: "additional_purpose",
                          label: "Additional Purpose"
                        },
                        {
                          fieldName: "hectares",
                          label: "Hectares"
                        },
                        // INFO
                        {
                          fieldName: "reserve_name",
                          label: "Reserve Name"
                        },
                        {
                          fieldName: "office",
                          label: "Crown Lands Office"
                        },
                        {
                          fieldName: "manager",
                          label: "Manager"
                        },
                        {
                          fieldName: "purpose",
                          label: "Purpose"
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            title: "Lease",
            layerType: "groupLayer",
            visible: false,
            metadata: "https://www.industry.nsw.gov.au/lands/use/leases", // external reference
            subLayers: [
              {
                title: "Leases",
                id: "usage_leases",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/CROWNLEASE/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                      type: "simple-fill",
                      style: "backward-diagonal",
                      color: "#d912ae",
                      outline: {
                      color: "#d912ae",
                      width: "1.5px"
                      }
                  }
                },
                popupTemplate: {
                  title: "Lease",
                  content: [
                    {
                      type: "text",
                      text: 
                      "<p>This <b>{tenure_type}</b> is a <b>{tenure_sub_type}</b> lease.</p>" +
              
                      "<p>The Regional Crown Lands office to contact about this Lease is <b>{office}</b>.</p>"
                    },
                    {
                      type: "fields",
                      title: "Details",
                      fieldInfos: [
                        {
                          fieldName: "OBJECTID",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "cadid",
                          label: "CADID"
                        },
                        {
                          fieldName: "crownlregno",
                          label: "Registration Number"
                        },
                        {
                          fieldName: "crownaccountid",
                          label: "Account ID"
                        },
                        {
                          fieldName: "crownaccounttype_desc",
                          label: "Account Description"
                        },
                        {
                          fieldName: "account_class",
                          label: "Account Class"
                        },
                        {
                          fieldName: "purpose",
                          label: "Purpose"
                        },
                        {
                          fieldName: "hectares",
                          label: "Hectares"
                        },
                        // INFO
                        {
                          fieldName: "tenure_type ",
                          label: "Tenure Type"
                        },
                        {
                          fieldName: "tenure_sub_type",
                          label: "Tenure Subtype"
                        },
                        {
                          fieldName: "office",
                          label: "Crown Lands Office"
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            title: "Licenses",
            layerType: "groupLayer",
            visible: false,
            metadata: "https://www.industry.nsw.gov.au/lands/use/licences", // external reference
            subLayers: [
              {
                title: "Licenses",
                id: "usage_licenses",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/CROWNLICENCE/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                      type: "simple-fill",
                      style: "forward-diagonal",
                      color: "#f3631b",
                      outline: {
                      color: "#f3631b",
                      width: "1.5px"
                      }
                  }
                },
                popupTemplate: {
                  title: "License",
                  content: [
                    {
                      type: "text",
                      text: 
                      "<p>This <b>{tenure_type}</b> is a <b>{tenure_sub_type}</b> license.</p>" +
              
                      "<p>The Regional Crown Lands office to contact about this License is <b>{office}</b>.</p>"
                    },
                    {
                      type: "fields",
                      title: "Details",
                      fieldInfos: [
                        {
                          fieldName: "OBJECTID",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "cadid",
                          label: "CADID"
                        },
                        {
                          fieldName: "crownlregno",
                          label: "Registration Number"
                        },
                        {
                          fieldName: "crownaccountid",
                          label: "Account ID"
                        },
                        {
                          fieldName: "crownaccounttype_desc",
                          label: "Account Description"
                        },
                        {
                          fieldName: "account_class",
                          label: "Account Class"
                        },
                        {
                          fieldName: "purpose",
                          label: "Purpose"
                        },
                        {
                          fieldName: "hectares",
                          label: "Hectares"
                        },
                        // INFO
                        {
                          fieldName: "tenure_type ",
                          label: "Tenure Type"
                        },
                        {
                          fieldName: "tenure_sub_type",
                          label: "Tenure Subtype"
                        },
                        {
                          fieldName: "office",
                          label: "Crown Lands Office"
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            title: "Enclosure Permits",
            layerType: "groupLayer",
            visible: false,
            metadata: "https://www.industry.nsw.gov.au/lands/use/enclosure-permits", // external reference
            subLayers: [
              {
                title: "Enclosure Permits",
                id: "usage_permits",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/CROWNENCLOSUREPERMIT/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                      type: "simple-fill",
                      style: "horizontal",
                      color: "#523719",
                      outline: {
                        color: "#523719",
                        width: "1.5px"
                      }
                  }
                },
                popupTemplate: {
                  title: "Enclosure Permit",
                  content: [
                    {
                      type: "text",
                      text: 
                      "<p>This <b>{tenure_type}</b> is a <b>{tenure_sub_type}</b> enclosure premit.</p>" +
              
                      "<p>The Regional Crown Lands office to contact about this Enclosure Permit is <b>{office}</b>.</p>"
                    },
                    {
                      type: "fields",
                      title: "Details",
                      fieldInfos: [
                        {
                          fieldName: "OBJECTID",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "cadid",
                          label: "CADID"
                        },
                        {
                          fieldName: "crownlregno",
                          label: "Registration Number"
                        },
                        {
                          fieldName: "crownaccountid",
                          label: "Account ID"
                        },
                        {
                          fieldName: "crownaccounttype_desc",
                          label: "Account Description"
                        },
                        {
                          fieldName: "account_class",
                          label: "Account Class"
                        },
                        {
                          fieldName: "purpose",
                          label: "Purpose"
                        },
                        {
                          fieldName: "hectares",
                          label: "Hectares"
                        },
                        // INFO
                        {
                          fieldName: "tenure_type ",
                          label: "Tenure Type"
                        },
                        {
                          fieldName: "tenure_sub_type",
                          label: "Tenure Subtype"
                        },
                        {
                          fieldName: "office",
                          label: "Crown Lands Office"
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            title: "Usage Areas",
            layerType: "groupLayer",
            visible: false,
            subLayers: [
              {
                title: "Usage Areas",
                id: "usage_areas_fl",
                layerType: "featureLayer",
                url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_public/FeatureServer/0",
                renderer: {
                  type: "simple",
                  symbol: {
                    type: "simple-fill",
                    outline: { width: 1, color: [71, 170, 177, 1] },
                    color: [71, 170, 177, 0.5]
                  }
                },
                popupTemplate: {
                  title: "Usage Areas",
                  content: [
                    {
                      type: "text",
                      text: 
                      "<p>The area <b>{CADID}</b> is located on <b>{classsubtype_desc}</b> ."
                    },
                    {
                      type: "fields",
                      title: "Details",
                      fieldInfos: [
                        {
                          fieldName: "OBJECTID",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "cadid",
                          label: "ID"
                        },
                        {
                          fieldName: "classsubtype_desc",
                          label: "Crown Estate Type"
                        }
                      ]
                    }
                  ]
                }
              },
            ]
          }, 
        ]
      },
      {
        title: "Reference Information",
        childLayers: [
          {
            title: "Property Boundaries",
            layerType: "groupLayer",
            visible: false,
            metadata: "https://www.nswlrs.com.au/Plan-Types", // external reference
            subLayers: [
              {
                title: "Property Boundaries (Lots)",
                id: "ref_lot",
                layerType: "featureLayer",
                url: "https://portal.spatial.nsw.gov.au/server/rest/services/NSW_Land_Parcel_Property_Theme/FeatureServer/8",
                renderer: {
                  type: "simple",
                  symbol: {
                    type: "simple-fill",
                    outline: { 
                      width: 1, 
                      color: "#002664"
                    },
                    color: [0, 38, 100, 0]
                  }
                },
                labelingInfo: [{
                  symbol: {
                    type: "text",
                    color: "#002664",
                    font: {
                      family: "basier_circleregular",
                      size: 10,
                      weight: "bold"
                    },
                    haloColor: "#ffffff",
                    haloSize: "2px",
                  },
                  maxScale: 0,
                  minScale: 5000,
                  labelPlacement: "above-center",
                  labelExpressionInfo: {
                    expression: "$feature.lotidstring"
                  },
                }],
                popupTemplate: {
                  title: "Map Layer: Property Boundaries",
                  content: [
                    {
                      type: "fields",
                      fieldInfos: [
                        {
                          fieldName: "objectid",
                          label: "Object ID",
                          visible: false
                        },
                        {
                          fieldName: "lotnumber",
                          label: "lotnumber"
                        },
                        {
                          fieldName: "planlabel",
                          label: "planlabel"
                        },
                        {
                          fieldName: "lotidstring",
                          label: "lotidstring"
                        },
                      ]
                    }
                  ]
                }
              },
            ]
          },
          {
            title: "Aerial Imagery",
            layerType: "groupLayer",
            visible: false,
            subLayers: [
              {
                title: "NSW Satellite Image Service [dev]",
                id: "ref_image",
                layerType: "mapImageLayer",
                url: "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer",
                listMode: 'hidden',
                legendEnabled: false
              },
            ]
          },
        ]
      }
    ],

    internal: {
      clcLayer: {
        id: "crownestate",
        alias: "All Crown Estate",
        url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_phase3/FeatureServer/0",
        outFields: ["cadid", "crownlandstatustype_desc", "classsubtype_desc", "disposalstatus_desc", 'Reserve', 'area_sqm'], 
        // 'FERD_AGRI','FERD_RESO', 'FERD_TOUR', 'FERD_TERT', 'FERD_HEAL', 'FERD_FREI', 'FERD_DEFE', 'FERD_ADVA', 'FERD_RENE', 'FERD_TECH', 
        idField: "cadid",
        ferFields: [
          {name: 'FERD_AGRI', label: 'Agribusiness & Forestry'},
          {name: 'FERD_RESO', label: 'Resources & Mining'},
          {name: 'FERD_TOUR', label: 'Tourism & Hospitality'},
          {name: 'FERD_TERT', label: 'Tertiary Education & Skills'},
          {name: 'FERD_HEAL', label: 'Health & Residential Care'},
          {name: 'FERD_FREI', label: 'Freight & Logistics'},
          {name: 'FERD_DEFE', label: 'Defence'},
          {name: 'FERD_ADVA', label: 'Advanced Manufacturing'},
          {name: 'FERD_RENE', label: 'Renewable Energy'},
          {name: 'FERD_TECH', label: 'Technology Enabled Primary Industries'}
        ],
        areaField: 'area_sqm',
        reserveField: 'Reserve',
        fieldConfigs: [
          {
            name: "cadid",
            label: "ID",
            direction: "asc"
          },
          {
            name: "crownlandstatustype_desc",
            label: "STATUS TYPE"
          },
          {
            name: "classsubtype_desc",
            label: "CLASS SUBTYPE"
          },
          {
            name: "disposalstatus_desc",
            label: "DISPOSAL STATUS"
          },
          {name: 'FERD_AGRI', label: 'Agribusiness & Forestry'},
          {name: 'FERD_RESO', label: 'Resources & Mining'},
          {name: 'FERD_TOUR', label: 'Tourism & Hospitality'},
          {name: 'FERD_TERT', label: 'Tertiary Education & Skills'},
          {name: 'FERD_HEAL', label: 'Health & Residential Care'},
          {name: 'FERD_FREI', label: 'Freight & Logistics'},
          {name: 'FERD_DEFE', label: 'Defence'},
          {name: 'FERD_ADVA', label: 'Advanced Manufacturing'},
          {name: 'FERD_RENE', label: 'Renewable Energy'},
          {name: 'FERD_TECH', label: 'Technology Enabled Primary Industries'},
          {name: 'Reserve', label: 'Reserve'}
        ]
      }
    },

    public: {

      clcLayer: {
        id: "crownestate",
        alias: "All Crown Estate",
        url: "https://services3.arcgis.com/zFHHoguTBljtcdVm/arcgis/rest/services/AllCrownEstate_public/FeatureServer/0",
        outFields: ["cadid", "classsubtype_desc", 'area_sqm'], 
        idField: "cadid",
        
        areaField: 'area_sqm',
        fieldConfigs: [
          {
            name: "cadid",
            label: "ID",
            direction: "asc"
          },
          {
            name: "classsubtype_desc",
            label: "Type"
          },
          {
            name: "area_sqm",
            label: "Area (sqm)"
          }
        ]
      }

    },

    nearmap: {
      url: "https://au0.nearmap.com/maps/api/wmts/v1/apikey/NDU5YmFiOWItNjM4NC00ODUwLTk3MzItOWRkY2IzNzEwNjA1",
      id: "nearmap",
      visible: false,
      title: "Nearmap"
    },

    imageLayers: [
      {
        "id": "vegetation",
        "title": "Vegetation Classes of NSW - v3.03, 200m Raster (DPIE, 2012)",
        "visible": false,
        "url": "https://mapprod.environment.nsw.gov.au/arcgis/rest/services/Vegetation_IBCA/nswmap_2_3a_ext/MapServer"
      },
      {
        "id": "clum",
        "title": "Catchment Scale Land Use (CSIRO, 2018)",
        "visible": false,
        "url": "https://www.asris.csiro.au/arcgis/rest/services/abares/clum_50m_2018/MapServer",
        "childLayers": [
          {
            "title": "Catchment Scale Land Use - 18 class classification (CSIRO, 2018)",
            "index": 0,
            "visible": false
          },
          {
            "title": "Catchment Scale Land Use - Primary classification (CSIRO, 2018)",
            "index": 1,
            "visible": false
          },
          {
            "title": "Catchment Scale Land Use - Secondary classification (CSIRO, 2018)",
            "index": 2,
            "visible": false
          },
          {
            "title": "Catchment Scale Land Use - Agricultural industries (CSIRO, 2018)",
            "index": 3,
            "visible": false
          },
          {
            "title": "Catchment Scale Land Use - Agriculture (CSIRO, 2018)",
            "index": 4,
            "visible": false
          }
        ]
      }

    ]

  }
}

export { layerConfig }
