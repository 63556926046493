import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { loadModules } from 'esri-loader';
import { Filter } from './Filter';
import { uniqueId } from 'lodash';

class FilterTool extends Component {
    constructor(props) {
      super(props);
      this.state = {
          filtersValid: true,
          filterAndOr: 'and',
        //   height: null
      }
      this.addExpression = this.addExpression.bind(this);
      this.applyFilters = this.applyFilters.bind(this);
      this.updateFilter = this.updateFilter.bind(this);
      this.deleteFilter = this.deleteFilter.bind(this);
      this.setOperator = this.setOperator.bind(this);
      this.checkFilterValidity = this.checkFilterValidity.bind(this);
      this.changeAndOrOperator = this.changeAndOrOperator.bind(this);
  
    }
  
    componentDidMount(){
        console.log("filter tool mounted")
    }
  
    componentWillUnmount() {
        console.log("filter tool will unmount")
    }

    async addExpression(){
        try{
            console.log("Add Expression")
            let filters = this.props.filterLayer.filters;
            let id = uniqueId();
            let fieldName = this.props.filterLayer.filterFields[0].name;
            let fieldType = this.props.filterLayer.filterFields[0].type;
            let alias = this.props.filterLayer.filterFields[0].alias;
            let operators = this.setOperator(fieldName);
            let filter = {
                id: id,
                exceededTransferLimit: {},
                fieldName: fieldName,
                fieldType: fieldType,
                alias: alias,
                operator: operators[0],
                value: '',
                valid: false,
                uniqueValues: {}
            }
            filters.push(filter);
            await this.props.saveFiltersToLayer(filters);

            if (fieldType === 'string'){
                // simulate a change event to retrieve valid string values
                let event = {
                    filter: filter,
                    target: {
                        name: "fieldName",
                        value: fieldName
                    }
                }
                await this.updateFilter(event)
            }
            
            this.checkFilterValidity()

        }catch(err){
            console.error("There was a problem creating a new filter", err)
        }
    }

    setOperator(fieldName){
        // choose the appropriate operators for this field
        let operators = [];
        try{
            
            let targetField = this.props.filterLayer.filterFields.find(o => o.name === fieldName);
            if (targetField.type === 'string'){
                operators = ["is", "is not"]; // these don't work with URL paramaeters, "starts with", "ends with"];
            } else if (['integer', 'small-integer', 'double', 'float', 'oid'].includes(targetField.type)){
                operators = ['=', '<', '<=', ">", ">="] 
            } else if (targetField.type === 'date'){
                operators = ["on", "before", "after"]
            }
            
        } catch(err){
            console.error("There was a problem choosing the operator", err)
        }
        return operators;        
    }

    async updateFilter(event){
        let filters = JSON.parse(JSON.stringify(this.props.filterLayer.filters));
        let filter = filters.find(o => o.id === event.filter.id);
        let name, value;
        if (event.data) {
            name = event.data.name;
            value = event.value;
        } else {
            name = event.target.name;
            value = event.target.value;
        }
        
        try{
            if (name === 'fieldName'){
                let field = this.props.filterLayer.filterFields.find(o => o.name === value);
                // Update the alias
                filter.alias = field.alias;
                if (field && field.type === 'string'){
                    if (!filter.uniqueValues[field.name]) {
                        console.log("Check unique values for", field.name)
                        const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);
                        let qTask = new QueryTask(this.props.filterLayer.parsedUrl.path);
                        let query = new Query({
                            where: "1=1",
                            outFields: [field.name],
                            orderByFields: [field.name],
                            returnDistinctValues: true
                        });
                        qTask.execute(query).then(results => {
                            filter.exceededTransferLimit[field.name] = results.exceededTransferLimit;
                            let uniqueValues = results.features.map(feature => {
                                return feature.attributes[field.name]
                            });
                            filter.uniqueValues[field.name] = uniqueValues;
                            this.setState({filter})
                            
                        }).catch(error => {
                            console.error("unique query error", error)
                        })
                    }
                }
            }
        } catch (err){
            console.error("There was a problem obtaining unique filter values", err)
        }
        filter[name] = value;
        filter.fieldName && filter.operator && filter.value ? filter.valid = true : filter.valid = false;
        filter.fieldType = this.props.filterLayer.filterFields.find(o => o.name === filter.fieldName).type;
        filters.splice(filters.indexOf(filter), 1, filter)
        await this.props.saveFiltersToLayer(filters);
        this.checkFilterValidity()

    }

    checkFilterValidity(){
        // Check the validity of each filter
        let filtersValid = true;
        this.props.filterLayer.filters.forEach(filter => {
            let valid = true;
            if (filter.fieldName === null || filter.fieldName === undefined){
                valid = false;
            }
            if (filter.operator === null || filter.operator === undefined){
                valid = false;
            }
            if (filter.value === '' || filter.value === null || filter.value === undefined){
                valid = false;
            }
            if (filter.fieldType === 'date'){
                if (isNaN(Date.parse(filter.value))){
                    valid = false;
                }
            } else if (['integer','small-integer'].indexOf(filter.fieldType) > -1){
                if (isNaN(parseInt(filter.value))){
                    valid = false;
                }
            } else if (['double', 'single'].indexOf(filter.fieldType) > -1){
                if (isNaN(parseFloat(filter.value))){
                    valid = false;
                }
            } else if (typeof(filter.value) !== filter.fieldType){
                valid = false;
            }
            filter.valid =  valid;
            
            if (!filter.valid) {
                filtersValid = false;
            }
        });
        this.setState({filtersValid});
    }

    applyFilters(){
        if (!this.state.filtersValid) {
            console.error("filters are not valid")
            return;
        }
        console.log("Apply filters")
        try{
            // Persist the filters on the layer for later retrieval
            // this.props.filterLayer.filters = this.props.filterLayer.filters;

            // Create a definition query from the filters
            let whereClause;
            let whereTitle;
            this.props.filterLayer.filters.forEach(filter => {
                let fieldName = filter.fieldName;
                let alias = filter.alias;
                let value = filter.value;
                let operator = filter.operator;
                
                // Unpack the operator
                let operator2 = this.decodeOperator(operator)

                let fieldType = filter.fieldType;
                let queryString;
                let aliasString;
                if (['string', 'date'].includes(fieldType)){
                    // TODO: date fields are not currently supported but may be added. MainMap.js line 411.
                    if (fieldType === 'date'){
                        if (operator === "=") {
                            console.log("value", value)
                            queryString = fieldName + " >= '" + value + " 00:00:00' and " + fieldName + " <= '" + value + " 23:59:59'";
                            aliasString = alias + " >= '" + value + " 00:00:00' and " + alias + " <= '" + value + " 23:59:59'";
                        } else {
                            queryString = fieldName + operator2 + "'" + value + "'";
                            aliasString = alias + operator2 + "'" + value + "'";
                        }   
                    } else {
                        queryString = fieldName + operator2 + "'" + value + "'";
                        aliasString = alias + operator2 + "'" + value + "'";
                    }
                } else if (['integer', 'small-integer', 'double', 'oid'].includes(fieldType)){
                    queryString = fieldName + operator2 + value;
                    aliasString = alias + operator2 + value;
                }


                if (!whereClause) {
                    whereClause = queryString;
                    if (whereTitle) {
                        whereTitle = this.state.filterAndOr.replace("and", "match all conditions").replace("or", "match any conditions") + " : " + whereTitle + ", " + aliasString.replaceAll("=", " = ");
                    } else {
                        whereTitle = aliasString.replaceAll("=", " = ");
                    }
                    
                } else {
                    whereClause += " " + this.state.filterAndOr + " " + queryString;
                    if (whereTitle) {
                        whereTitle = this.state.filterAndOr.replace("and", "match all conditions").replace("or", "match any conditions") + " : " +  whereTitle + ", " + aliasString.replaceAll("=", " = ");
                    } else {
                        whereTitle = aliasString.replaceAll("=", " = ");
                    }
                }

                if (!whereClause) {
                    // TODO: display popup error message
                    console.error("There was a problem setting the where clause");
                    filter.valid = false;
                    return;
                }
            })
            
            console.log("adding filter to layer", this.props.filterLayer.title, whereClause);
            this.props.filterLayer.definitionExpression = whereClause;

            // Add the filtered state to the title
            let title = this.props.filterLayer.title;
            if (title.indexOf(" (Filter") > 0) {
                title = title.substr(0, title.indexOf(" (Filter - "))
            }
            
            if (whereClause){
                title = title += " (Filter - " + whereTitle + ")";                
            }
            console.log("title:", title)
            this.props.renameFilterLayer(title);

            // this.props.filterLayer.refresh();

        } catch(err){
            console.error("There was a problem applying the filter", err)
        }
        
    }

    changeAndOrOperator(evt){
        this.setState({filterAndOr: evt.target.value})
    }

    decodeOperator(operator){
        // Convert the human-readable operator to a query statement value
        switch(operator){
            case 'is':
                return '=';
            case 'on':
                return '=';
            case 'is not':
                return '<>';
            case 'before':
                return '<';
            case 'after':
                return '>';
            default:
                return operator;
        }
    }

    async deleteFilter(event){
        // The filter ID is either on this element, or its parent in the case of clicking on the icon
        let filterId = event.target.dataset["filterid"];
        if (!filterId) {
            filterId = event.target.parentElement.dataset["filterid"];
        }
        if (filterId){
            await this.props.deleteFilterFromLayer(filterId)
            this.checkFilterValidity();
            this.applyFilters()
        } else {
            console.error("Unable to delete filter")
        }
    }

    
    render(){

        let andOrSelector;
        if (this.props.filterLayer.filters.length > 1){
            andOrSelector = (
                <Form.Row className="mt-3">
                    <Col sm={12}>
                        <Form.Control as="select" name="andOr" onChange={this.changeAndOrOperator} className='react-select__control mt-3 mb-3'>
                            <option value="and" name="andOr" selected={this.state.filterAndOr==='and'}>Match all of the conditions</option>
                            <option value="or" name="andOr" selected={this.state.filterAndOr==='or'}>Match any of the conditions</option>
                        </Form.Control>
                    </Col>
                </Form.Row>
            )
        }

        // Set the dialog height based on the number of filters. Slightly dodgy but....
        let label = document.getElementById("labelFilterName");
        let labelHeight = 0
        if (label){
            labelHeight = label.clientHeight - 20;
        } else if (this.props.filterLayer.title) {
            // The component may render before the label has been set
            labelHeight = 50;
        }
        let numFilters = this.props.filterLayer.filters.length;
        let height = 220;
        if (numFilters === 1) {
            height = 450 + labelHeight;
        } else if (numFilters >= 2) {
            height = 720 + labelHeight;
        }

        const divStyle = {
            height: height + 'px',
        };

        return (
            <Draggable
                bounds='#mapDiv'
                defaultPosition={{x: 0, y: 0}}
            >
                {this.props.filterLayer ? 
                    <div id="filterToolDiv" style={divStyle} className={"esri-component esri-widget customTool " + (this.props.searchPending ? 'hidden' : '')}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggleFilter}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <Row>
                            <Col xs={11} className="pl-0">
                                <h2>Filter</h2>
                                <p className="pt-2">Subset selected map layer based on attributes.</p>
                            </Col>
                        </Row>
                        <label id="labelFilterName" className="form-label">Selected map layer: {this.props.filterLayer.title}</label>

                        <Form id="filterForm" className="form pt-2" onSubmit={e => { e.preventDefault(); }}>
                            <Button
                                // type="submit"
                                // value="estimateCredits"
                                className="pl-0 pt-0"
                                variant="turquoiseText"
                                onClick={this.addExpression}
                            >
                                <img className="mr-1 resultsIcon"
                                    src="./img/expression.svg"
                                    // onMouseOver={e => (e.currentTarget.src = "./img/clear.svg")}
                                    // onMouseOut={e => (e.currentTarget.src ="./img/clear.svg")}
                                    alt="add expression"
                                />
                                ADD CONDITION
                            </Button>
                            {this.props.filterLayer.filters.map((filter, key) =>
                                <Filter
                                    fields = {this.props.filterLayer.filterFields}
                                    key={key}
                                    filter={filter}
                                    deleteFilter={this.deleteFilter}
                                    setOperator={this.setOperator}
                                    updateFilter={this.updateFilter}
                                >
                                </Filter>
                            )}

                            {this.props.filterLayer.filters.length > 0 ? 
                                <React.Fragment>
                                    {andOrSelector}

                                    <Form.Row>
                                        <Col sm={12}>
                                            <Button
                                                id="btnSubmitFilter"
                                                type="submit"
                                                value="Submit"
                                                className="btn btn-block clcButton mt-3"
                                                onClick={this.applyFilters}
                                                disabled={!this.state.filtersValid || this.props.mapIsUpdating}
                                            >APPLY</Button>
                                        </Col>
                                    </Form.Row>
                                </React.Fragment>
                            : null}
               
                        </Form>
                    </div>
                : null}
            </Draggable>
        )
    }
}


export { FilterTool };