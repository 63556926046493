import React, { Component } from 'react';
import { Modal, Button, Form, Col} from 'react-bootstrap';
import { mapConfig } from '../config/mapConfig.js';
import axios from 'axios';

class SaveToAgol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      layerName: null,
      message: null,
      processing: false,
      itemId: null      
    };

    this.checkLayerName = this.checkLayerName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createService = this.createService.bind(this);
    this.writeFeatures = this.writeFeatures.bind(this);

  }

  componentDidMount(){
    
  }

  componentWillUnmount() {
    
  }

  checkLayerName(evt){
    evt.preventDefault();
    this.setState({processing: true, error: false, message: null, itemId: null})

    // Check whether layer name is unique in SmartSpace
    var url = mapConfig().serviceNameURL + "?type=Feature%20Service&f=json&name="+ this.state.layerName
    url += "&token=" + this.props.token;
    axios.get(url)
      .then((response) => {
        if (response.data.available) {
          this.createService()
        } else {
          this.setState({
            message: "Layer name is not available",
            error: true,
            processing: false
          })
        }
        
      })
      .catch((err) => {
        console.error("There was a problem saving the layer to ArcGIS Online", err);
        this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
      });
  }

  createService(){
    // Create a new empty layer to hold the output layer
    let params = {
      createParameters: JSON.stringify({
        "name": this.state.layerName,
        "description": "Layer created by Activate",
        "capabilities": "Create,Delete,Query,Update,Editing",
        "allowGeometryUpdates" : true,
        "units" : "esriMeters",
        "xssPreventionInfo" : {
          "xssPreventionEnabled" : true,
          "xssPreventionRule" : "input",
          "xssInputRule" : "rejectInvalid"
        }
      }),
      outputType: "featureService",
      f: "json",
      token: this.props.token
    }

    let data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');

    let url = mapConfig().createServiceURL + this.props.userId + "/createService"
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data,
      url: url
    };

    axios(options)
      .then((response) => {
        if (!response.data.success) {
          console.error("There was a problem saving the layer to ArcGIS Online");
          this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
        } else {
          let itemId = response.data.itemId;
          this.setState({itemId})
          let layerUrl = response.data.serviceurl;
          let name = response.data.name;
          this.addToDefinition(name, layerUrl, itemId)
        }
      })
      .catch((err) => {
        console.error("There was a problem saving the layer to ArcGIS Online", err);
        this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
      });
  }

  addToDefinition(layerName, layerUrl, itemId) {
    // https://developers.arcgis.com/rest/services-reference/add-to-definition-feature-service-.htm

    let geometryType = this.props.saveToAgolLayer.geometryType;
    if (geometryType === 'polygon'){
      geometryType = 'esriGeometryPolygon';
    } else if (geometryType === 'point'){
      geometryType = 'esriGeometryPoint';
    } else if (geometryType === 'polyline'){
      geometryType = "esriGeometryPolyine";
    }

    let layerJSON = {
      "id": 0,
      "name": layerName,
      "type": "Feature Layer",
      "serviceItemId": itemId,
      "supportsAppend": true,
      "geometryType": geometryType,
      "extent": this.props.saveToAgolLayer.fullExtent,
      "drawingInfo": {
        "renderer": this.props.saveToAgolLayer.renderer,
        "transparency": 0,
        "labelingInfo": null
      },
      "allowGeometryUpdates": true,
      "objectIdField": this.props.saveToAgolLayer.objectIdField,
      "fields": this.props.saveToAgolLayer.fields,
      "supportedQueryFormats": "JSON, geoJSON, PBF",
      "supportedAppendFormats": "sqlite,gpkg,shapefile,filegdb,featureCollection,geojson,csv,excel",
      "capabilities": "Query"
    }

    var params = {
      "addToDefinition": JSON.stringify({
        "layers":[layerJSON]
      }),
      "f": "json",
      "token": this.props.token
    }

    let url = layerUrl.replace("/rest/services/", "/rest/admin/services/");
    url += "/addToDefinition";

    let data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data,
      url: url
    };

    axios(options)
      .then((response) => {
        if (!response.data.success) {
          console.error("There was a problem saving the layer to ArcGIS Online");
          this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
        } else {
          this.writeFeatures(layerName, layerUrl, itemId)
        }
      })
      .catch((err) => {
        console.error("There was a problem saving the layer to ArcGIS Online", err);
        this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
      });
  }

  writeFeatures(layerName, layerUrl, itemId) {

    // Send the polygon(s) to the new layer which was previously created. This will always be index 0 since
    // we only just created the layer
    var url = layerUrl + '/0/addFeatures?f=json&token=' + this.props.token;

    // Fetch all fields except object id
    let oidField = this.props.saveToAgolLayer.objectIdField;
    let fields = this.props.saveToAgolLayer.fields.map(field => {
      return field.name;
    });
    var index = fields.indexOf(oidField);
    if (index !== -1) {
      fields.splice(index, 1);
    }
    let query = {
      where: "1=1",
      outFields: fields,
      returnGeometry: true
    }

    this.props.saveToAgolLayer.queryFeatures(query)
      .then(response => {
        if (response.exceededTransferLimit) {
          console.error("Exceeded transfer limit - not all features were retrieved from the layer")
        }
        var features = response.features;
        var params = {
          features: JSON.stringify(features)
        };
    
        let data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
    
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: url
        };
    
        axios(options)
          .then((response) => {
            this.setState({processing: false, error: false, message: null})
          })
          .catch((err) => {
            console.error("There was a problem saving the layer to ArcGIS Online", err);
            this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
          });

      })
      .catch((err) => {
        console.error("There was a problem saving the layer to ArcGIS Online", err);
        this.setState({processing: false, error: true, message: "There was a problem saving the layer to ArcGIS Online"})
      });
  }

  handleChange(evt){
    let value = evt.target.value;
    if (value) {
      value = value.replaceAll(" ", "_")
    }
    this.setState({
      [evt.target.name]: value,
      error: false,
      message: null,
      processing: false,
      itemId: null
    });
  }

  render() {

    let newLayerUrl;
    if (!this.state.processing && !this.state.error && this.state.itemId){
      newLayerUrl = mapConfig().portalUrl + "/home/item.html?id=" + this.state.itemId;
    }

    return (
      <React.Fragment>
        <Modal
          id="modalSaveToAGOL"
          show={this.props.showSaveModal}
          onHide={this.props.closeSaveModal}
          centered
          className="text-center"
        >
          <Modal.Header className="d-block text-center">
            <h2>Save to ArcGIS Online</h2>
            <button id="btnCloseSaveToAgol" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeSaveModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row>
                <Col xs={12} md={12} lg={12}>
                    <input
                      id="txtSaveToAgolName"
                      type="text"
                      name="layerName"
                      value={this.state.layerName}
                      onChange={this.handleChange}
                      autoComplete="off"
                      disabled={this.state.processing}
                      className='bordered clcInput'
                      placeholder="Enter the output layer name"
                    />
                  </Col>
              </Form.Row>

              {this.state.message ? 
                <Form.Row className="pl-2 pt-1">           
                  <Col xs={12} className="">
                  <p className={"mb-0 message " + (this.state.error ? 'error' : '')}>
                      {this.state.message}
                    </p>
                  </Col>
                </Form.Row>
                
              : null}   

              <Form.Row className={(this.state.error ? 'pt-0' : 'pt-4')}>
                <Col xs={12} md={12} lg={12}>
                  <Button
                    id="btnSaveToAgol"
                    className='clcButton'
                    variant="secondary"
                    onClick={this.checkLayerName}
                    disabled={this.state.layerName === null || this.state.layerName === '' || this.state.processing
                  }>
                  {this.state.processing ? 
                    "PROCESSING" : "SAVE"
                  }
                  
                </Button>
              </Col>
            </Form.Row>

              {newLayerUrl ?                                            
                <Form.Row className="pl-2 pt-3">           
                  <Col xs={12} className="">
                    <p id="txtViewLayerInAGOL">
                      <a className="turquoiseText" target="_blank" rel="noreferrer" href={newLayerUrl}>
                        <img className="mr-1 resultsIcon"
                            src="./img/viewlayer.svg"
                            // onMouseOver={e => (e.currentTarget.src = "./img/clear.svg")}
                            // onMouseOut={e => (e.currentTarget.src ="./img/clear.svg")}
                            alt="add expression"
                        />
                        VIEW LAYER IN ARCGIS ONLINE</a>
                    </p>
                  </Col>
                </Form.Row>
                
              : null}
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SaveToAgol;

