import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Landing from "./Landing"
import Explore from './Explore';
import Report from './Report';
import { MainMap } from "./components/MainMap";
import { layerConfig } from './config/layerConfig.js';
import { mapConfig } from './config/mapConfig.js';
import { nearbyConfig } from './config/nearbyConfig.js';
import Pluralize from 'pluralize';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
import Printout from './Printout';
// import Sources from './components/Sources';
// import { layer } from '@fortawesome/fontawesome-svg-core';
setDefaultOptions({ version: '4.22' })

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // Toggle between internal and public modes using an environment variable. This
      // uses a different web map and different functionality
      mode: 'internal',

      acceptedDisclaimer: false,
      addRemoveMode: null,
      addRemoveHandler: null,
      addRemoveChanged: false,
      agolPrivileges: [],
      areaMin: null,
      areaMax: null,
      autoRunSearch: false,
      classSubtype: 'any',
      statusType: '',
      error: null,
      esriId: null,
      exceededTransferLimit: false,
      extentChanged: false,
      featureDedup: [],
      features: [],
      fer: 'any',
      geocodeForReport: [],
      geocodeLatLong: null,
      geocodeOptions: [],
      isLoaded: false,
      isLoggedIn: false,
      map: null,
      mapClass: null,
      mapIsUpdating: false,
      modalContent: "",
      modalOpen: false,
      nearbyBuffers: [],
      nearbyDistance: 1,
      nearbyGeometryType: null,
      nearbyLayer: '',
      nearbyPointsLayer: null,
      nearbyPolygonsLayer: null,
      nearbyType: '',
      nearbyTypeAlias: '',
      nearbyTypes: [],
      originalMap: null,
      overlapCLCfeatures: [],
      overlapFeatureCount: 0,
      overlayLayer: null,
      paginationCurrentPage: 1,
      queryResponseCount: 0,
      reserve: 'any',
      searchCount: 0,
      searchMessages: [],
      searchPending: false,
      searchReady: true,
      selectedGeocode: null,
      sidebarMode: "search",
      showSourcesModal: false,
      token: null,
      useOverlayLayer: false,
      userId: null,
      view: null
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.runSearch = this.runSearch.bind(this);
    this.handleNearbyLayerChange = this.handleNearbyLayerChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleQueryResponse = this.handleQueryResponse.bind(this);
    this.handleQueryFail = this.handleQueryFail.bind(this);
    this.runQuery = this.runQuery.bind(this);
    this.runNearbyQuery = this.runNearbyQuery.bind(this);
    this.finaliseSearches = this.finaliseSearches.bind(this);
    this.setSidebarMode = this.setSidebarMode.bind(this);
    this.saveViewToState = this.saveViewToState.bind(this);
    this.saveMapToState = this.saveMapToState.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.handleMapExtentChange = this.handleMapExtentChange.bind(this);
    this.writeWhereClause = this.writeWhereClause.bind(this);
    this.displayResults = this.displayResults.bind(this);
    this.displayGeocodeResult=this.displayGeocodeResult.bind(this);
    this.handleGeocodeChange = this.handleGeocodeChange.bind(this);
    this.handleGeocodeOptionsUpdate = this.handleGeocodeOptionsUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleSourcesModal = this.toggleSourcesModal.bind(this);
    this.createQueue = this.createQueue.bind(this);
    this.clearClcLayers = this.clearClcLayers.bind(this);
    this.clearGeocodeResults = this.clearGeocodeResults.bind(this);
    this.updatePagination = this.updatePagination.bind(this)
    this.checkForSearchParam = this.checkForSearchParam.bind(this);
    this.clearFeatures = this.clearFeatures.bind(this);
    this.removeFromSearchResults = this.removeFromSearchResults.bind(this);
    this.removeAllSearchResults = this.removeAllSearchResults.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.displayWidgets = this.displayWidgets.bind(this);
    this.reRenderSidebarBtn = this.reRenderSidebarBtn.bind(this);
    // this.updateVisibleLayers = this.updateVisibleLayers.bind(this);
    // this.watchLayerVisibility = this.watchLayerVisibility.bind(this);
    this.resetSettings = this.resetSettings.bind(this);
    this.updateOverlayLayer = this.updateOverlayLayer.bind(this);
    this.clearOverlayLayer = this.clearOverlayLayer.bind(this);
    this.searchWithinOverLapLayer = this.searchWithinOverLapLayer.bind(this);
    this.createOverlapQueue = this.createOverlapQueue.bind(this);
    this.updateLoadedStatus = this.updateLoadedStatus.bind(this);
    this.checkAgolPrivileges = this.checkAgolPrivileges.bind(this);
    this.handleDisclaimer = this.handleDisclaimer.bind(this);
    this.zoomToGeoCode = this.zoomToGeoCode.bind(this);
    this.generateClusterConfig = this.generateClusterConfig.bind(this);
    this.toggleAddRemoveMode = this.toggleAddRemoveMode.bind(this);
  }

  componentDidMount(){
    //console.log("app component mounted")
    loadModules([
      "esri/identity/OAuthInfo", 
      "esri/identity/IdentityManager", 
      "esri/config"
    ], { css: true })
    .then(([
      OAuthInfo,
      esriId,
      esriConfig
    ]) => {
      //console.log("app mounted")

      // Decide whether this is the public or internal instance, based on the environment variable
      const { REACT_APP_ACTIVATE_MODE } = process.env;
      console.log("internal vs public:", REACT_APP_ACTIVATE_MODE)
      if (REACT_APP_ACTIVATE_MODE === 'public'){
        this.setState({mode: 'public'})
      } else {
        // Authenticate with ArcGIS Online's oAuth
        esriConfig.portalUrl = mapConfig().portalUrl;
        var info = new OAuthInfo({
          appId: mapConfig().internal.appId,
          popup: false
        });
        esriId.useSignInPage = false;
        esriId.registerOAuthInfos([info]);
 
        esriId.checkSignInStatus(mapConfig().portalUrl).then(status => {
          if (status.token) {
            this.setState({isLoggedIn: true, token: status.token, userId: status.userId}, () => {
              this.checkAgolPrivileges();
            });
          }
        }).catch(error => {
          console.info("User is not logged in")
        })
 
        let isLoggedIn = false;
        if (esriId._oAuthHash && esriId._oAuthHash.access_token){
          isLoggedIn = true;
        }
        this.setState({esriId, isLoggedIn}, () => {
          if (this.state.isLoggedIn) {
            console.log("user is logged in")
          }
        })
      }
 
    })
   }

   login() {
    loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"], { css: true })
    .then(([QueryTask, Query]) => {
      try{

        //console.log("log user in")
        
        // Run a simple query to force the login prompt on the landing page. We don't care about the result
        let url;
        this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
        let queryTask = new QueryTask(url)
        let query = new Query();
        query.objectIds = [1];
        query.outFields = "*";
        queryTask.execute(query);
        
      } catch(error) {
        console.error("There was a problem logging in to the application", error);
        toast.error("There was a problem logging in to the application");
      }
    })
  }

  async logout(){
    //console.log("logging out")
    let esriId = this.state.esriId;
    esriId.destroyCredentials();
    await this.setState({isLoggedIn: false, esriId: esriId});
    window.location.href = `/`;
  }

  async handleSearchSubmit(evt){
    try{
      if (this.state.nearbyLayer !== '' && this.state.nearbyType === '') {
        evt.preventDefault();
        this.raiseError("Please select a Type in the Proximity section");
        return;
      }

      if (!this.state.view.extent) {
        //console.log("error","View is not ready")
        return;
      }

      // called from the search Submit button, or the Redo Search button
      this.setState({
        searchMessages: [],
        paginationCurrentPage: 1
      });
      if (evt){
        evt.preventDefault();
        await this.setState(prevState => {
           return {searchCount: prevState.searchCount + 1}
        })
      }

      this.runSearch();

    } catch(err) {
      console.error("error","There was a problem running the search", err.message)
    }


  }

  zoomToGeoCode(){
    if (!this.state.selectedGeocode) { return; }
    //console.log("------------------Zoom to geocode:", this.state.selectedGeocode);

    let view = this.state.view;
    if(this.state.selectedGeocode.extent){
      view.extent = this.state.selectedGeocode.extent;

    }
    else if(this.state.selectedGeocode.longitude  && this.state.selectedGeocode.latitude ){
      view.center=[this.state.selectedGeocode.longitude,this.state.selectedGeocode.latitude];
      view.zoom = 16;
    }
  }

  addToSearchResults(feature){
    try{
      console.log("add to search results", feature)
      let features = this.state.features;
      let idField;
      this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
      let foundFeature = features.find(o => o.attributes[idField] === feature.attributes[idField])
      if (foundFeature === undefined){
        features.push(feature)
        this.setState({features: features}, () => {
          this.displayResults();
        })
      }
    } catch(err){
      console.error("There was a problem adding this feature to the selection")
    }
  }

  removeFromSearchResults(feature){
    try{
      // Remove the provided feature from the current list of selected features
      let idField;
      this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;      
      let features = this.state.features.filter(o => o.attributes[idField] !== feature.attributes[idField])
      this.setState({features: features}, () => {
        this.displayResults();
        // Remove the highlights
        let highlightPolygonGraphicsLayer = this.state.view.map.layers.items.find(o => o.id==='highlightPolygonGraphicsLayer');
        if (highlightPolygonGraphicsLayer) {highlightPolygonGraphicsLayer.removeAll();}
        let highlightPointGraphicsLayer = this.state.view.map.layers.items.find(o => o.id==='highlightPointGraphicsLayer');
        if (highlightPointGraphicsLayer) {highlightPointGraphicsLayer.removeAll();}
      })
    } catch(err){
      console.error("There was a problem removing this feature from the selection")
    }
  }

  removeAllSearchResults() {
    this.setState({features: [], addRemoveChanged: true}, () => {
      this.displayResults();
      // Remove the highlights
      let highlightPolygonGraphicsLayer = this.state.view.map.layers.items.find(o => o.id==='highlightPolygonGraphicsLayer');
      if (highlightPolygonGraphicsLayer) {highlightPolygonGraphicsLayer.removeAll();}
      let highlightPointGraphicsLayer = this.state.view.map.layers.items.find(o => o.id==='highlightPointGraphicsLayer');
      if (highlightPointGraphicsLayer) {highlightPointGraphicsLayer.removeAll();}
    })
  }

  async clearFeatures(clearGeocodeResults, resetUI) {
    // console.log("clear features")
    this.setState({
      features: [],
      nearbyBuffers: [],
      queryResponseCount: 0,
      featureDedup: [],
      overlapCLCfeatures: [],
      overlapFeatureCount: 0
    });

    try{

      // Remove any existing centroid/parcel/nearby features
      let view = this.state.view;
      this.clearClcLayers();

      if (this.state.nearbyPointsLayer) {
        //console.log("destroying the nearby points layer")
        this.state.nearbyPointsLayer.destroy();
      }

      if (this.state.nearbyPolygonsLayer) {
        //console.log("destroying the nearby polygons layer")
        this.state.nearbyPolygonsLayer.destroy();
      }

      this.setState({view});

      if(clearGeocodeResults){
        this.clearGeocodeResults();
      }

      if (resetUI) {
        // await this.setState({
        //   classSubtype: 'any',
        //   statusType: '',
        //   nearbyLayer: '',
        //   nearbyType: '',
        //   nearbyTypeAlias: null,
        //   nearbyDistance: 1
        // });
        this.resetSettings()
      }

      await this.setSidebarMode("search");

    } catch(err) {
      console.error("error","There was a problem clearing the map graphics", err.message)
    }

  }


  runSearch() {
    //console.log("run search")
    this.setState({paginationCurrentPage: 1, searchPending: true});
    try{
      this.clearFeatures();

      // remove the widgets while the search is in progress
      let view = this.state.view;
      view.ui.remove(this.state.view.zoomControl);
      view.ui.remove(this.state.view.printControl);
      view.ui.remove(this.state.view.layerControl);
      view.ui.remove(this.state.view.homeControl);
      view.ui.remove(this.state.view.locateControl);
      view.ui.remove(this.state.view.basemapControl);
      view.ui.remove(this.state.view.legendControl);
      view.ui.remove(this.state.view.scaleBar);
      // view.ui.remove("screenshotMapButton")
      // view.ui.remove("overlayToolButton")
      // view.ui.remove("MeasureToolDiv")

      this.setState({view})

      try{

        // Remove any existing centroid/parcel/nearby features
        this.clearClcLayers()
        if (this.state.nearbyPointsLayer) {
          //console.log("destroying the nearby points layer")
          this.state.nearbyPointsLayer.destroy();
        }

        if (this.state.nearbyPolygonsLayer) {
          //console.log("destroying the nearby polygons layer")
          this.state.nearbyPolygonsLayer.destroy();
        }
        this.setState({view});

      } catch(err) {
        console.error("There was a problem clearing the map graphics", err.message);
        toast.error("There was a problem clearing the map graphics")
      }

      //TODO add geocode point/polygon/polyline layer featurees apply edit herte.
      if (this.state.nearbyType !== '' && this.state.nearbyDistance !== undefined && this.state.nearbyDistance > 0){
        this.runNearbyQuery();
      } else {
        this.setState({nearbyBuffers: [this.state.view.extent.extent]});
        this.runQuery(this.state.view.extent.extent);
      }
    } catch(err) {
      console.error("error","There was a problem running the search", err.message)
      toast.error("There was a problem running the search. Please try again.")
    }
  }

clearGeocodeResults(){
  try{
    //console.log("clear geocode results")
    let view = this.state.view;
    let geocodeLayer = view.map.layers.items.find(o => o.id === 'geocodeLayer');
    if (geocodeLayer){view.map.remove(geocodeLayer);}
    this.setState({view});
  } catch(err){
    console.error("unable to clear geocode results")
  }

}
 
  clearClcLayers() {
    // Remove the polygon and centroid CLC results layers
    try{
      let map = this.state.view.map;
      let polygonLayer = map.layers.items.find(o => o.id === 'polygonLayer');
      if (polygonLayer) {
        // polygonLayer.legendEnabled = false;
        polygonLayer.destroy();
      }
      let polygonGraphicsLayer = map.layers.items.find(o => o.id === 'polygonGraphicsLayer');
      if (polygonGraphicsLayer) {
        polygonGraphicsLayer.removeAll();
      }    
      let centroidLayer = map.layers.items.find(o => o.id === 'centroidLayer');
      if (centroidLayer) {
        // centroidLayer.legendEnabled = false;
        centroidLayer.destroy();
      }
      this.setState({map: map})
    } catch(err){
      console.error("There was a problem removing the polygon and centroid layers")
    }
  }

  async runNearbyQuery() {
    //console.log("run nearby query")
    this.setState({ searchMessages: [...this.state.searchMessages, 'Searching for ' + Pluralize.plural(this.state.nearbyTypeAlias)] });
    await this.setState({searchPending: true});
    const [FeatureLayer] = await loadModules(["esri/layers/FeatureLayer"]);

    // this.setState({nearbyGeometryType: nearbyConfig()[this.state.nearbyLayer].geometryType});
    try{
      let view = this.state.view;
      if (this.state.nearbyGeometryType === 'point'){
        let nearbyPointsLayer = new FeatureLayer({
          title: Pluralize.plural(this.state.nearbyTypeAlias),
          listMode: "show",
          legendEnabled: true,
          objectIdField: "ObjectID",
          fields: [
            {
              name: "ObjectID",
              alias: "ObjectID",
              type: "oid"
           }
          ],
          spatialReference: { wkid: 4326 },
          source: [],
          geometryType: "point",
          renderer: mapConfig().nearbyPoints,
          customLayer: true
        });
        await this.setState({nearbyPointsLayer})
        view.map.add(this.state.nearbyPointsLayer);
      } else if (this.state.nearbyGeometryType === 'polygon') {
        let nearbyPolygonsLayer = new FeatureLayer({
          title: Pluralize.plural(this.state.nearbyTypeAlias),
          listMode: "show",
          legendEnabled: true,
          objectIdField: "ObjectID",
          fields: [
            {
              name: "ObjectID",
              alias: "ObjectID",
              type: "oid"
           }
          ],
          spatialReference: { wkid: 4326 },
          source: [],
          geometryType: "polygon",
          renderer: mapConfig().nearbyPolygons,
          customLayer: true
        });
        await this.setState({nearbyPolygonsLayer})
        view.map.add(this.state.nearbyPolygonsLayer);
      }

      await this.setState({view});
    } catch(err) {
      console.error("nearby features layer not ready")
    }

    // Query the POI or National Park layer
    loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]).then(
      ([QueryTask, Query]) => {

        let where = nearbyConfig()[this.state.nearbyLayer].typeField + "='" + this.state.nearbyType + "'";

        let queryTask = new QueryTask(nearbyConfig()[this.state.nearbyLayer].url);
        let query = new Query();
        query.where = where;
        query.returnGeometry = true;
        query.geometry = this.state.view.extent.extent;
        query.maxAllowableOffset = this.setMaxOffset();

        queryTask.execute(query).then(this.handleNearbyQueryResponse).catch(this.handleQueryFail);
      }
    );
  }

  handleQueryFail(err) {
    //console.log("error","There was a problem running the query", err)
    this.raiseError("There was a problem running the query. Please try again.")
    this.finaliseSearches();
  }

  handleNearbyQueryResponse = (response) => {
    // filter the Crown features against the nearby features and distance
    //console.log("handle spatial query response")
    this.setState({exceededTransferLimit: response.exceededTransferLimit});
    if (response.exceededTransferLimit) {
      this.raiseError("Too many " + Pluralize.plural(this.state.nearbyTypeAlias) + " were found. Please try refining the search criteria.")
      this.finaliseSearches();
      return;
    } else if (response.features.length === 0) {
      console.error("error","no features found within the map extent")
      this.raiseError("No " + Pluralize.plural(this.state.nearbyTypeAlias) + " were found within the map extent. Please refine your search further.")
      this.finaliseSearches();
      return;
    } else if (response.features.length > 200) {
      this.raiseError("More than 200 " + Pluralize.plural(this.state.nearbyTypeAlias) + " were found within the map extent. Please refine your search further.")
      this.finaliseSearches();
      return;
    }

    let searchMessage = "Found " + response.features.length + " " + Pluralize(this.state.nearbyTypeAlias, response.features.length);
    this.setState({ searchMessages: [...this.state.searchMessages, searchMessage] });
    this.setState({ searchMessages: [...this.state.searchMessages, "Searching within " + this.state.nearbyDistance + "km of " + Pluralize("this", response.features.length) + Pluralize(" feature", response.features.length)] });

    // Build up an array of queries, which will be queued and rate-limited
    let queryQueue = [];

    loadModules(["esri/geometry/geometryEngine", "esri/geometry/Polygon", "esri/Graphic", "esri/tasks/support/Query"]).then(([geometryEngine, Polygon, Graphic, Query]) => {
      // Buffer each of the input features. Clip the buffer with the extent of the view
      let addEdits = {
        addFeatures: []
      };

      response.features.forEach(spatialFeature => {
        let nearbyGraphic = new Graphic({
          geometry: spatialFeature.geometry,
          attributes: spatialFeature.attributes,
          popupTemplate: {
            title: "{" + Object.keys(spatialFeature.attributes)[0] + "}"
          }
        })

        // Add these features to the nearby features layer
        addEdits.addFeatures.push(nearbyGraphic)

        let buffer = geometryEngine.buffer(spatialFeature.geometry, this.state.nearbyDistance, "kilometers");
        buffer = geometryEngine.clip(buffer, this.state.view.extent);
        this.setState({ nearbyBuffers: [...this.state.nearbyBuffers, buffer] });
      });

      // let geometryType = nearbyConfig()[this.state.nearbyLayer].geometryType;
      try{
        if (this.state.nearbyGeometryType === 'point') {
          this.state.nearbyPointsLayer.applyEdits(addEdits);
        } else if (this.state.nearbyGeometryType === 'polygon'){
          this.state.nearbyPolygonsLayer.applyEdits(addEdits);
        }
      } catch(err){
        console.error("Can't apply edits to nearby features layer")
      }

      this.state.nearbyBuffers.forEach(feature => {
        let polygon = new Polygon({
          hasZ: true,
          hasM: true,
          rings: feature.rings,
          spatialReference: feature.spatialReference
        });


        let where = this.writeWhereClause();
        if (where !== undefined) {
          let query = new Query();
          query.where = where;
          query.returnGeometry = true;
          let outFields = ["*"]
          this.state.mode === 'public' ? outFields = layerConfig().public.clcLayer.outFields : outFields = layerConfig().internal.clcLayer.outFields;
          query.outFields = outFields;
          query.geometry = polygon;
          query.maxAllowableOffset = this.setMaxOffset();
          query.geomeryType = "esriGeometryPolygon";
          //query.maxRecordCountFactor = 5;

          queryQueue.push(query)
          } else {
            console.error("error","No search terms found")
          }

      });

      //console.log("query queue", queryQueue)
      this.createQueue(queryQueue);

    })
  }

  setMaxOffset() {
    // Calculate the appropriate query offset based on the current zoom
    let maxAllowableOffset;
    if (this.state.view.zoom < 5) {
        maxAllowableOffset = 100;
    } else if (this.state.view.zoom < 10){
        maxAllowableOffset = 50;
    } else if (this.state.view.zoom < 15){
        maxAllowableOffset = 10
    } else if (this.state.view.zoom < 17){
        maxAllowableOffset = 5
    } else {
        maxAllowableOffset = 0;
    }
    return (maxAllowableOffset);
  }

  createQueue(tasks, maxNumOfWorkers = 10) {
    // This function queues the nearby features queries into groups of 10, to avoid overloading the server
    // https://krasimirtsonev.com/blog/article/implementing-an-async-queue-in-23-lines-of-code
    var numOfWorkers = 0;
    var taskIndex = 0;

    loadModules(["esri/tasks/QueryTask"]).then(([QueryTask]) => {
      let url;
      this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
      let queryTask = new QueryTask(url)
      return new Promise(done => {
        const handleResult = index => response => {

          this.setState({exceededTransferLimit: response.exceededTransferLimit});
          if (response.exceededTransferLimit) {
            this.raiseError("The number of features returned exceeds 50,000. Please refine your search further.")
            this.writeWhereClause();
            this.finaliseSearches();
            done(tasks);
          } else {

            // increment the query response count
            this.setState({ queryResponseCount: this.state.queryResponseCount + 1 })

            // Check for duplicates
            if (!response.features) {
              this.raiseError("There was an error with the search. Please try again");
              this.writeWhereClause();
              this.finaliseSearches();
              return;
            }

            response.features.forEach(feature => {
              let idField;
              this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
              let id = feature.attributes[idField];
              if (!this.state.featureDedup.includes(id)){
                this.setState({ features: [...this.state.features, feature] });
                this.setState({ featureDedup: [...this.state.featureDedup, id] });
              }
            });

            // Stop if this is the final query response we're awaiting
            if (this.state.queryResponseCount === this.state.nearbyBuffers.length) {

              // If the overlay layer is being used, query within the existing results
              if (this.state.useOverlayLayer){
                let query = this.state.overlayLayer.createQuery();
                query.where = "1=1";
                query.outFields = ["*"];
                this.state.overlayLayer.queryFeatures(query)
                  .then(this.searchWithinOverLapLayer)
                  .catch(error => {
                    console.error("There was a problem searching within the overlay layer", error)
                    toast.error("There was a problem searching within the overlay layer")
                  });
              } else {
                this.finaliseSearches()
              }
            } else {
              //console.log("awaiting further query responses")
            }
          }

          // Move onto the next task
          tasks[index] = response;
          numOfWorkers--;
          getNextTask();
        };
        const getNextTask = () => {
          if (numOfWorkers < maxNumOfWorkers && taskIndex < tasks.length) {
            let query = tasks[taskIndex];
            queryTask.execute(query).then(handleResult(taskIndex)).catch(handleResult(taskIndex));

            taskIndex++;
            numOfWorkers++;
            getNextTask();
          } else if (numOfWorkers === 0 && taskIndex === tasks.length) {
            done(tasks);
          }
        };
        getNextTask();
      });
    });
  }

  async runQuery(withinGeometry) {
    // Query the Crown Estate layer using an attribute query, within the
    // withinGeometry object (this may be the view extent, or a buffer)
    await this.setState({searchPending: true, addRemoveChanged: false});
    loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]).then(
      ([QueryTask, Query]) => {
        // Build up the query from the chosen options
        let where = this.writeWhereClause();

        if (where !== undefined) {
          let url;
          this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
          let queryTask = new QueryTask(url)
          let query = new Query();
          query.where = where;
          query.returnGeometry = true;
          let outFields = ["*"];
          this.state.mode === 'public' ? outFields = layerConfig().public.clcLayer.outFields : outFields = layerConfig().internal.clcLayer.outFields;
          query.outFields = outFields;
          query.geometry = withinGeometry;
          query.maxAllowableOffset = this.setMaxOffset();
          //query.maxRecordCountFactor = 5;
          query.geomeryType = "esriGeometryPolygon";

          queryTask.execute(query).then(this.handleQueryResponse).catch(this.handleQueryFail);
        } else {
          console.error("error","No search terms found")
        }
      }
    );
  }

  writeWhereClause(){
    // Create the search's Where clause, or disable the search if none found
    let where;

    if (this.state.classSubtype === 'any') {
      where = "1=1";
    } else {
      where = "classsubtype_desc = '" + this.state.classSubtype + "'";
    }

    if (this.state.statusType !== '' && this.state.statusType !== 'Any'){
      where += " AND crownlandstatustype_desc ='" + this.state.statusType + "'";
    }

    // Reserve
    if (this.state.reserve !== 'any') {
      let reserveField;
      this.state.mode === 'public' ? reserveField = layerConfig().public.clcLayer.reserveField : reserveField = layerConfig().internal.clcLayer.reserveField;
      if (reserveField) {
        if (where === "1=1") {
          where = reserveField + "='" + this.state.reserve + "'";
        } else {
          where += " AND " + reserveField + "='" + this.state.reserve + "'";
        }
      }
    }

    // Functional economic region
    if (this.state.fer !== 'any') {
      if (where === "1=1") {
        where = "(" + this.state.fer + "='Emerging' or " + this.state.fer + "='Current')";
      } else {
        where += " AND (" + this.state.fer + "='Emerging' or " + this.state.fer + "='Current')";
      }
    }

    // Min/Max area
    let areaField;
    this.state.mode === 'public' ? areaField = layerConfig().public.clcLayer.areaField : areaField = layerConfig().internal.clcLayer.areaField;
    if (areaField){
      if (parseFloat(this.state.areaMin) > 0) {
        if (where === "1=1") {
          where = areaField + ">=" + parseFloat(this.state.areaMin);
        } else {
          where += " AND " + areaField + ">=" + parseFloat(this.state.areaMin);
        }
      }
      if (parseFloat(this.state.areaMax) > 0) {
        if (where === "1=1") {
          where = areaField + "<=" + parseFloat(this.state.areaMax);
        } else {
          where += " AND " + areaField + "<=" + parseFloat(this.state.areaMax);
        }
      }
    }
    // console.log("where clause:", where)
    return where;
  }

  handleQueryResponse = (response) => {
    this.setState({exceededTransferLimit: response.exceededTransferLimit});
    if (response.exceededTransferLimit) {
      this.raiseError("The number of features returned exceeds 50,000. Please refine your search further.")
      this.writeWhereClause();
      this.finaliseSearches();
    } else {

      // Check for duplicates
      response.features.forEach(feature => {
        let idField;
        this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
        let id = feature.attributes[idField];
        if (!this.state.featureDedup.includes(id)){
          this.setState({ features: [...this.state.features, feature] });
          this.setState({ featureDedup: [...this.state.featureDedup, id] });
        }
      });

      // Re-select features within the overlay layer if required
      if (this.state.useOverlayLayer) {
        let query = this.state.overlayLayer.createQuery();
        query.where = "1=1";
        query.outFields = ["*"];

        this.state.overlayLayer.queryFeatures(query)
          .then(this.searchWithinOverLapLayer)
          .catch(error => {
            console.error("There was a problem searching within the overlay layer", error)
            toast.error("There was a problem searching within the overlay layer")
          })
      } else {
        this.finaliseSearches();
      }

    }
  }

  async searchWithinOverLapLayer(overlapResponse){
    //console.log("search within overlap layer")
    
    this.setState({
      searchMessages: [...this.state.searchMessages, "Searching within overlap features"],
      overlapFeatureCount: overlapResponse.features.length,
      nearbyBuffers: []
    });

    // Build up an array of queries, which will be queued and rate-limited
    let queryQueue = [];

    loadModules(["esri/geometry/Polygon", "esri/geometry/Point", "esri/geometry/Polyline", "esri/tasks/support/Query"]).then(([Polygon, Point, Polyline, Query]) => {
      
      overlapResponse.features.forEach(feature => {
        
        let where = this.writeWhereClause();
        if (where !== undefined) {
          let query = new Query();
          query.where = where;
          query.returnGeometry = true;
          let outFields = ["*"];
          this.state.mode === 'public' ? outFields = layerConfig().public.clcLayer.outFields : outFields = layerConfig().internal.clcLayer.outFields;
          query.outFields = outFields;
          query.geometry = feature.geometry;
          query.maxAllowableOffset = this.setMaxOffset();
          //query.maxRecordCountFactor = 5;

          queryQueue.push(query)
          } else {
            console.error("error","No search terms found")
          }

      });

      //console.log("query queue", queryQueue)

      this.setState({ queryResponseCount: 0}, () => {
        this.createOverlapQueue(queryQueue);
      });

    })
  }

  createOverlapQueue(tasks, maxNumOfWorkers = 10) {
    // This is a duplicate of the Nearby search query queue but I don't know how else to handle this

    // This function queues the nearby features queries into groups of 10, to avoid overloading the server
    // https://krasimirtsonev.com/blog/article/implementing-an-async-queue-in-23-lines-of-code
    var numOfWorkers = 0;
    var taskIndex = 0;

    loadModules(["esri/tasks/QueryTask"]).then(([QueryTask]) => {
      
      let url;
      this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
      let queryTask = new QueryTask(url)
      return new Promise(done => {
        const handleOverlapResult = index => response => {

          this.setState({exceededTransferLimit: response.exceededTransferLimit});
          if (response.exceededTransferLimit) {
            this.raiseError("The number of features returned exceeds 50,000. Please refine your search further.")
            this.writeWhereClause();
            this.finaliseSearches();
            done(tasks);
          } else {

            // increment the query response count
            this.setState({ queryResponseCount: this.state.queryResponseCount + 1 })

            // Check for features which match the overlap response - these are the ones to keep
            if (!response.features) {
              this.raiseError("There was an error with the search. Please try again");
              this.writeWhereClause();
              this.finaliseSearches();
              return;
            }

            response.features.forEach(feature => {
              let idField;
              this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
              let id = feature.attributes[idField];
              this.setState({ overlapCLCfeatures: [...this.state.overlapCLCfeatures, id] });              
            });

            // Check whether this is the final query response we're awaiting
            if (this.state.queryResponseCount === this.state.overlapFeatureCount) {
              
              // Remove all but the matching features
              let matchingFeatures = this.state.features.filter(o => this.state.overlapCLCfeatures.includes(o.attributes['cadid']));
              this.setState({features: matchingFeatures}, () => {
                this.finaliseSearches();
              })
            } else {
              console.log("awaiting further query responses")
            }
          }

          // Move onto the next task
          tasks[index] = response;
          numOfWorkers--;
          getNextTask();
        };
        const getNextTask = () => {
          if (numOfWorkers < maxNumOfWorkers && taskIndex < tasks.length) {
            let query = tasks[taskIndex];
            queryTask.execute(query).then(handleOverlapResult(taskIndex)).catch(handleOverlapResult(taskIndex));

            taskIndex++;
            numOfWorkers++;
            getNextTask();
          } else if (numOfWorkers === 0 && taskIndex === tasks.length) {
            done(tasks);
          }
        };
        getNextTask();
      });
    });
  }

  displayWidgets() {
    //console.log("display widgets")
    try{
      let view = this.state.view;
      view.ui.add(view.zoomControl, {position: "top-right",index: 0});
      view.ui.add(view.homeControl, {position: "top-right", index: 1});
      view.ui.add(view.locateControl, {position: "top-right", index: 2});
      view.ui.add(view.layerControl, {position: "top-right", index: 5});
      view.ui.add(view.basemapControl, {position: "bottom-right", index: 0});
      view.ui.add(view.printControl, {position: "bottom-right", index: 1});
      view.ui.add(view.legendControl, {position: "bottom-left", index: 0});
      view.ui.add(view.sidebarBtn, {position: "top-left",index: 1});
      if (view.ui.find("screenshotMapButton") !== null){
        view.ui.move("screenshotMapButton", {position: "bottom-right", index: 3});
      } else {
        view.ui.add("screenshotMapButton", {position: "bottom-right", index: 3});
      }
      view.ui.add(view.scaleBar, {position: "bottom-right", index: 4});
      view.ui.add(view.measurement, {position: "bottom-right"});
      if (view.ui.find("MeasureToolDiv") !== null){
        view.ui.move("MeasureToolDiv", {position: "top-right", index: 3});
      } else {
        view.ui.add("MeasureToolDiv", {position: "top-right", index: 3});
      }
      if (this.state.agolPrivileges.find(o => o === "premium:user:spatialanalysis")){
        if (view.ui.find("overlayToolButton") !== null){
          view.ui.move("overlayToolButton", {position: "top-right", index: 4});
        } else {
          view.ui.add("overlayToolButton", {position: "top-right", index: 4});
        }
      } else {
        view.ui.remove("overlayToolButton");
      }
      
      this.setState({view});

    } catch(err){
      console.error("error adding widgets")
    }
  }

  reRenderSidebarBtn() {

    // not working
    // try{
    //  this.state.view.ui.remove(this.state.view.sidebarBtn);
    //   let btn= document.getElementById("collapseBtnContainer");
    //    if (this.state.mapClass === 'active') {
    //     this.state.view.sidebarBtn= document.getElementById("collapseBtnContainer");
    //     this.state.view.ui.add(btn, {position: "top-left",index: 1});

    //    } else {
    //     this.state.view.sidebarBtn= document.getElementById("showBtnContainer");
    //     this.state.view.ui.add(this.state.view.sidebarBtn, {position: "top-left",index: 1});
    //   }


    // } catch(err){
    //   console.log("error adding widgets")
    // }
  }

  async finaliseSearches() {
    // this is called when all searches have completed
    await this.setState({ searchPending: false });
    await this.setSidebarMode("results");
    if (!this.state.exceededTransferLimit) {
      await this.displayResults();
    } else {
      this.setState({modalOpen: true})
    }
    await this.setState({extentChanged: false});
    this.writeWhereClause();
    // restore the hidden widgets
    this.displayWidgets();
  }

  displayResults() {
    loadModules(["esri/Graphic", "esri/layers/FeatureLayer", "esri/layers/GraphicsLayer"]).then(([Graphic, FeatureLayer, GraphicsLayer]) => {

    // Display the geocode result immediately after running the geocode
    // this.displayGeocodeResult();

    // Add the final search results to the map
    this.clearClcLayers();
    
    let centroidGraphics = [];
    let polygonGraphics = [];
    this.state.features.forEach(feature => {
      let polygonGraphic = new Graphic({
        geometry: {
          rings: feature.geometry.rings,
          spatialReference: feature.geometry.spatialReference,
          type: "polygon"
        },
        attributes: feature.attributes,
        symbol: mapConfig().clcPolygons.symbol
      });

      let centroidGraphic = new Graphic({
        geometry: {
          latitude: feature.geometry.centroid.latitude,
          longitude: feature.geometry.centroid.longitude,
          spatialReference: feature.geometry.centroid.spatialReference,
          type: "point"
        },
        attributes: feature.attributes,
        symbol:{
          type: "simple-marker",
          style: mapConfig().clcPoints.shape,
          color: mapConfig().clcPoints.color,
          size: mapConfig().clcPoints.size
        }
      })

      centroidGraphics.push(centroidGraphic);
      polygonGraphics.push(polygonGraphic);
    });

    // Create graphics layers to hold the search results
    let polygonGraphicsLayer = this.state.view.map.layers.items.find(o => o.id === 'polygonGraphicsLayer');
    if (!polygonGraphicsLayer){
      polygonGraphicsLayer = new GraphicsLayer({
        id: "polygonGraphicsLayer",
        title: "Selected Crown Estate Areas",
        minScale: mapConfig().pointScale,
        listMode: 'show',
        legendEnabled: true,
        graphics: polygonGraphics,
        customLayer: true
      });
      this.state.view.map.add(polygonGraphicsLayer);
    } else {
      polygonGraphicsLayer.graphics = polygonGraphics
    }

    let polygonLayer = this.state.view.map.layers.items.find(o => o.id === 'polygonLayer');
    if (!polygonLayer){
      polygonLayer = new FeatureLayer({
        id: "polygonLayer",
        title: "Selected Crown Estate Areas",
        minScale: mapConfig().pointScale,
        listMode: 'hide',
        legendEnabled: true,
        objectIdField: "ObjectID",
        geometryType: "polygon",
        fields: [
          {
            name: "ObjectID",
            alias: "ObjectID",
            type: "oid"
        }, {
            name: "cadid",
            alias: "cadid",
            type: "string"
        }, {
            name: "classSubtype",
            alias: "Crown Land status type",
            type: "string"
        },
        {
            name: "statusType",
            alias: "Crown Account Type",
            type: "string"
        }
        ],
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: mapConfig().clcPolygons,
        customLayer: true
      });
      this.state.view.map.add(polygonLayer);
    } else {
      polygonLayer.legendEnabled = true;
    }

    // the centroid layer is a clustered feature layer
    let centroidLayer = new FeatureLayer({
      id: "centroidLayer",
      title: "Selected Crown Estate Areas",
      maxScale: mapConfig().pointScale,
      listMode: 'hide',
      legendEnabled: false,
      objectIdField: "ObjectID",
      geometryType: "point",
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid"
       }, {
          name: "cadid",
          alias: "cadid",
          type: "string"
       }, {
          name: "classSubtype",
          alias: "Crown Land status type",
          type: "string"
       },
       {
          name: "statusType",
          alias: "Crown Account Type",
          type: "string"
       }
      ],
      spatialReference: { wkid: 4326 },
      source: centroidGraphics,
      renderer: {
        type: "simple",
        symbol: {
          type: "simple-marker",
          style: mapConfig().clcPoints.shape,
          color: mapConfig().clcPoints.color,
          size: mapConfig().clcPoints.size,
          outline: mapConfig().clcPoints.outline
        }
      },
      customLayer: true
    });

    // Generate clusters: https://developers.arcgis.com/javascript/latest/sample-code/sandbox/index.html?sample=featurereduction-cluster-filter
    centroidLayer.when()
      .then(this.generateClusterConfig)
      .then(this.state.view.map.add(centroidLayer));

    });
  }

  generateClusterConfig(layer) {
    console.log('generate cluster config')
    // https://developers.arcgis.com/javascript/latest/sample-code/sandbox/index.html?sample=featurereduction-cluster-filter

    loadModules(["esri/smartMapping/labels/clusters", "esri/core/promiseUtils"]).then(([clusterLabelCreator, promiseUtils]) => {
      // generates default labelingInfo
      const labelPromise = clusterLabelCreator
        .getLabelSchemes({
          layer: layer,
          view: this.state.view
        })
        .then(function (labelSchemes) {
          return labelSchemes.primaryScheme;
        });

      return promiseUtils
        .eachAlways([labelPromise])
        .then(function (result) {
          // const popupTemplate = result[0].value;

          const primaryLabelScheme = result[0].value;
          const labelingInfo = [
            {
              deconflictionStrategy: "static",
              labelExpressionInfo: {
                expression: "Text($feature.cluster_count, '#,###')"
              },
              symbol: {
                type: "text",
                color: "#fff",
                font: {
                  weight: "bold",
                  family: "Noto Sans",
                  size: "12px"
                }
              },
              labelPlacement: "center-center"
            }
          ]
          // Ensures the clusters are large enough to fit labels
          const clusterMinSize = primaryLabelScheme.clusterMinSize;

          layer.featureReduction = {
            type: "cluster",
            labelingInfo: labelingInfo,
            clusterMinSize: clusterMinSize
          };
          return;
        })
        .catch(function (error) {
          console.log("error",error);
        });
    })
  }


   displayGeocodeResult() {
    loadModules(["esri/Graphic", "esri/layers/FeatureLayer"]).then(([Graphic, FeatureLayer]) => {

      this.clearGeocodeResults();
      if(this.state.selectedGeocode){

        let view = this.state.view;
        let addEdits = {addFeatures: []};
        let geocodeLayer;
        
        if(this.state.selectedGeocode.type === "point"){

          let centroidGraphic = new Graphic({
            geometry: {
              latitude: this.state.selectedGeocode.latitude,
              longitude: this.state.selectedGeocode.longitude,
              spatialReference: {wkid:4326},
              type: "point"
            },
             attributes:[]
          });

          addEdits = {
            addFeatures: [centroidGraphic]
          };

          geocodeLayer = new FeatureLayer({
            id: "geocodeLayer",
            title: "Geocode point feature",
            listMode: "hide",
            legendEnabled: false,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "point",
            renderer: mapConfig().geocodePoints,
            customLayer: true
          });

        } else if(this.state.selectedGeocode.type === "polygon"){

          let polygonGraphic = new Graphic({
            geometry: {
              rings: this.state.selectedGeocode.geometry.rings,
              spatialReference: this.state.selectedGeocode.geometry.spatialReference,
              type: "polygon"
            },
            attributes: []
          });

          addEdits = {
            addFeatures: [polygonGraphic]
          };

          geocodeLayer = new FeatureLayer({
            id: "geocodeLayer",
            title: "Geocode polygon feature",
            listMode: "hide",
            legendEnabled: false,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "polygon",
            renderer: mapConfig().geocodePolygons,
            customLayer: true
          });


        } else if (this.state.selectedGeocode.type === "polyline"){

          var polylineGraphic = new Graphic({
            geometry: {
              paths: this.state.selectedGeocode.geometry.paths,
              spatialReference: this.state.selectedGeocode.geometry.spatialReference,
              type: "polyline"
            },
            attributes: []
          });

          addEdits = {
            addFeatures: [polylineGraphic]
          };

          geocodeLayer = new FeatureLayer({
            id: "geocodeLayer",
            title: "Geocode polyline feature",
            listMode: "hide",
            legendEnabled: false,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "polyline",
            renderer: mapConfig().geocodePolylines,
            customLayer: true
          });

        }

        if (geocodeLayer){
          geocodeLayer.applyEdits(addEdits);
          geocodeLayer.title=this.state.selectedGeocode.name;
          geocodeLayer.listMode = 'show';
          geocodeLayer.legendEnabled = true;
          view.map.add(geocodeLayer);
        }

        // Persist the geocode for use in the report, then delete it so that it's not used when
        // panning/zooming
        // this.setState({geocodeForReport: this.state.selectedGeocode}, () => {
        //   this.setState({selectedGeocode:null, view: view});
        // });

        this.setState({geocodeForReport: this.state.selectedGeocode});
      }

    });
  }


  setSidebarMode(mode){
    this.setState({sidebarMode: mode})
  }

  async handleNearbyLayerChange(evt){
    //console.log("handle nearby layer change")
    this.setState({ [evt.target.name]: evt.target.value });
    let nearbyLayer = nearbyConfig()[evt.target.value] || {values: [{value: '', alias: '...'}]};
    await this.setState({
      nearbyTypes: nearbyLayer.values,
      nearbyType: '',
      nearbyTypeAlias: '',
      nearbyGeometryType: nearbyLayer.geometryType
    });
  }

  async updateOverlayLayer(layerDefinition){
    // create or update the Spatial Analysis output layer
    let map = this.state.map;
    if (this.state.overlayLayer){
      map.remove(this.state.overlayLayer);
    }
    const [FeatureLayer] = await loadModules(["esri/layers/FeatureLayer"]);
    let overlayLayer = new FeatureLayer(layerDefinition);
    map.add(overlayLayer);
    this.setState({map: map, overlayLayer: overlayLayer})
    
  }

  async handleSelectChange(evt){
    //console.log("handle select change", evt.target.name, evt.target.value)
    let name = evt.target.name;
    let value = evt.target.value;
    if(evt.target.dataset.numeric === 'true'){
      value = parseFloat(value);
    }
    if (evt.target.type === 'checkbox'){
      value = evt.target.checked;
    }

    await this.setState({ [name]: value }, () =>
      // Check to see whether the Submit button should be enabled
      this.writeWhereClause()
    );

    // Update the nearby type alias, if applicable
    if (evt.target.dataset['layer'] !== undefined) {
      let values = nearbyConfig()[evt.target.dataset.layer].values;
      let alias = values.find(o => o.value === evt.target.value).alias;
      await this.setState({nearbyTypeAlias: alias})
      //console.log("alias:", alias)
    }

    // Reset the Status Type if the Class Subtype has changed
    try{
      if (evt.target.name === 'classSubtype') {
        await this.setState({statusType: ''});
        //console.log("reset status type", this.state.statusType)
      }
    } catch(err) {
      console.error("Error trying to reset status type")
    }
  }

  handleGeocodeOptionsUpdate(options){
    this.setState({geocodeOptions: options})
  }

  handleGeocodeChange(selected){

    if (selected){
      if (selected.length > 0) {
        this.setState({selectedGeocode: selected[0]});
        //console.log("selected", selected[0].text)
      } else if(selected && selected.text){
        this.setState({selectedGeocode: selected});
        //console.log("selected", selected.text)
      }
      this.displayGeocodeResult();
    } else {
      //console.log("Clearing geocode value")
      this.setState({selectedGeocode: null, geocodeForReport: []});
      this.clearGeocodeResults()
    }

    this.zoomToGeoCode();

  }

  toggleState(paramName){
    let currentState = this.state[paramName];
    this.setState({[paramName]: !currentState});
  }

  async handleMapExtentChange(){
    // Ignore map extent changes if the search hasn't run yet - this is just the map instantiating

    // console.log("Map extent changed. Scale:", Math.round(this.state.view.scale).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    await this.setState(prevState => {
       return {searchCount: prevState.searchCount + 1}
    })
    // console.log("handle map extent change, searchCount:", this.state.searchCount)
    if (window.location.search === "?firstRun") {
      window.history.replaceState(null, null, window.location.pathname);
      if (this.state.selectedGeocode){
        this.clearGeocodeResults();
        this.displayGeocodeResult();
        this.zoomToGeoCode();
      }
      return;
    }

    // When the map extent changes, if the Search As Map Moves option
    // is NOT checked, allow the user to re-run the search for this new extent

    await this.setState({extentChanged: true})
    this.writeWhereClause();

    // If this is the first time the map has been opened from the Explore Map button, don't run a search
    if (window.location.hash === "#/explore?firstRun") {
      window.location.hash = "#/explore";
      this.setState({sidebarMode: "search", features: []});
      this.clearClcLayers();
      if (this.state.selectedGeocode){
        this.clearGeocodeResults();
        this.displayGeocodeResult();
        this.zoomToGeoCode();
      }

    } else if (this.state.autoRunSearch && this.state.searchReady){
      if (this.state.sidebarMode === 'search' || this.state.mapClass === 'active'){
        // console.log("auto-searching is disabled while the search form is open")
        return;
      } else {
        this.handleSearchSubmit();
      }
    }

  }

  saveViewToState(view, page){
    //console.log("save view to state", view, page)
    this.setState({view: view})

    if (page === 'explore'){

      // Watch for map extent changes, to manage the Search As I Move The Map function
      loadModules(["esri/core/watchUtils"]).then(([watchUtils]) => {
        watchUtils.whenTrue(view, "stationary", this.handleMapExtentChange);

        try{

          // Show/hide the loading indicator when the view is updating
          watchUtils.whenTrue(view, "updating", evt => {
            this.setState({mapIsUpdating: true});
          });

          watchUtils.whenFalse(view, "updating", evt => {
            this.setState({mapIsUpdating: false});
          });
        } catch(err) {
          console.error("Problem getting hold of the view", err.message)
        }
        
      });
      
      view.when(this.checkForSearchParam);
      // view.map.allLayers.forEach(lyr => this.watchLayerVisibility(lyr));

    }
  }

  saveMapToState(map){
    //console.log("saving map to state", map)
    this.setState({map:map, originalMap: map});
  }

  checkForSearchParam(){
    //console.log("Check for search param")
    if (window.location.hash.indexOf("#search") > -1) {
      // If this page was loaded from the Submit button on the Landing page,
      // it will have the parameter #search, so run a search, then remove the hash
      //console.log("Search parameter detected, running a search")
      window.history.replaceState(null, null, window.location.pathname)
      this.handleSearchSubmit();
    }

  }

  raiseError(message){
    console.error("error",message)
    this.setState({modalContent: message, modalOpen: true});
  }

  closeModal() {
    this.setSidebarMode("search");
    this.setState({modalOpen: false});
  }

  toggleSourcesModal(){
    let visible = this.state.showSourcesModal;
    if (visible){
      console.log("Remove hash from URL");
      let newUrl = window.location.href.substr(0, window.location.href.indexOf("#"));
      window.history.pushState("object or string", "Title", newUrl);
    }
    this.setState({showSourcesModal: !visible});
  }

  async updatePagination(evt){
    // scroll through the searchResults
    if (evt.target.dataset.pagination === 'next' || evt.target.parentElement.parentElement.dataset.pagination === 'next') {
      await this.setState(prevState => {
         return {paginationCurrentPage: prevState.paginationCurrentPage + 1}
      })
    } else if (evt.target.dataset.pagination === 'prev' || evt.target.parentElement.parentElement.dataset.pagination === 'prev') {
      await this.setState(prevState => {
         return {paginationCurrentPage: prevState.paginationCurrentPage - 1}
      })
    }

  }

  toggleSidebar(){
    //console.log("toggle sidebar")
    if (this.state.mapClass === 'active') {
      this.setState({mapClass: null});

    } else {
      this.setState({mapClass: "active"})

    }

   this.reRenderSidebarBtn();
  }

  resetSettings(removeExplorerLayers){
    // reset state values
    this.clearGeocodeResults();
    let originalMap = this.state.originalMap;

    // remove Explore Menu layers
    if (removeExplorerLayers){
      try{
        let layersToRemove = originalMap.layers.items.filter(layer => layer.exploreMenuLayer === true)
        layersToRemove.forEach(layer => {
          originalMap.remove(layer)
        })
      } catch(err){
        console.error("There was a problem removing Explore Menu layers", err.message)
      }
    }

    // Remove results layers
    ['polygonLayer', 'centroidLayer', 'polygonGraphicsLayer'].forEach(layerName => {
      let layer = originalMap.layers.items.find(o => o.id === layerName);
      if (layer) {
        console.log("removing layer", layer.title)
        originalMap.remove(layer)
      }
    })

    this.setState({
      acceptedDisclaimer: true,
      areaMin: null,
      areaMax: null,
      classSubtype: 'any',
      statusType: '',
      features: [],
      fer: 'any',
      geocodeLatLong: null,
      geocodeForReport: [],
      geocodeOptions: [],
      map: originalMap,
      nearbyBuffers: [],
      nearbyDistance: 1,
      nearbyGeometryType: null,
      nearbyLayer: '',
      nearbyPointsLayer: null,
      nearbyPolygonsLayer: null,
      nearbyType: '',
      nearbyTypeAlias: '',
      nearbyTypes: [],
      reserve: 'any',
      selectedGeocode: null,
      sidebarMode: "search"
    });

  }

  clearOverlayLayer(){
    if (this.state.overlayLayer){
      this.state.map.remove(this.state.overlayLayer);
      this.setState({overlayLayer: null});
    }
  }

  updateLoadedStatus(status){
    this.setState({isLoaded: status}, () => {
      //console.log("map loaded:", this.state.isLoaded)
      if (this.state.isLoaded) {
        // Restore scrolling, which is temporarily disabled during loading
        document.body.style.overflowY = 'auto';
      }
    });
  }

  checkAgolPrivileges(){
    
    let params = {
        token: this.state.token,
        f: 'json'
    }

    // Parameterise the params object
    var str = "";
    for (var key in params) {
        if (str !== "") {
            str += "&";
        }
        str += key + "=" + encodeURIComponent(params[key]);
    }

    // Add the parameters then submit the job
    let url = 'https://www.arcgis.com/sharing/rest/community/self';
    url += "?" + str;
    axios.get(url)
      .then((response) => {
        // Store the user's ArcGIS Online privileges
        if (response.data.privileges) {
          this.setState({agolPrivileges: response.data.privileges})
        } else {
          console.error("There was a problem fetching the user's AGOL privileges")
        }
      })
      .catch((err) => {
        console.error("There was a problem fetching the user's AGOL privileges", err)
      })

}

handleDisclaimer = () => {
  //console.log("Accepted disclaimer")
  this.setState({acceptedDisclaimer: true})
}

toggleAddRemoveMode(evt){
  loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"], { css: true }).then(([QueryTask, Query]) => {
    let addRemoveMode = evt.target.dataset.mode;
    let view = this.state.view;
    let addRemoveHandler = this.state.addRemoveHandler;
    if (addRemoveHandler) {
      addRemoveHandler.remove();
      addRemoveHandler = null;
    }

    view.popup.watch("visible", (visible) => {
      if (visible){
        view.popup.visible = (this.state.addRemoveMode === null);
      }
    });

    if (this.state.addRemoveMode === addRemoveMode){
      this.setState({addRemoveMode: null});
    } else {

      this.setState({addRemoveMode}, () => {
        if (this.state.addRemoveMode === 'add'){
          addRemoveHandler = view.on("click", evt => {
            view.popup.visible = false;
            let url;
            this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
            let idField;
            this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
            let queryTask = new QueryTask(url)
            let query = new Query();
            query.outFields = [idField];
            query.geometry = evt.mapPoint;
            query.returnGeometry = true;
            console.log("clicked on the view in add mode")
            queryTask.execute(query).then((results) => {
              if (results.features.length > 0 ){
                this.setState({addRemoveChanged: true});
                results.features.forEach(feature => this.addToSearchResults(feature));
              }
            })

          });
        } else if (this.state.addRemoveMode === 'remove'){
          console.log('clicking on the map removes from results');
          addRemoveHandler = view.on("click", evt => {
            view.popup.visible = false;
            let url;
            this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
            let idField;
            this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
            let queryTask = new QueryTask(url)
            let query = new Query();
            query.outFields = [idField];
            query.geometry = evt.mapPoint;
            query.returnGeometry = false;
            console.log("clicked on the view in remove mode")
            queryTask.execute(query).then((results) => {
              if (results.features.length > 0 ){
                this.setState({addRemoveChanged: true});
                results.features.forEach(feature => this.removeFromSearchResults(feature));
              };
            });

          });
        } else {
          view.popup.watch("visible", (visible) => {
            view.popup.visible = true;
          });
        }
      })
    }

    this.setState({addRemoveHandler})
  })

    


}

  render() {
    return (
        <Router>
            <Route
              path='/' exact
              render={(props) => (
                <React.Fragment>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Landing {...props}
                    acceptedDisclaimer={this.state.acceptedDisclaimer}
                    clearGeocodeResults = {this.clearGeocodeResults}
                    error={this.state.error}
                    geocodeLatLong={this.state.geocodeLatLong}
                    geocodeOptions={this.state.geocodeOptions}
                    handleDisclaimer={this.handleDisclaimer}
                    handleGeocodeChange = {this.handleGeocodeChange}
                    handleGeocodeOptionsUpdate={this.handleGeocodeOptionsUpdate}
                    handleSearchSubmit={this.handleSearchSubmit}
                    isLoaded={this.state.isLoaded}
                    isLoggedIn={this.state.isLoggedIn}
                    login = {this.login}
                    logout = {this.logout}
                    mode={this.state.mode}
                    page = "landing"
                    resetSettings = {this.resetSettings}
                    searchMessages={this.state.searchMessages}
                    searchPending={this.state.searchPending}
                    searchReady={this.state.searchReady}
                    selectedGeocode={this.state.selectedGeocode}
                    showSourcesModal={this.state.showSourcesModal}
                    toggleSourcesModal={this.toggleSourcesModal}
                  />
                  {((this.state.mode !== 'public' && this.state.isLoggedIn) || (this.state.mode === 'public' && !this.state.acceptedDisclaimer)) && !this.state.isLoaded ? 
                    <div id="minimap">
                      <MainMap
                        mode= {this.state.mode}
                        page = "landing"
                        agolPrivileges = {this.state.agolPrivileges}
                        map = {this.state.map}
                        saveMapToState = {this.saveMapToState}
                        saveViewToState = {this.saveViewToState}
                        token={this.state.token}
                        updateLoadedStatus = {this.updateLoadedStatus}
                        view = {this.state.view}
                      />
                    </div>
                  : null }
                </React.Fragment>
              )}
            /> 

            <Route
              path='/explore' exact
              render={(props) => (
                <React.Fragment>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                
                  <Explore {...props}
                    addRemoveChanged = {this.state.addRemoveChanged}
                    addRemoveMode = {this.state.addRemoveMode}
                    agolPrivileges = {this.state.agolPrivileges}
                    areaMax = {this.state.areaMax}
                    areaMin = {this.state.areaMin}
                    autoRunSearch = {this.state.autoRunSearch}
                    classSubtype = {this.state.classSubtype}
                    clearFeatures = {this.clearFeatures}
                    clearGeocodeResults = {this.clearGeocodeResults}
                    clearOverlayLayer={this.clearOverlayLayer}
                    closeModal = {this.closeModal}
                    displayGeocodeResult = {this.displayGeocodeResult}
                    displayWidgets = {this.displayWidgets}
                    error={this.state.error}
                    exceededTransferLimit={this.state.exceededTransferLimit}
                    extentChanged={this.state.extentChanged}
                    features={this.state.features}
                    fer={this.state.fer}
                    geocodeForReport={this.state.geocodeForReport}
                    geocodeLatLong={this.state.geocodeLatLong}
                    geocodeOptions={this.state.geocodeOptions}
                    handleGeocodeOptionsUpdate={this.handleGeocodeOptionsUpdate}
                    toggleState={this.toggleState}
                    handleGeocodeChange = {this.handleGeocodeChange}
                    handleNearbyLayerChange={this.handleNearbyLayerChange}
                    handleSearchSubmit={this.handleSearchSubmit}
                    handleSelectChange={this.handleSelectChange}
                    isLoaded={this.state.isLoaded}
                    isLoggedIn={this.state.isLoggedIn}
                    logout = {this.logout}
                    map={this.state.map}
                    mapClass={this.state.mapClass}
                    mapIsUpdating={this.state.mapIsUpdating}
                    modalContent = {this.state.modalContent}
                    modalOpen = {this.state.modalOpen}
                    mode = {this.state.mode}
                    nearbyBuffers = {this.state.nearbyBuffers}
                    nearbyDistance={this.state.nearbyDistance}
                    nearbyLayer={this.state.nearbyLayer}
                    nearbyPointsLayer={this.state.nearbyPointsLayer}
                    nearbyPolygonsLayer={this.state.nearbyPolygonsLayer}
                    nearbyType={this.state.nearbyType}
                    nearbyTypeAlias={this.state.nearbyTypeAlias}
                    nearbyTypes={this.state.nearbyTypes}
                    overlapFeatureCount={this.state.overlapFeatureCount}
                    overlayLayer={this.state.overlayLayer}
                    page = "explore"
                    paginationCurrentPage = {this.state.paginationCurrentPage}
                    queryResponseCount = {this.state.queryResponseCount}
                    removeAllSearchResults = {this.removeAllSearchResults}
                    removeFromSearchResults = {this.removeFromSearchResults}
                    reserve={this.state.reserve}
                    resetSettings = {this.resetSettings}
                    zoomToGeoCode = {this.zoomToGeoCode}
                    saveMapToState = {this.saveMapToState}
                    saveViewToState = {this.saveViewToState}
                    searchMessages={this.state.searchMessages}
                    searchPending={this.state.searchPending}
                    searchReady={this.state.searchReady}
                    selectedGeocode={this.state.selectedGeocode}
                    setSidebarMode={this.setSidebarMode}
                    showSourcesModal={this.state.showSourcesModal}
                    sidebarMode={this.state.sidebarMode}
                    statusType={this.state.statusType}
                    toggleAddRemoveMode={this.toggleAddRemoveMode}
                    toggleSidebar={this.toggleSidebar}
                    toggleSourcesModal={this.toggleSourcesModal}
                    token={this.state.token}
                    updateLoadedStatus = {this.updateLoadedStatus}
                    updateOverlayLayer={this.updateOverlayLayer}
                    updatePagination={this.updatePagination}
                    // updateVisibleLayers={this.updateVisibleLayers}
                    useOverlayLayer={this.state.useOverlayLayer}
                    userId={this.state.userId}
                    view = {this.state.view}
                    // visibleLayers = {this.state.visibleLayers}
                  />
                </React.Fragment>
              )}
            />

            <Route
              path="/report" exact
              render={(props) => (
                <React.Fragment>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Report {...props}
                    isLoggedIn={this.state.isLoggedIn}
                    logout = {this.logout}
                    features={this.state.features}
                    map={this.state.map}
                    page = "report"
                    resetSettings = {this.resetSettings}
                    runNearbyQuery = {this.runNearbyQuery}
                    saveMapToState = {this.saveMapToState}
                  />
                </React.Fragment>
              )}
            />

            <Route
              path="/printout" exact
              render={(props) => (
                <React.Fragment>
                  <Printout {...props}
                    isLoggedIn={this.state.isLoggedIn}
                    logout = {this.logout}
                    features={this.state.features}
                    map={this.state.map}
                    page = "printout"
                    resetSettings = {this.resetSettings}
                    runNearbyQuery = {this.runNearbyQuery}
                    saveMapToState = {this.saveMapToState}
                  />
                </React.Fragment>
              )}
            /> 

        </Router>
    )
  }
}

export default App;
