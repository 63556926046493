
const sourcesConfig = () => {

  return {

    sources: [
      {
        title: "Crown Estate - PLACEHOLDER",
        id: "crown-estate",
        children: [
        //   {
        //     title:"County",
        //     description:"It depicts one of 141 basic divisions of the State of New South Wales, further divided into parishes, for administrative purposes, especially for management and disposal of Crown lands. County and Parish are historical layers and the information contained was gathered from Parish and County maps which are now held at State Records (digital versions can be accessed through the Historical Lands Records Viewer). Boundaries in this dataset can be updated (if necessary) after a title inspection.",
        //     source:"Spatial Services NSW",
        //     lastUpdated:"Current",
        //     frequencyUpdated:"Updated as required.",
        //     url:"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=e669f213174d4aeb8b59234fccb15737",
        //     licenseInformation:"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
        //  },
          {
            title:"Usage Areas",

          },
          {
            title:"Reserves",
          },
          {
            title:"Leases",
          },
          {
            title:"Enclosure Permits",
          },
        ]
      },
      {
        title: "Administrative Boundaries",
        id: "administrative-boundaries",
        children: [
          {
            "title":"County",
            "description":"It depicts one of 141 basic divisions of the State of New South Wales, further divided into parishes, for administrative purposes, especially for management and disposal of Crown lands. County and Parish are historical layers and the information contained was gathered from Parish and County maps which are now held at State Records (digital versions can be accessed through the Historical Lands Records Viewer). Boundaries in this dataset can be updated (if necessary) after a title inspection.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=e669f213174d4aeb8b59234fccb15737",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Land Districts",
            "description":"Local Land District",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=9dc2caa5f5f4418a87c7913b231b7b66",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Parish",
            "description":"NSW Parish is a dataset within the Administrative boundaries theme of the FSDF. It contains 7,459 administrative areas (Parishes) formed by the division of 141 counties. Counties and parishes are administrative divisions of the state and are not separately disposable land parcels. County and Parish are historical layers and the information contained on these layers was gathered from Parish and County maps which are now held at State Records.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=29b2ceaa01d4406ea3d5be061bc9697c",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2019"
         },
         {
            "title":"Federal Electoral Divisions",
            "description":"NSW Federal Electoral Division is a feature class which represents a gazetted area of a federal electoral division that has been defined by redistribution.",
            "source":"Australian Electoral Commission. Published by Spatial Services NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=b3a0e462750c4dfeb7c9f4e8564cc840",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Local Aboriginal Land Councils",
            "description":"NSW Local Aboriginal Land Council is a dataset within the NSW Administrative boundaries theme (FSDF). It depicts a gazetted area with input by the Aboriginal and Torres Strait Islander Commission of Local Aboriginal Land Councils. The dataset contains the local council name and regional council name.",
            "source":"Office of Aboriginal Affairs and NSW Local Aboriginal Land Council. Published by Spatial Services NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=8b4a0762283b458187c4960e771d8ef2",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Local Government Areas",
            "description":"NSW Local Government Area is a dataset within the Administrative boundaries theme (FSDF). It depicts polygons of gazetted boundaries defining the Local Government Area. It contains all of the cadastral line data or topographic features which are used to define the boundaries between adjoining shires, municipalities, cities (Local Government Act) and the unincorporated areas of NSW.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=3e1edb6861524b5490c74db81e42433a",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Local Land Services Regions",
            "description":"NSW Local Land Service Region (LLSR) is a dataset within the NSW Administrative boundaries theme (FSDF). It was formerly known as the Livestock Health and Pest Authority (LHPA) District which itself was the replacement for Rural Lands Protection Districts. This dataset contains the name and the boundaries of the LLSR.",
            "source":"Department of Primary Industries – Local Land Services. Published by Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=7ce96ef7f1504fcda859110450cc2649",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"State Electoral Districts",
            "description":"NSW State Electoral District is a dataset within the NSW Administrative boundaries theme (FSDF). It represents current NSW electoral boundaries which were formalised in the 2013 electoral redistribution and will be in place until the scheduled 2023 NSW state election.",
            "source":"Electoral Commission NSW. Published by Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2b607dab4a1442ffae90c85e50c57561",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"State Forests",
            "description":"NSW State Forest is a dataset within the Administrative boundaries theme (FSDF). It represents gazetted areas reserved for forestry purposes. The NSW Forestry Corporation is responsible for this dataset. Any changes that occur to the dataset should have a reference in the authority of reference feature class in the Land Parcel and Property theme. There is no overall accuracy reported in the database.",
            "source":"Office of Environment and Heritage NSW. Published by Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=c8b7f937377f4a4da455c0d64daed2b5",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Mine Subsidence District",
            "description":"Mine Subsidence Districts define the area of control of the Mine Subsidence Board, which administers the Mine Subsidence Compensation Act, 1961. The Mine Subsidence Board is responsible for this dataset. Any changes that occur to the dataset should have a reference in the authority of reference feature class in the FSDF theme.",
            "source":"Subsidence Advisory NSW. Published by Spatial Services NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=9d0d0178c91543d4a415ce6ee7c7bb2e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2019"
         },
         {
            "title":"National Park and Wildlife Services Reserve",
            "description":"NSW NPWS Reserve is a dataset within the NSW Administrative boundaries theme (FSDF). The dataset contains National Park, Nature Reserve, Historic Site, Regional Park, Karst Conservation Reserve, Aboriginal Area, and State Conservation Area. It identifies under the terms of the National Parks and Wildlife Act, 1974, certain areas of NSW that have been reserved.",
            "source":"Office of Environment and Heritage NSW. Published by Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=76390bbc31dd4dc88c7108b92e3d4dd8",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2019"
         },
         {
          "title":"Suburbs",
          "description":"Suburbs and Localities for NSW",
          "source":"Spatial Services NSW",
          "lastUpdated":"Current",
          "frequencyUpdated":"Updated as required.",
          "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=38bdaa10b7cc41a3a19be6eca91f5368",
          "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2019"
        },
         {
            "title":"Marine Protected Areas (CAPAD)",
            "description":"This layer provides a geographic interpretation of Marine Park and Aquatic Reserve boundaries in NSW as described in the NSW Marine Estate Management (Management Rules) Regulation 1999 and NSW Government Gazette (Aquatic Reserves). Polygons and associated data are identical to those submitted to the DoE for the CAPAD 2018.",
            "source":"Department of Primary Industries",
            "lastUpdated":"2018",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-marine-protected-areas",
            "licenseInformation":"© State Government of NSW and Department of Primary Industries (DPI) 2022"
         },
         {
            "title":"Terrestrial Protected Areas (CAPAD)",
            "description":"The Collaborative Australian Protected Areas Database (CAPAD) 2018 provides both spatial and textual information about government, Indigenous and privately protected areas for Australia, in both the marine and terrestrial environments. This dataset is for terrestrial data only. State, Territory and the large NGO conservation agencies supplied data current for 30 June 2018. This is the eleventh version of the terrestrial database, with previous versions released in 1997, 2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014 and 2016.",
            "source":"Australian Department of the Environment",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknwon",
            "url":"https://services.arcgis.com/xDL0LTy98rbQTFbo/arcgis/rest/services/CAPAD2018_terrestrial/FeatureServer",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Commonwealth of Australia 2020"
         }
        ]
      },
      {
        title: "Native Title Claims",
        id: "native-title-claims",
        children: [
          {
            "title":"Registered Native Title Bodies Corporate",
            "description":"RNTBCs (Registered Native Title Bodies Corporate) are corporations which have prescribed functions under the Native Title Act 1993 (NTA) to hold, protect and manage determined native title and ensure certainty for governments and other parties interested in accessing or regulating native title land and waters by providing a legal entity to manage and conduct the affairs of the native title holders. PBCs (Prescribed Bodies Corporate) are established as an interim measure to represent native title owners and their interests until an RNTBC is formally registered.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Future Act Determination Applications",
            "description":"A future act determination application (FADA) is a future act application that a negotiation party makes to the Tribunal for a determination that the act must not be done, the act may be done or the act may be done with conditions (s 38 of the NTA).A FADA cannot be made within 6 months of the notification date nor if an agreement in relation to the act has been made between the negotiation parties (s 35 of the NTA).The NNTT cannot make a determination in relation to a FADA unless the parties have negotiated in good faith (s 36(2) of the NTA) and it has taken into account the criteria set out in s 39 of the NTA (including effect of the act on the native title parties, the interests and wishes of the native title parties, the economic and other significance of the act to Australia and/or the State or Territory, the public interest in the doing of the act and any other matter the NNTT considers relevant).",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Future Act Objections",
            "description":"A future act objection is where a native title party objects to the inclusion of a statement that the act attracts the expedited procedure (s 32 of the NTA).  A future act is considered to attract the expedited procedure if the act is unlikely to interfere with the native title holders' community or social activities, areas or sites of particular significance, or cause major disturbance to any land or waters concerned (s 237 of the NTA).The native title party has 4 months from the notification date within which to lodge a future act objection (or an &quot;expedited procedure objection application&quot; (s 75(a) of the NTA).",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Future Act Notices Current",
            "description":"Before an act is done, which may affect native title, a Government party must issue a notice under s 29 of the NTA (s 29 notice or future act notice). The notice provides details of the proposed act and specifies a date (notification date) from which persons have either 3 or 4 months to object to the act or become a native title party with rights to negotiate about the doing of the act. The NNTT holds information on all current and historical future act notices. Future act notices for acts that are not yet finalised are referred to as current and include notices where: (a) less than 6 months has passed from the notification date of the notice; (b) the act is the subject of a 'future act application' under s 75 of the NTA; or (c) the act is not an act attracting the expedited procedure (ss 32 and 237 of the NTA).",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Indigenous Land Use Agreements",
            "description":"An indigenous land use agreement (ILUA) is a voluntary, legally binding agreement about the use and management of land or waters, made between one or more native title groups and non-native title interest holders in the ILUA area (such as grantee parties, pastoralists or governments). The Register of Indigenous Land Use Agreements is kept by the Native Title Registrar in accordance with s 199A of the NTA and includes a description of the ILUA area, the parties' names, the term of the ILUA and other information as the Registrar considers is appropriate (s 199B of the NTA). Registration confers a contractual effect on the ILUA and binds all persons holding native title regardless as to whether they are already parties to the ILUA (s 24EA of the NTA). Note: This dataset contains records of ILUAs that have been publicly notified and/or registered.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Native Title Determination Outcomes",
            "description":"An approved determination of native title is made by way of an order of the court pursuant to ss 86, 87 or 87A of the NTA or s 23 of the Federal Court of Australia Act 1976 (Cth). The determination order details whether or not native title exists, and, if native title does exist:a) who holds the native title;b) the nature and extent of the native title rights and interests;c) the nature and extent of other interests in the determination area;d) the relationship between the different rights and interests in the determination area; ande) the extent to which native title is held to the exclusion of others (s 225 of the NTA).Determination outcomes are extracted from the determination order to broadly categorise the land and waters of the determination area where:a) native title does not exist;b) native title exists to the exclusion of all others (exclusive);c) native title exists but not to the exclusion of all others (non-exclusive); andd) native title has been extinguished (extinguished).",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Native Title Determinations",
            "description":"The National Native Title Register is kept by the Native Title Registrar pursuant to s 192 of the NTA to hold information about approved determinations of native title. A determination of native title is a decision on whether native title exists in relation to a particular area of land or waters (s 225 of the NTA). Determinations of native title can be made by agreement (consent determination), without opposition (unopposed determination) or following a trial (litigated determination). An approved determination of native title is a determination of native title made by the Federal Court of Australia, the High Court of Australia, or a recognised State/Territory body within its jurisdictional limits (s 13 of the NTA). Only approved determinations of native title are recorded on the National Native Title Register (s 193 of the NTA). Note: This dataset also includes representations of determinations of native title that have not yet been entered on the National Native Title Register.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Register of Native Title Claims",
            "description":"The Register of Native Title Claims is kept by the Native Title Registrar pursuant to s 185 of the NTA to hold information on claimant applications that have satisfied the registration requirements set out in ss 190B and 190C of the NTA. Native title claimants whose applications have been accepted for registration are afforded a number of procedural rights in relation to future acts that affect native title including the right to negotiate, the right to be notified, the right to comment, the right to request consultation, or the right to object as prescribed under the NTA.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Schedule of Native Title Determination Applications",
            "description":"The Schedule of Native Title Determination Applications is kept by the Native Title Registrar pursuant to s 98A(1) of the NTA and contains information on all applications made under s 61 of the NTA, including claimant, non-claimant, compensation applications and applications for a revised determination of native title. The Schedule includes claimant applications that are not on the Register of Native Title Claims because they have: a) not yet been considered for registration; b) been considered for registration but have not been accepted; or c) been amended, but the amended application has not yet been considered for registration.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"RATSIB Areas",
            "description":"A representative Aboriginal, Torres Strait Islander body (RATSIB) is a body recognised by the Commonwealth under s 203AD of the NTA to represent native title holders and persons who may hold native title and to consult with Aboriginal and Torres Strait Islander persons within a specified area. The statutory functions of RATSIBs are detailed in Part 11 Division 3 of the NTA and include: a) facilitation and assistance to prepare and progress native title applications and negotiation of future act processes; b) certification of native title applications and applications for registration of an ILUA; c) resolution of disputes between constituents; d) notification to persons who hold or may hold native title in the area of notices that relate to land and water in the RATSIB area; and e) agreement making to be a party to ILUAs as appropriate in its specified area.",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         },
         {
            "title":"Records of Section 31 Agreements",
            "description":"Under the Native Title Act, the Registrar must keep a record of section 31 agreements that the Registrar receives from an arbitral body, such as the National Native Title Tribunal. This requirement applies to agreements received on or after 25 March 2021​. A section 31 agreement records the agreement of the parties to the doing of a future act (for example, the grant of a mining tenement) to which the ‘right to negotiate’ applies. Often some or all of the parties will also enter into another written agreement, known as an ‘ancillary agreement.’",
            "source":"National Native Title Register",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=20070f5fe5704e1588f4611d065032a8",
            "licenseInformation":"National Native Title Tribunal, © Commonwealth of Australia"
         }
        ]
      },
      {
        title: "Bushfire",
        id: "bushfire",
        children: [
          {
            "title":"Bushfire Prone Land 2021 (Legend)",
            "description":"Bush Fire Prone Land mapping is intended to designate areas of the State that are considered to be higher bush fire risk for development control purposes.",
            "source":"NSW Rural Fire Service. Published by Crown Land Commissioner",
            "lastUpdated":"2021",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/bush-fire-prone-land",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and NSW Rural Fire Service 2022"
         },
         {
            "title":"Bushfire Prone Land 2021 (Feature Layer)",
            "description":"Bush Fire Prone Land mapping is intended to designate areas of the State that are considered to be higher bush fire risk for development control purposes.",
            "source":"NSW Rural Fire Service. Published by Crown Land Commissioner",
            "lastUpdated":"2021",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/bush-fire-prone-land",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and NSW Rural Fire Service 2022"
         },
         {
            "title":"Bushfire Prone Land 2021 (Vector Tile)",
            "description":"Bush Fire Prone Land mapping is intended to designate areas of the State that are considered to be higher bush fire risk for development control purposes.",
            "source":"NSW Rural Fire Service. Published by Crown Land Commissioner",
            "lastUpdated":"2021",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/bush-fire-prone-land",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and NSW Rural Fire Service 2022"
         },
         {
            "title":"Bushfire Extent 2019 - 2020",
            "description":"The total extent of the bushfires in NSW between July 2019 to February 2020.",
            "source":"NSW Rural Fire Service",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/bush-fire-prone-land",
            "licenseInformation":"© State Government of NSW and NSW Rural Fire Service 2022"
         },
         {
            "title":"Wild Fire History 2001 - 2016",
            "description":"A combined fires layer for NSW extracted from ICON. Updated for Fire Season 06-07 on 26th October 2007. Prior to this the last update was on 25 October 2007, with Fire History data of 2006-2007 fire season loaded. Fire history (burnt area polygons) has been captured (or collated) for NSW by RFS Head Office consistently from 2001/2002 fire season through 2006/2007 fire season. This is a list of values: *Capture Method1 = linescan, 2=statellite data, 3=GPS airborn, 4=GPS car, 5=Hand drawn, 6 = unknown *Capture Source 1=DECC, 2=NSW Fire Brigade, 3=LPI, 5=LGA,6=RFS, 7=BFMC, 8= Forest NSw, 9=Catchment Authority, 10=Private, 11=RFA, 14 Railcorp, 15 = Other, 17 = Planning NSW, 20 = SES, 21 = NSW Police, 22 = NSW Ambulance * Cause of Fire: 1= Lightning, 2 = Campfire, 3=smoking, 4=debris Burning, 5= Equiptment Use, 6 = incediary, 7=Children, 8=Rail, 9=Other, 10 = Undetermined, 11= Accidental, 12 = Natural",
            "source":"NSW Rural Fire Service. Published by Crown Lands.",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://spatial.industry.nsw.gov.au/arcgis/rest/services/CrownLands_Bushfire/Bushfire_RFSData/MapServer/5",
            "licenseInformation":"NSW Dept of Industry, Lands and Forestry"
         },
         {
            "title":"Bushfire Prone Land",
            "description":"Bush Fire Prone Land mapping is intended to designate areas of the State that are considered to be higher bush fire risk for development control purposes.",
            "source":"NSW Rural Fire Service",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://datasets.seed.nsw.gov.au/dataset/bush-fire-prone-land",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and NSW Rural Fire Service 2022"
         },
         {
            "title":"Bushfire RFS Data",
            "description":"Map service showning the Bush Fire RFS Data across NSW.",
            "source":"NSW Rural Fire Service. Published by Crown Lands.",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://spatial.industry.nsw.gov.au/arcgis/rest/services/CrownLands_Bushfire/Bushfire_RFSData/MapServer",
            "licenseInformation":"NSW Dept of Industry, Lands and Forestry"
         }
        ]
      },
      {
        title: "Environment",
        id: "environment",
        children: [
          {
            "title":"Hazard: Flood Planning",
            "description":"This spatial dataset identifies the land where development implications exist due to the risk of flood as designated by the relevant NSW environmental planning instrument (EPI). The specific EPI which defines the planning requirement is described in the dataset.",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/epi-flood",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2014"
         },
         {
            "title":"Hydrography: Named Watercourse - SS",
            "description":"Named creeks for display at large scales",
            "source":"Spatial Services NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Updated as required.",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-hydrography",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Spatial Services (DCS) 2022"
         },
         {
            "title":"Hydrography: Named Watercourse - LS",
            "description":"Named creeks for display at large scales",
            "source":"Spatial Services NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Updated as required.",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-hydrography",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Spatial Services (DCS) 2022"
         },
         {
            "title":"Climate: Average daily mean temperature 1961-1990",
            "description":"Average daily mean temperature 1961-1990",
            "source":"Australian Bureau of Meteorology. Published by Crown Land Commissioner",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknown",
            "url":"http://www.bom.gov.au/jsp/ncc/climate_averages/decadal-temperature/index.jsp?maptype=6&period=6190&product=max#maps",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Copyright Commonwealth of Australia , Bureau of Meteorology"
         },
         {
            "title":"Climate: Average annual rainfall 1981-2010",
            "description":"Average annual rainfall 1981-2010",
            "source":"Australian Bureau of Meteorology. Published by Crown Land Commissioner",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknown",
            "url":"http://www.bom.gov.au/jsp/ncc/climate_averages/decadal-rainfall/index.jsp",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Copyright Commonwealth of Australia , Bureau of Meteorology"
         },
         {
            "title":"Land Use: Land Use 2017 - v1.2",
            "description":"The 2017 Landuse captures how the landscape in NSW is being used for food production, forestry, nature conservation, infrastructure and urban development. It can be used to monitor changes in the landscape and identify impacts on biodiversity values and individual ecosystems. The NSW 2017 Landuse mapping is dated September 2017. This is version 1.2 of the dataset, published June 2020. Version 1.2 of the 2017 Landuse incorporates fine scale mapping of the Greater Sydney Metropolitan Region.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Updated as required.",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-landuse-2017-v1p2-f0ed",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2020"
         },
         {
            "title":"Topography: Elevation Contours - 10m",
            "description":"Unknown",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://spatial.industry.nsw.gov.au/arcgis/rest/services/PUBLIC/Contours/FeatureServer/0",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Urban Heat: Urban Heat Island, Greater Sydney",
            "description":"The Urban Heat Island (UHI) dataset measures the effects of urbanisation on land surface temperatures across Sydney Greater Metropolitan Area for the Summer of 2015-2016. UHI shows the variation of temperature to a non-urban vegetated reference, such as heavily wooded areas or national parks around Sydney. Derived from the analysis of thermal and infrared data from Landsat satellite, the dataset has been combined with the Australian Bureau of Statistics (ABS) Mesh Block polygon dataset to provide a mean UHI temperature that enables multi-scale spatial analysis of the relationship of heat to green cover.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"2016",
            "frequencyUpdated":"Not planned",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-urban-heat-island-to-modified-mesh-block-2016",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2019"
         },
         {
            "title":"Urban Heat: Heat Vulnerability Index, Greater Sydney",
            "description":"The Urban Heat Island (UHI) dataset measures the effects of urbanisation on land surface temperatures across Sydney Greater Metropolitan Area for the Summer of 2015-2016. UHI shows the variation of temperature to a non-urban vegetated reference, such as heavily wooded areas or national parks around Sydney. Derived from the analysis of thermal and infrared data from Landsat satellite, the dataset has been combined with the Australian Bureau of Statistics (ABS) Mesh Block polygon dataset to provide a mean UHI temperature that enables multi-scale spatial analysis of the relationship of heat to green cover.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"2016",
            "frequencyUpdated":"Not planned",
            "url":"https://datasets.seed.nsw.gov.au/dataset/nsw-urban-heat-island-to-modified-mesh-block-2016",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2019"
         },
         {
            "title":"Urban Vegetation: Cover - Percent All Veg., Greater Sydney",
            "description":"The Greater Sydney Region Urban Vegetation Cover to Modified Mesh Block 2016 provides both an area and percentage of vegetation for city blocks and infrastructure corridors in the Greater Sydney Region as of 2016. With this dataset, users can estimate tree canopy and vegetation cover in urban areas at many scales, such as mesh block, precinct, or local government area.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"2016",
            "frequencyUpdated":"Not planned",
            "url":"https://datasets.seed.nsw.gov.au/dataset/greater-sydney-region-urban-vegetation-cover-to-modified-mesh-block",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2019"
         },
         {
            "title":"Urban Vegetation: Cover - Percent Tree and Shrub, Greater Sydney",
            "description":"The Greater Sydney Region Urban Vegetation Cover to Modified Mesh Block 2016 provides both an area and percentage of vegetation for city blocks and infrastructure corridors in the Greater Sydney Region as of 2016. With this dataset, users can estimate tree canopy and vegetation cover in urban areas at many scales, such as mesh block, precinct, or local government area.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"2016",
            "frequencyUpdated":"Not planned",
            "url":"https://datasets.seed.nsw.gov.au/dataset/greater-sydney-region-urban-vegetation-cover-to-modified-mesh-block",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2019"
         },
         {
            "title":"Urban Vegetation: Cover - Percent Tree Canopy, Greater Sydney",
            "description":"The Greater Sydney Region Urban Vegetation Cover to Modified Mesh Block 2016 provides both an area and percentage of vegetation for city blocks and infrastructure corridors in the Greater Sydney Region as of 2016. With this dataset, users can estimate tree canopy and vegetation cover in urban areas at many scales, such as mesh block, precinct, or local government area.",
            "source":"Department of Planning and Environment NSW",
            "lastUpdated":"2016",
            "frequencyUpdated":"Not planned",
            "url":"https://datasets.seed.nsw.gov.au/dataset/greater-sydney-region-urban-vegetation-cover-to-modified-mesh-block",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © State Government of NSW and Department of Planning and Environment 2019"
         },
         {
            "title":"Vegetation: MODIS National Land Cover Dataset",
            "description":"Landcover generated from MODIS satellite",
            "source":"Geoscience Australia",
            "lastUpdated":"2014",
            "frequencyUpdated":"Unknown",
            "url":"https://cmi.ga.gov.au/data-products/dea/131/geoscience-australia-land-cover-terra-modis#basics",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Commonwealth of Australia (Geoscience Australia) 2015"
         },
         {
            "title":"Vegetation: National Vegetation Information System v5.1- Extant Vegetation",
            "description":"This dataset is a vector layer delineating the extant ('present') native vegetation types across Australia made available for release under CC BY. This dataset is part of a larger compilation of Australian states and territories for the latest release (Version 5.1) of the National Vegetation Information System (NVIS).",
            "source":"Department of the Environment and Energy Australia",
            "lastUpdated":"2018",
            "frequencyUpdated":"Unknown",
            "url":"https://data.gov.au/dataset/ds-dga-11976c1c-d0fa-49a3-9720-8fda84be1ce1/details",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Commonwealth of Australia (Department of the Environment and Energy) 2018"
         },
         {
            "title":"Biodiversity Values Map",
            "description":"The Biodiversity Values Map (BV Map) identifies land with high biodiversity value that is particularly sensitive to impacts from development and clearing. The BV Map is one of the triggers for determining whether the Biodiversity Offset Scheme (BOS) applies to a clearing or development proposal.",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Monthly",
            "url":"https://datasets.seed.nsw.gov.au/dataset/biodiversity-values-map",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence."
         }
        ]
      },
      {
        title: "Locations of Interest",
        id: "locations-of-interest",
        children: [
          {
            "title":"Open Street Map - Shops",
            "description":"This feature layer provides access to OpenStreetMap (OSM) shops data for Australia and Oceania, which is updated every 5 minutes with the latest edits. In OSM, a shop is a place selling retail products or services, such as a supermarket, bakery, or florist.",
            "source":"Open Street Map",
            "lastUpdated":"Current",
            "frequencyUpdated":"Every 5 minutes.",
            "url":"https://www.arcgis.com/home/item.html?id=988071da24954be5b250a5d2a6bc6cab",
            "licenseInformation":"Open Database License (ODbl), © OpenStreetMap contributors"
         },
         {
            "title":"Open Street Map - Amenities",
            "description":"This feature layer provides access to OpenStreetMap (OSM) amenity data for Australia and Oceania, which is updated every 5 minutes with the latest edits. In OSM, amenities are useful and important facilities for visitors and residents, such as places of worship, restaurants, banks, and schools.",
            "source":"Open Street Map",
            "lastUpdated":"Current",
            "frequencyUpdated":"Every 5 minutes.",
            "url":"https://www.arcgis.com/home/item.html?id=b4e6461dacd946ea854115570ee4ea68",
            "licenseInformation":"Open Database License (ODbl), © OpenStreetMap contributors"
         },
         {
            "title":"Open Street Map - Tourist Attractions",
            "description":"This feature layer provides access to OpenStreetMap (OSM) tourist attraction point data for Australia and Oceania, which is updated every 5 minutes with the latest edits. In OSM, tourism features are places and things of specific interest to tourists including places to see, places to stay, things and places providing information and support to tourists. ",
            "source":"Open Street Map",
            "lastUpdated":"Current",
            "frequencyUpdated":"Every 5 minutes.",
            "url":"https://www.arcgis.com/home/item.html?id=76e2281c81814e8c8c7e6cb1081f4d22",
            "licenseInformation":"Open Database License (ODbl), © OpenStreetMap contributors"
         },
         {
            "title":"NSW Electricity Transmission Line",
            "description":"A wire used for the transmission of electricity rated at 19.13kv through to 500kv. This line feature dataset is part of Spatial Services Defined Administrative Data Sets Where possible, line geometries of the electricity transmission line dataset align to Spatial Services Defined Administrative Data Sets.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=deee22e925fd4b9b9e0de1ffd5c8fa62",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Spatial Services 2019"
         },
         {
            "title":"NSW Emergency Service Facilities",
            "description":"This service provides online access to the National Emergency Services Facilities Dataset 2018. The dataset presents the spatial location; in point format, of all known Emergency Services facilities within Australia.",
            "source":"Geoscience Australia",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://services9.arcgis.com/ZFlIzBMHgtgl0EYj/ArcGIS/rest/services/National_Emergency_Services_Facilities_2018/FeatureServer/0",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Commonwealth of Australia (Geoscience Australia) 2018"
         }
        ]
      },
      {
        title: "LEPs and SEPPS",
        id: "leps-and-sepps",
        children: [
          {
            "title":"Local Environment Plan Zoning: Height of Building",
            "description":"This spatial dataset identifies the maximum height of a building that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au.",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/14",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Height of Building - Additional Controls",
            "description":"This spatial dataset identifies the maximum height of a building that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field EPI_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/13",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Minimum Lot Size",
            "description":"This spatial dataset identifies the minimum lot size that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/22",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Minimum Lot Size - Additional Controls",
            "description":"This spatial dataset identifies the minimum lot size that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field EPI_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/21",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Land Reservation Acquisition",
            "description":"This spatial dataset identifies land that is reserved for acquisition by an authority of the State or land to which the owner-initiated acquisition provisions in Division 3 of Part 2 of the Land Acquisition (Just Terms Compensation) Act 1991 applies, as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/24",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Land Zoning",
            "description":"This spatial dataset identifies land use zones and the type of land uses that are permitted (with or without consent) or prohibited in each zone on any given land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. Land use zones are grouped into the following broad categories: BUSINESS (B), ENVIRONMENT (E), INDUSTRIAL (IN), RESIDENTIAL (R), RECREATION (RE), RURAL (RU), SPECIAL (SP) and WATERWAYS (W). The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/19",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Land Zoning - Additional Controls",
            "description":"This spatial dataset identifies the minimum lot size that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field EPI_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/21",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Floor Space Ratio",
            "description":"This spatial dataset identifies the maximum floor space ratio that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/11",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: Floor Space Ratio - Additional Controls",
            "description":"This spatial dataset identifies the maximum floor space ratio that is permitted on land as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au.",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/10",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: EPI Heritage",
            "description":"This spatial dataset identifies areas subject to Heritage conservation as designated by the relevant NSW environmental planning instrument (EPI) under the Environmental Planning and Assessment Act 1979. The specific EPI which defines the planning requirement is described in the attribute field LEP_Name. The EPI can be viewed on the NSW legislation website: www.legislation.nsw.gov.au.",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/16",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Local Environment Plan Zoning: State Heritage Register Curtilage",
            "description":"State Heritage Register Curtilage",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/ePlanning/Planning_Portal_Layers/MapServer/221",
            "licenseInformation":"Unknown"
         },
         {
            "title":"State Environmental Planning Policy: State Significant Precincts - Subject Land",
            "description":"State Significant Precincts - Subject Land",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/Planning/SEPP_State_Significant_Precincts_2005/MapServer",
            "licenseInformation":"Unknown"
         },
         {
            "title":"State Environmental Planning Policy: Coastal Wetlands",
            "description":"Coastal Wetlands",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/Planning/SEPP_Coastal_Management_2018/MapServer/2",
            "licenseInformation":"Unknown"
         },
         {
            "title":"State Environmental Planning Policy: Littoral Rainforest",
            "description":"Littoral Rainforest",
            "source":"Department of Primary Industries NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/Planning/SEPP_Coastal_Management_2018/MapServer/3",
            "licenseInformation":"Unknown"
         }
        ]
      },
      {
        title: "Land Pacels & Property",
        id: "land-parcels-and-property",
        children: [
          {
            "title":"Cadastral Fabric: Lot",
            "description":"Depicts a parcel of land created on a survey plan. Each lot may be represented by standard lots, standard part lots, strata or stratum. Each lot has a lot number, section number, plan lot area, plan number, plan label, ITS title status, and stratum label.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Fabric: Unidentified",
            "description":"Represents a parcel of land that cannot be identified. Crown land, vested, dedicated and severed land may be included in this category as well as Old System lots for which lot/DP identification cannot be found. This dataset also identifies the locations of 100ft wide reserves, ACT regions, closed roads, crossings, surveyed areas, and un-surveyed areas.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Fabric: Road",
            "description":"Represents dedicated public roads which are open ways for the passage of vehicles, persons or animals on land. The road dataset includes public roads in use. Each road type has a section number, plan number, plan label, ITS title status, road type, road width or Crown/Council width, lot number, and stratum label.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Fabric: Water Feature",
            "description":"Represents tidal, non-tidal and ocean waters which form a cadastral boundary.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Water Feature Corridor",
            "description":"Represents the extent of a water feature or the delineation between water features of a different type or status. The dataset contains high water mark, low water mark, the limit of tidal influence and bay closing lines.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Road Corridor",
            "description":"Represents the spatial extent of the legal road network",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Railway Corridor",
            "description":"Represents a part of the Land Parcel and Property Theme covering railway land that is not defined by a lot.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Property",
            "description":"Property data is a polygon feature class that spatially represents an  aspatial property description as provided by Property NSW in their Valnet database.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Easement (including Carriageway)",
            "description":"Depicts a right, attached to land (the dominant tenement), to use other land (the servient tenement) for a specified non-exclusive purpose known to the law, e.g. right of carriageway, easement to drain water etc. – however the law also recognises an easement in favour of a statutory authority without a dominant tenement, described as an ‘easement in gross’.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Road Centreline",
            "description":"Represents a line that forms the centreline of cadastral road corridors.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Authority Reference",
            "description":"Depicts the changes to an area definition that has occurred through a gazettal, act or government file action.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         },
         {
            "title":"Cadastral Feature: Watermark",
            "description":"Represents the spatial extent of tidal, non-tidal and ocean waters which form a cadastral boundary.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"Updated as required.",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=01de8834e88a45a1a673b120aa00c82e",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence. © Spatial Services 2021"
         }
        ]
      },
      {
        title: "Property Valuations",
        id: "property-valuations",
        children: [
          {
            "title":"Property Valuation - Urban",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         },
         {
            "title":"Property Boundary - Urban",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         },
         {
            "title":"Property Valuation - Semi Rural",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         },
         {
            "title":"Property Boundary - Semi Rural",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         },
         {
            "title":"Property Valuation - Rural",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         },
         {
            "title":"Property Boundary - Rural",
            "description":"Land values and property sales information from across NSW ",
            "source":"Spatial Services NSW, Department of Customer Service, Valuer General, Property NSW.",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=2536c8e4882140eb957e90090cb0ef97",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © Department Customer Service [date of extraction]"
         }
        ]
      },
      {
        title: "Socioeconomic",
        id: "socioeconomic",
        children: [
          {
            "title":"Community Profile - LGA",
            "description":"Community Profile information about the population from the 2016 Census, within Local Government Areas.",
            "source":"Australian Bureau of Statistics. Published by Crown Land Commissioner",
            "lastUpdated":"2016",
            "frequencyUpdated":"Unknown",
            "url":"https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/2916.0main+features152016",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Tourism Overnight Expenditure - Tourism Regions",
            "description":"Tourism data from Tourism Research Australia for year ending June 2020.",
            "source":"Tourism Research Australia. Published by Crown Land Commissioner",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknown",
            "url":"https://www.tra.gov.au/data-and-research/reports/national-visitor-survey-results-june-2020/national-visitor-survey-results-june-2020",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Unemployment - LGA",
            "description":"Unemployment information about the population from the 2016 Census, within Local Government Areas.",
            "source":"Australian Bureau of Statistics. Published by Crown Land Commissioner",
            "lastUpdated":"2016",
            "frequencyUpdated":"Unknown",
            "url":"https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/2916.0main+features152016",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Tourism Region Boundaries",
            "description":"Tourism data from Tourism Research Australia for year ending June 2020.",
            "source":"Tourism Research Australia. Published by Crown Land Commissioner",
            "lastUpdated":"2020",
            "frequencyUpdated":"Unknown",
            "url":"https://www.tra.gov.au/data-and-research/reports/national-visitor-survey-results-june-2020/national-visitor-survey-results-june-2020",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Index of Socio Economic Advantage & Disadvantage - LGA",
            "description":"The Index of Relative Socio-economic Advantage and Disadvantage (IRSAD) summarises information about the economic and social conditions of people and households within an area, including both relative advantage and disadvantage measures.A low score indicates relatively greater disadvantage and a lack of advantage in general. A high score indicates a relative lack of disadvantage and greater advantage in general.",
            "source":"Australian Bureau of Statistics. Published by Crown Land Commissioner",
            "lastUpdated":"2016",
            "frequencyUpdated":"Unknown",
            "url":"https://www.abs.gov.au/ausstats/abs@.nsf/mf/2033.0.55.001",
            "licenseInformation":"Unknown"
         },
         {
            "title":"Indigenous Population - LGA",
            "description":"Indigenous population information from the 2016 Census, within Local Government Areas.",
            "source":"Australian Bureau of Statistics. Published by Crown Land Commissioner",
            "lastUpdated":"2016",
            "frequencyUpdated":"Unknown",
            "url":"https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/2916.0main+features152016",
            "licenseInformation":"Unknown"
         }
        ]
      },
      {
        title: "Travelling Stock Reserves",
        id: "travelling-stock",
        children: [
          {
            "title":"Travelling Stock Reserves Classification",
            "description":"A data set that classifies Travelling Stock Reserves (TSR) under Local Land Services management in accordance with the LLS Statewide TSR Plan of Management.",
            "source":"NSW Department of Primary Industries, Local Land Services",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://services.arcgis.com/xDL0LTy98rbQTFbo/ArcGIS/rest/services/LLS_TSR_StateClassificationMap_Categories/FeatureServer/2",
            "licenseInformation":"© Local Land Services NSW 2019"
         },
         {
            "title":"Travelling Stock Reserve Highways",
            "description":"TSR Highways are defined as the main droving routes connecting NSW with Queensland and Victoria. TSR highways are critical for maintaining a viable, connected network of TSRs across the sate. The highways allow livestock to be moved between regiona through the TSR Network.TSR Livestock Highways for NSW mapped by Local Land Services and the Combined Action to Retain Reserved for Travelling stock (CARRTS) group.For further informaiton: NSW Travelling Stock Reserves Inverim Update, November 2017 (https://southeast.lls.nsw.gov.au/__data/assets/pdf_file/0010/748414/Travelling-Stock-Reserves-Review-interim-update-final.pdf)",
            "source":"NSW Department of Primary Industries, Local Land Services",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://services.arcgis.com/xDL0LTy98rbQTFbo/arcgis/rest/services/LivestockHighways/FeatureServer/0",
            "licenseInformation":"© NSW Department of Industry 2017"
         },
         {
            "title":"Travelling Stock Reserve Conservation Value 2019",
            "description":"The “Linear Reserves – Managing Travelling Stock Reserves For Sustainable Conservation Outcomes” project was funded by the NSW Environmental Trust and delivered by Local Land Services. A key component of the project was the creation of a statewide dataset of conservation values on TSRs to assist public land managers with their asset management. This dataset is a compilation of ‘best available’ information from new and historical assessments of TSRs. Each TSR has been assigned High, Medium and Low conservation value and is contained in the field ‘BIOCVFINAL’.",
            "source":"Department of Regional New South Wales",
            "lastUpdated":"2017",
            "frequencyUpdated":"Unknown",
            "url":"https://datasets.seed.nsw.gov.au/dataset/travelling-stock-reserves",
            "licenseInformation":"Creative Commons Attribution 3.0 Australia Licence, © State Government of NSW and Department of Regional New South Wales 2022"
         }
        ]
      },
      {
        title: "NSW Base Maps",
        id: "nsw-base-maps",
        children: [
          {
            "title":"NSW Hydrography Map",
            "description":"The NSW Hydrography web service provides access to a topographic map of New South Wales showing hydrography-related features and drainage. ",
            "source":"Spatial Services NSW",
            "lastUpdated":"Unknown",
            "frequencyUpdated":"Unknown",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=d01c1953e13948ce961d48e9d7ac3c4d",
            "licenseInformation":"© Department of Finance, Services & Innovation 2018"
         },
         {
            "title":"NSW Topo Map",
            "description":"Map Cache Web Service provides rasterised topographic maps in a seamless mosaic view covering NSW. This service generally contains the current standard Topographic maps from the 1:100,000; 1:50,000 and 1:25,000 series. Where coverage exists at multiple scales, the largest scale map is displayed. ",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=599c4f5b7e8b4440a24458d2415bafaa",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Spatial Services 2019"
         },
         {
            "title":"NSW Base Map",
            "description":"The NSW Base Map web service depicts a map of NSW using layers from the Digital Topographic Database, the Geocoded Urban and Rural Addressing System database and the Digital Cadastral Database.",
            "source":"Spatial Services NSW",
            "lastUpdated":"Current",
            "frequencyUpdated":"As required",
            "url":"https://portal.spatial.nsw.gov.au/portal/home/item.html?id=b9a3b60e2a0c4b999dae288180fae743",
            "licenseInformation":"Creative Commons Attribution 4.0 Australia Licence, © Spatial Services 2021"
         }
        ]
      }
    ]
  }
}

export { sourcesConfig }
