/* This component creates an individual search result, used on the Explore page */

import React, { Component } from 'react';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { mapConfig } from '../config/mapConfig.js';
import { layerConfig } from '../config/layerConfig.js';
import { redirectToTarget } from '../modules/utils';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.22' })

class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.zoomToFeature = this.zoomToFeature.bind(this);
        this.clearHighlight = this.clearHighlight.bind(this);
        this.highlightFeature = this.highlightFeature.bind(this);
    }

    componentDidMount(){
        // console.log("search result mounted")
    }

    componentWillUnmount() {
        // console.log("search result will unmount")
    }

    zoomToFeature(evt) {
        // Fly the map to the extent of the selected feature
        this.props.view.goTo({
            center: [parseFloat(evt.currentTarget.dataset.longitude), parseFloat(evt.currentTarget.dataset.latitude)],
            zoom: Math.max(this.props.view.zoom, 12)
        }, {
            duration: 2000
        })
    }

    highlightFeature(evt) {
      let polygonLayer = this.props.map.layers.items.find(o => o.id === 'polygonLayer');
      let centroidLayer = this.props.map.layers.items.find(o => o.id === 'centroidLayer');
      if (polygonLayer){polygonLayer.opacity = 1;}
      if (centroidLayer) {centroidLayer.opacity = 0.25;}

      loadModules(["esri/Graphic", "esri/layers/GraphicsLayer"]).then(([Graphic, GraphicsLayer]) => {

        // Add the highlight centroids layer
        let highlightPointGraphicsLayer = this.props.view.map.layers.items.find(o => o.id==='highlightPointGraphicsLayer')
        if (!highlightPointGraphicsLayer) {
          highlightPointGraphicsLayer = new GraphicsLayer({
            id: 'highlightPointGraphicsLayer',
            title: "highlighted centroids",
            maxScale: mapConfig().pointScale,
            listMode: "hide",
            legendEnabled: false
          });
          this.props.view.map.add(highlightPointGraphicsLayer);
        } else {
          highlightPointGraphicsLayer.removeAll();
        }
        let highlightPointGraphic = new Graphic({
            geometry: {
              latitude: this.props.feature.geometry.centroid.latitude,
              longitude: this.props.feature.geometry.centroid.longitude,
              type: "point"
            },
            attributes: [],
            symbol:{
              type: "simple-marker",
              style: mapConfig().clcPoints.shape,
              color: mapConfig().clcPoints.color,
              size: mapConfig().clcPoints.size,
              outline: mapConfig().clcPoints.outline
            }
        });
        highlightPointGraphicsLayer.add(highlightPointGraphic);

        // Add the highlight polygons layer
        let highlightPolygonGraphicsLayer = this.props.view.map.layers.items.find(o => o.id==='highlightPolygonGraphicsLayer');
        if (!highlightPolygonGraphicsLayer){
          highlightPolygonGraphicsLayer = new GraphicsLayer({
            id: 'highlightPolygonGraphicsLayer',
            title: "highlighted polygons",
            minScale: mapConfig().pointScale,
            listMode: "hide"
          });
          this.props.view.map.add(highlightPolygonGraphicsLayer);
        } else {
          highlightPolygonGraphicsLayer.removeAll();
        }
        let polygonGraphic = new Graphic({
          geometry: {
            rings: this.props.feature.geometry.rings,
            spatialReference: this.props.feature.geometry.spatialReference,
            type: "polygon"
          },
          attributes: this.props.feature.attributes,
          symbol: mapConfig().clcPolygons.symbol
        });
        highlightPolygonGraphicsLayer.add(polygonGraphic);

        // Ensure the highlight layers are on top
        this.props.map.reorder(highlightPolygonGraphicsLayer,this.props.map.layers.length - 1);
        this.props.map.reorder(highlightPointGraphicsLayer,this.props.map.layers.length - 1);

      }, this)
        
    }

    clearHighlight() {
      console.log("clear highlight");
      let highlightPointGraphicsLayer = this.props.view.map.layers.items.find(o => o.id==='highlightPoingGraphicsLayer');
      if (highlightPointGraphicsLayer){
        highlightPointGraphicsLayer.removeAll();
      }
      let highlightPolygonGraphicsLayer = this.props.view.map.layers.items.find(o => o.id==='highlightPolygonGraphicsLayer');
      if (highlightPolygonGraphicsLayer){
        highlightPolygonGraphicsLayer.removeAll();
      }
      let polygonLayer = this.props.map.layers.items.find(o => o.id === 'polygonLayer');
      if (polygonLayer){polygonLayer.opacity = 1;}
      let centroidLayer = this.props.map.layers.items.find(o => o.id === 'centroidLayer');
      if (centroidLayer) {centroidLayer.opacity = 1;}
    }

    render() {

      let idField;
      this.props.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
      let reserveField;
      this.props.mode === 'public' ? reserveField = layerConfig().public.clcLayer.reserveField : reserveField = layerConfig().internal.clcLayer.reserveField;

        return (
            <Card
                onMouseOver={this.highlightFeature}
                onMouseOut={this.clearHighlight}
                className="searchResult"
            >
              <Card.Body className='pl-2 pr-2 pt-0 pb-0'>
                <Row>
                  <Col md={10} className="">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id='tooltip-right'>
                          Remove this Crown Estate area from the selection
                        </Tooltip>
                      }
                    >
                      <Button
                        data-id={this.props.feature.attributes[idField]}
                        variant="turquoiseText"
                        className="btnRemoveFromResults"
                        onClick={() => this.props.removeFromSearchResults(this.props.feature)}
                      >
                        <img className="mr-1 resultsIcon"
                            src="./img/clear_grey.svg"
                            onMouseOver={e => (e.currentTarget.src = "./img/clear.svg")}
                            onMouseOut={e => (e.currentTarget.src ="./img/clear_grey.svg")}
                            alt="clear"
                        />
                      </Button>
                    </OverlayTrigger>
                    <Card.Title className='ml-5 mt-2'>ID: {this.props.feature.attributes[idField]}</Card.Title>
                  </Col>
                  <Col md={1} className="resultCol">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id='tooltip-right'>
                          Create a Report on this Crown Estate area
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="no-background"
                        className="singleReportButton"
                        onClick={() => redirectToTarget('report', this.props)}
                      >
                        <img 
                          src="./img/selection_row_report.svg"
                          alt="report"
                        />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col md={1} className="resultCol">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id='tooltip-right'>
                          Zoom to this Crown Estate area
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="no-background"
                        className="btnZoomTo"
                        onClick={this.zoomToFeature}
                        data-latitude={this.props.feature.geometry.centroid.latitude}
                        data-longitude={this.props.feature.geometry.centroid.longitude}                        
                      >
                        <img
                          src="./img/selection_row_map.svg"
                          alt="map"
                        />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
               </Card.Body>
            </Card>
        );
    }
}

export {SearchResult}
