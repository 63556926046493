import { layerConfig } from "../config/layerConfig";

const getUrlParameter = (sParam) => {
  try{
      // retrieve URL parameters for use in the report
      var sURLVariables = window.location.search.substring(window.location.search.indexOf("?") + 1).split("&")
      var sParameterName;
      var i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
  } catch(err){
    console.error("There was a problem decoding the URL parameters", err)
  }
};

const exportCSVFile = (headers, items, fileTitle) => {
    console.log("Export CSV file")
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !== '') line += ','
    
                line += array[i][index];
            }
    
            str += line + '\r\n';
        }
    
        return str;
    }
}

const redirectToTarget = (page, props) => {
    console.log("redirect to target", page, props)
    try{

      // Set the map extent
      if (props.view && props.view.center) {
        let url = "/" + page + "?latitude=" + props.view.center.latitude + "&longitude=" + props.view.center.longitude;
        url += "&zoom=" + props.view.zoom;
        if (props.map && props.map.basemap) {
          // hack to get around default webmap basemap ID
          if (props.map.basemap.title === 'Topographic') {
            url += "&basemap=topo-vector";
          } else {
            url += "&basemap=" + props.map.basemap.id;
          }
        }

        // Append the queries, if applicable (and if the Add Remove selection has not been manually changed)
        if (!props.addRemoveChanged){
          if (props.statusType) {
            url += "&statusType=" + props.statusType;
          }
          if (props.classSubtype) {
            url += "&classSubtype=" + props.classSubtype;
          }

          if (props.nearbyLayer) {
            url += "&nearbyLayer=" + props.nearbyLayer;
          }

          if (props.nearbyDistance) {
            url += "&nearbyDistance=" + props.nearbyDistance;
          }
          if (props.nearbyType) {
            url += "&nearbyType=" + props.nearbyType;
          }
          if (props.nearbyTypeAlias) {
            url += "&nearbyTypeAlias=" + props.nearbyTypeAlias;
          }
          if (props.fer && props.fer !== 'any') {
            url += "&fer=" + props.fer;
          }
          if (props.areaMin && props.areaMin >0) {
            url += "&areaMin=" + props.areaMin;
          }
          if (props.areaMax && props.areaMax >0) {
            url += "&areaMax=" + props.areaMax;
          }
        }

        // Append the feature IDs
        if (props.features && props.features.length > 0) {
          let idField;
          props.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
          url += "&cadids="
          props.features.forEach(feature => {
            let id = feature.attributes[idField];
            url += id + ","
          })
          // drop the trailing comma
          url = url.slice(0, url.length - 1);
        } else if (props.feature && props.feature.attributes && props.feature.attributes.cadid){
            // for individual report links
            url += '&cadids=' + props.feature.attributes.cadid;
        }

        // Append any visible layers, including filters
        if (props.map && props.map.layers && props.map.layers.length > 0){
          console.log("Adding visible layers")
          let visibleLayers = [];
          let visibleLayersUrl = "&layers=";
          for (let y = 0; y < props.map.layers.length; y++){
            let layer = props.map.layers.items[y];
            if (layer.type === 'group' && layer.visible) {
              for (let z = 0; z < layer.layers.length; z++){
                let layer2 = layer.layers.items[z];
                if (layer2.visible && layer2.url !== null){
                  // Add the layer id, and any definition query, to the URL. This could be refactored to avoid duplication
                  let definition;
                  if (layer2.definitionExpression) {
                    definition = layer2.definitionExpression.replaceAll("<>", "+neq+").replaceAll("=", "+eq+");
                  }
                  if (visibleLayers.length === 0){
                    visibleLayersUrl += layer2.id;
                  } else  {
                    visibleLayersUrl += "," + layer2.id
                  }
                  if (definition){
                    visibleLayersUrl += ":" + definition
                  }
                  visibleLayers.push(layer2.id);
                }
                
              }
            } else if (layer.visible && layer.url !== null) {
              // Add the layer id, and any definition query, to the URL. This could be refactored to avoid duplication
              let definition;
              if (layer.definitionExpression) {
                definition = layer.definitionExpression.replaceAll("<>", "+neq+").replaceAll("=", "+eq+");
              }
              if (visibleLayers.length === 0){
                visibleLayersUrl += layer.id;
              } else  {
                visibleLayersUrl += "," + layer.id
              }
              if (definition){
                visibleLayersUrl += ":" + definition
              }
              visibleLayers.push(layer.id)
            }
          }
          if (visibleLayers.length > 0) {
            url += visibleLayersUrl;
          }
        }

        // Append the geocode
        console.log("add geocode to header")
        try{
          if (props.geocodeForReport) {
            let geocodeForReport = props.geocodeForReport
            if (geocodeForReport.suggestionType) {
              url += "&poiType=" + geocodeForReport.suggestionType;
              if (['watercourse','lga','lot'].indexOf(props.geocodeForReport.suggestionType) > -1) {
                url+= "&poiText=" + geocodeForReport.name;
                if (geocodeForReport.cadId) {
                  url+= "&poiCadid=" + geocodeForReport.cadId;
                }
                if (geocodeForReport.topoid) {
                  url+="&poiTopoid=" + geocodeForReport.topoid;
                }
              } else if (geocodeForReport.text) {
                if (geocodeForReport.text) {
                  url+= "&poiText=" + geocodeForReport.text
                }
              }
            }
            if (geocodeForReport.latitude && geocodeForReport.longitude) {
              url+= "&poiLat=" + geocodeForReport.latitude + "&poiLng=" + geocodeForReport.longitude;
            }


          }
        } catch (err) {
          console.log("There was a problem appending the geocode parameters", err)
        }

        // Open the report page in a new window
        console.log("opening URL", url)
        const win = window.open(url, "_blank");
        win.focus();
      } else {
        console.log("error","View is not available")
      }
    } catch(err){
      console.error("There was a problem opening the report URL", err)
    }
  }

  const addFilterTitle = (filter, layer) => {
    console.log("filter")

    filter = filter.replaceAll("=", " = ");
    layer.fields.forEach(field => {
      let fieldName = field.name;
      let alias = field.alias;
      if (filter.indexOf(fieldName) > -1){
        filter = filter.replaceAll(fieldName, alias);
      }
    });
    let title = layer.title;
    if (filter.indexOf(" and ") > -1){
      title += " (Filter - match all conditions: ";
      filter.split(" and ").forEach(clause => {
        title += clause + ", "
      })
      title = title.substr(0, title.length -2);
    } else if (filter.indexOf(" or ") > -1) {
      title += " (Filter - match any conditions: ";
      filter.split(" or ").forEach(clause => {
        title += clause + ", "
      })
      title = title.substr(0, title.length -2);
    } else {
      title += " (Filter: " + filter + ")"
    }
    return title;

  }

export { getUrlParameter, exportCSVFile, redirectToTarget, addFilterTitle }
