const searchConfig = () => {

    return {
 
        search:{
        poi: {
          id: "poi",
          url: "https://api.apps1.nsw.gov.au/planning/viewersf/V1/ePlanningApi/POI?p=",
          searchField: "poiName"
        },
        lot: {
          id: "lot",
          url: "https://api.apps1.nsw.gov.au/planning/viewersf/V1/ePlanningApi/lot?l=",
          searchField: "lot",
          uniqueIDField:"cadId"
        },
        address: {
          id: "address",
          url: "https://api.apps1.nsw.gov.au/planning/viewersf/V1/ePlanningApi/address?a=",
          searchField: "address"
        },
        lga: {
          id: "lga",
          url: "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Administrative_Boundaries/MapServer/1",
          searchField: "councilname",
          uniqueIDField:"cadid"
        },
        watercourse: {
          id: "watercourse",
          url: "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Hydrography/MapServer/6",
          searchField: "hydronamestring",
          uniqueIDField:"objectid"
        },
        boundary:{
          id:"boundary",
          url:"https://api.apps1.nsw.gov.au/planning/viewersf/V1/ePlanningApi/boundary?id="
        },
        addressdetail:{
          id:"addressdetail",
          url:"https://api.apps1.nsw.gov.au/planning/viewersf/V1/addressdetail?gurasid="
        }
      }
 
    }
  }
  
  export { searchConfig }
  