import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { mapConfig } from './config/mapConfig.js';
import { layerConfig } from './config/layerConfig.js';
import { getUrlParameter, addFilterTitle } from "./modules/utils";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
import Header  from "./components/Header";
import { nearbyConfig } from './config/nearbyConfig.js';
import { searchConfig } from "./config/searchConfig";
import Pluralize from 'pluralize';
import {loadExploreMenuLayers} from "./modules/exploreMenuLayers";
import MobileMessage from './components/MobileMessage';
setDefaultOptions({ version: '4.22' })

class Printout extends Component {
  constructor(props) {
    super(props);
    this.mapRef1 = React.createRef();
    this.state = {
      mode: 'internal',
      areaMin: null,
      areaMax: null,
      cadids: [],
      classSubtype: null,
      clcLayer: null,
      features: [],
      featureTables: [],
      fer: null,
      filters: {},
      nearbyDistance: null,
      nearbyLayer: null,
      nearbyType: null,
      nearbyTypeAlias: null,
      selectedGeocode: [],
      statusType: null,
      view: null,
      visibleLayers: [],
      visibleFeatures: []
    };

    this.handleMapLoad = this.handleMapLoad.bind(this);
    this.loadVisibleLayers = this.loadVisibleLayers.bind(this);
    this.setVisibleLayers = this.setVisibleLayers.bind(this);
    this.handleSelectedPolygons = this.handleSelectedPolygons.bind(this);
    this.addReportGeocode = this.addReportGeocode.bind(this);
    this.runReportNearbyQuery = this.runReportNearbyQuery.bind(this);
    this.addGeocodePolygons = this.addGeocodePolygons.bind(this);
    this.addGeocodePolylines = this.addGeocodePolylines.bind(this);

  }

  componentDidMount(){
    const { REACT_APP_ACTIVATE_MODE } = process.env;
    console.log("internal vs public:", REACT_APP_ACTIVATE_MODE)
    if (REACT_APP_ACTIVATE_MODE === 'public'){
      this.setState({mode: 'public'})
    }

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(['esri/Map', 'esri/WebMap', 'esri/views/MapView', "esri/core/watchUtils", "esri/widgets/Legend",
      "esri/layers/WMTSLayer", "esri/Basemap", "esri/layers/MapImageLayer", "esri/widgets/ScaleBar"], { css: true })
      .then(([Map, WebMap, MapView, watchUtils, Legend, WMTSLayer, Basemap, MapImageLayer, ScaleBar]) => {
          // Set the basemap from the URL parameter, or if that's absent/fails use satellite as the default
          let basemap = null;
          if (getUrlParameter("basemap")) {
            basemap = Basemap.fromId(getUrlParameter("basemap"))
          }
          if (basemap === null) {
            basemap = Basemap.fromId('satellite');
          }

          const { REACT_APP_ACTIVATE_MODE } = process.env;
          console.log("internal vs public:", REACT_APP_ACTIVATE_MODE)
          let assetMap;
          if (REACT_APP_ACTIVATE_MODE === 'public'){
            console.log("Web map ID is", mapConfig().public.webmapId)
            assetMap = new WebMap({
              portalItem: {
                  id: mapConfig().public.webmapId						
              }					
            });
          } else {
            console.log("Web map ID is", mapConfig().internal.webmapId);
            assetMap = new WebMap({
              portalItem: {
                id: mapConfig().internal.webmapId,
                portal: {
                  url: mapConfig().webMapBaseUrl
                }			  
              },
              basemap: basemap
            });
          }

          // If the lat/long/zoom are provided in the URL, use them when instantiating the map
          const lat = getUrlParameter("latitude");
          const lng = getUrlParameter("longitude");
          const zoom = getUrlParameter("zoom");
          this.setState({
            cadids: getUrlParameter("cadids") || []
          });
          if (getUrlParameter("statusType")) {
            this.setState({statusType: getUrlParameter("statusType")})
          }
          if (getUrlParameter("classSubtype")) {
            this.setState({classSubtype: getUrlParameter("classSubtype")})
          }
          if (getUrlParameter("nearbyDistance")) {
            this.setState({nearbyDistance: getUrlParameter("nearbyDistance")})
          }
          if (getUrlParameter("nearbyLayer")) {
            this.setState({nearbyLayer: getUrlParameter("nearbyLayer")})
          }
          if (getUrlParameter("nearbyType")) {
            this.setState({nearbyType: getUrlParameter("nearbyType")})
          }
          if (getUrlParameter("nearbyTypeAlias")) {
            this.setState({nearbyTypeAlias: getUrlParameter("nearbyTypeAlias")})
          }
          if (getUrlParameter("fer")) {
            this.setState({fer: getUrlParameter("fer")})
          }
          if (getUrlParameter("areaMin")) {
            this.setState({areaMin: parseFloat(getUrlParameter("areaMin"))})
          }
          if (getUrlParameter("areaMax")) {
            this.setState({areaMax: parseFloat(getUrlParameter("areaMax"))})
          }
          if (getUrlParameter("layers")) {
            let visibleLayers = getUrlParameter("layers");

            // Also set filters if any are found
            if (visibleLayers.indexOf(":") === -1){
              this.setState({visibleLayers})
            } else {
              let params = visibleLayers.split(",");
              let visibleLayers2;
              let filters = {}
              for (let x = 0; x < params.length; x++){
                let param = params[x];
                let layerName = param.substr(0, param.indexOf(":"));
                if (visibleLayers2){
                  visibleLayers2 += "," + layerName;
                } else {
                  visibleLayers2 = layerName;
                }
                let filter = param.substr(param.indexOf(":")  +1, param.length).replaceAll("+eq+", "=").replaceAll("+neq+", "<>");
                filters[layerName] = filter;
              }
              this.setState({filters, visibleLayers: visibleLayers2});              
            }
          }

          this.assetView = new MapView({
              container: this.mapRef1.current,
              map: assetMap,
              ui: { components: ["attribution"] }
          });
          loadExploreMenuLayers(this.assetView);

          let scaleBar = new ScaleBar({
            view: this.assetView,
            unit: "metric"
          });
          this.assetView.ui.add(scaleBar, {
            position: "bottom-right"
          });


          if (lat && lng && zoom) {
            this.assetView.center = [parseFloat(lng), parseFloat(lat)];
            this.assetView.zoom = parseInt(zoom);

          } else {
            this.assetView.extent = mapConfig().extent;
          }

          new Legend({
            view: this.assetView,
            container: "legend"
          });

          watchUtils.whenTrue(this.assetView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg').style.display = 'block';
            } catch(error){}
          });

          watchUtils.whenFalse(this.assetView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg').style.display = 'none';
            } catch(error){}
          });

          this.assetView.when(this.handleMapLoad);

      });

  }

  async handleMapLoad(view) {
    console.log("handle map load");
    this.loadVisibleLayers();

    const [QueryTask, Query, FeatureLayer] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query", "esri/layers/FeatureLayer"]);

    console.log("create report layers")
    this.selectedCLClayer = new FeatureLayer(mapConfig().reportSelectedPolygons);
    // this.nonSelectedCLClayer = new FeatureLayer(mapConfig().reportNonSelectedPolygons);

    // Query for the selected Crown polygons
    let url;
    this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
    let queryTask = new QueryTask(url)
    let query = new Query();
    query.returnGeometry = true;
    query.outFields = ['*'];
    let idField;
    this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
    if (this.state.cadids && this.state.cadids.length > 0){
      query.where = idField + " in (" + this.state.cadids.toLocaleString() + ")";
      queryTask.execute(query).then(this.handleSelectedPolygons).catch(this.promiseRejected);
    }

    // add the geocode features if applicable
    if (getUrlParameter("poiText")) {
      this.addReportGeocode();
    }

    // Run the nearby query
    if (this.state.nearbyTypeAlias){
      this.runReportNearbyQuery()
    }

    // Restore scrolling
    document.body.style.overflowY = 'auto';

  }

  async handleSelectedPolygons(response) {
    // console.log("handle selected polygons", response.features)
    this.setState({features: response.features});

    // Add the features to the map
    if (response.features && response.features.length > 0) {
      const [Graphic] = await loadModules(["esri/Graphic"]);
      let polygonGraphics = [];
      response.features.forEach(feature => {
        let polygonGraphic = new Graphic({
          geometry: {
            rings: feature.geometry.rings,
            spatialReference: feature.geometry.spatialReference,
            type: "polygon"
          },
          attributes: feature.attributes
        });
        polygonGraphics.push(polygonGraphic);
      });

      const addEditPolygons = {
        addFeatures: polygonGraphics
      }

      this.selectedCLClayer.applyEdits(addEditPolygons);
      this.assetView.map.add(this.selectedCLClayer);

    }
  }

  promiseRejected(error) {
    console.error("promise rejected", error.message)
  }

  loadVisibleLayers(){
    this.assetView.map.allLayers.forEach(lyr => this.setVisibleLayers(lyr))
  }

  setVisibleLayers(lyr){
    console.log("running set visible layers", lyr.title)
    try{
      if (lyr.type === 'group') {
          lyr.visible = true;
          return;    
      } else if (lyr.type !== 'feature' && lyr.type !== 'map-image') {
        return;
      }

      lyr.visible = false;
      if (this.state.visibleLayers.length > 0) {
        lyr.visible = (this.state.visibleLayers.split(",").indexOf(lyr.id) > -1);
      }
      

      // If this layer has a filter, apply it here
      if (lyr.visible && this.state.filters[lyr.id]) {
        let filter = this.state.filters[lyr.id];
        lyr.definitionExpression = filter;
        
        // We need to query the layer to get access to its fields. NFI why....
        let query = {
          geometry: this.assetView.extent.expand(5),
          returnGeometry: false,
          outFields: ['*']
        }
        lyr.queryFeatures(query).then(results => {
          try{
            let layer = results.features[0].layer;
            layer.title = addFilterTitle(filter, layer);
          } catch(err){
            console.log("There was a problem setting the filter title")
          }
        });        
        
      }
    } catch(err) {
      console.log("problem with layer visibility", err.message)
    }
  }

  componentWillUnmount() {
    console.log("Report page will unmount")
    if (this.assetView) {
      // destroy the map view
      this.assetView.destroy();
    }

  }

  exportReport = () =>  {
    window.print();
  }

  /* These functions are duplicated from the report code. There are slight differences which mean
  it's not simple to refactor into a single instance */
  addReportGeocode(){
    try{
      loadModules(["esri/layers/FeatureLayer"], { css: true })
        .then(([FeatureLayer]) => {
          let poiType = getUrlParameter("poiType");
          let poiText = getUrlParameter("poiText") || "geocoded feature";
          let poiCadid = getUrlParameter("poiCadid");
          let poiTopoid = getUrlParameter("poiTopoid");
          let latitude = parseFloat(getUrlParameter("poiLat"));
          let longitude = parseFloat(getUrlParameter("poiLng"));

          if (latitude && longitude){
            let geocodePointsLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [
               {
                 geometry: {
                   type: "point",
                   x: longitude,
                   y: latitude
                 },
                 attributes: {
                   ObjectID: 1,
                   name: poiText
                 }
               }
              ],
              geometryType: "point",
              renderer: mapConfig().geocodePoints
            });
            this.assetView.map.add(geocodePointsLayer1);
            
          } else if (['lga','lot'].indexOf(poiType) > -1) {
            this.geocodePolygonsLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polygon",
              renderer: mapConfig().geocodePolygons
            });
            this.assetView.map.add(this.geocodePolygonsLayer1);

            if (poiType === "lga") {
              this.loadLgaPolygons(poiText)
            } else if (poiType === 'lot' && poiCadid) {
              this.loadLotPolygons(poiText, poiCadid)              
            }

          } else if (['watercourse'].indexOf(poiType) > -1) {
            this.geocodePolylinesLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polyline",
              renderer: mapConfig().geocodePolylines
            });
            this.assetView.map.add(this.geocodePolylinesLayer1);
            
            this.loadWatercourseLines(poiText, poiTopoid)

          }

      })
    } catch (err) {
      console.log("There was a problem adding the geocode feature")
    }
    
  }

  runReportNearbyQuery(){
    if (this.state.nearbyType === null || this.state.nearbyTypeAlias === null || this.state.nearbyDistance === null || this.state.nearbyDistance === null){
      console.log("There was a problem running the nearby query on the report")
      return;
    }

    loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"], { css: true })
      .then(([QueryTask, Query]) => {

        let where = nearbyConfig()[this.state.nearbyLayer].typeField + "='" + this.state.nearbyType + "'";
        let queryTask = new QueryTask(nearbyConfig()[this.state.nearbyLayer].url);
        let query = new Query();
        query.where = where;
        query.returnGeometry = true;
        query.geometry = this.assetView.extent.extent.expand(5);
        // query.maxAllowableOffset = this.setMaxOffset();

        queryTask.execute(query).then(this.handleReportNearbyQueryResponse).catch(this.handleQueryFail);

    })
  }

  handleReportNearbyQueryResponse = (response) => {
    console.log("Handle nearby query response on the report", response)

    loadModules(["esri/layers/FeatureLayer", "esri/Graphic"], { css: true })
      .then(([FeatureLayer, Graphic]) => {

        let nearbyGeometryType = nearbyConfig()[this.state.nearbyLayer].geometryType;
        let nearbyLayer;
        if (nearbyGeometryType === 'point'){
          console.log("nearby layer is point")
          nearbyLayer = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "point",
            renderer: mapConfig().nearbyPoints
          });

        } else if (nearbyGeometryType === 'polygon') {
          console.log("nearby layer is polygon")
          nearbyLayer = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "polygon",
            renderer: mapConfig().nearbyPolygons
          });
        }

        let addEdits = {
          addFeatures: []
        };

        response.features.forEach(spatialFeature => {
          let nearbyGraphic = new Graphic({
            geometry: spatialFeature.geometry,
            attributes: spatialFeature.attributes,
          })
          addEdits.addFeatures.push(nearbyGraphic)
        });
        nearbyLayer.applyEdits(addEdits);
        this.assetView.map.add(nearbyLayer);

    })
  }

  loadWatercourseLines = async (poiText, poiTopoid) => {
    const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);

    console.log("load watercourse lines")
    let watercourseURL = searchConfig().search.watercourse.url;
    let queryTask_watercourse = new QueryTask(watercourseURL);
    let query_watercourse = new Query();
    query_watercourse.where = searchConfig().search.watercourse.searchField+"='" + poiText + "'";
    query_watercourse.returnGeometry = true;
    query_watercourse.outFields = ["*"];

    queryTask_watercourse.execute(query_watercourse).then(this.addGeocodePolylines).catch(this.handleQueryFail);

  }

  loadLgaPolygons = async (poiText) => {
    const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);

    let lgaURL = searchConfig().search.lga.url;
    let queryTask_lga = new QueryTask(lgaURL);
    let query_lga = new Query();
    query_lga.where = searchConfig().search.lga.searchField+"='" + poiText + "'";
    query_lga.returnGeometry = true;
    query_lga.outFields = ["*"];

    queryTask_lga.execute(query_lga).then(this.addGeocodePolygons).catch(this.handleQueryFail);

  }

  loadLotPolygons = async (poiText, poiCadid) => {
    const [Polygon,webMercatorUtils, Graphic ] = await loadModules(["esri/geometry/Polygon", "esri/geometry/support/webMercatorUtils", "esri/Graphic"]);
    console.log("Need to retrieve lot boundary", poiText, poiCadid);
    let boundaryUrl =  searchConfig().search.boundary.url + poiCadid + "&Type=lot";
    let boundaryResp = await fetch(boundaryUrl);
    const boundaryResult = await boundaryResp.json();
    if (boundaryResult) {

      try {
        let polygonGeom = new Polygon(boundaryResult[0].geometry);

        if(polygonGeom.spatialReference.isWebMercator){
          polygonGeom= webMercatorUtils.webMercatorToGeographic(polygonGeom);
        }

        let addEdits = {
          addFeatures: []
        };

        let lotGraphic = new Graphic({
          geometry: polygonGeom
        })
        addEdits.addFeatures.push(lotGraphic)
        this.geocodePolygonsLayer1.applyEdits(addEdits);
        this.geocodePolygonsLayer2.applyEdits(addEdits);  
        

      } catch (err) {
        console.log("error","There was a problem fetching the geocode for lot/DP")
      }
    }
  }

  addGeocodePolygons = (results) => {
    console.log("add geocode features to", this.geocodePolygonsLayer1)
    let addEdits = {
      addFeatures: results.features
    };
    this.geocodePolygonsLayer1.applyEdits(addEdits);
  }

  addGeocodePolylines = (results) => {
    console.log("add geocode polylines to", this.geocodePolylinesLayer1)
    let addEdits = {
      addFeatures: results.features
    };
    this.geocodePolylinesLayer1.applyEdits(addEdits);
   }

  handleQueryFail(err) {
    console.log("error","There was a problem running the query", err)
  }

  render() {
    var today = new Date(), date = today.getDate() + "/" + (today.getMonth() + 1 + "/" + today.getFullYear());
    

    return (
      <React.Fragment>
          <Header
            page = "printout"
            isLoggedIn={this.props.isLoggedIn}
            logout = {this.props.logout}
            resetSettings = {this.props.resetSettings}
            toggleSourcesModal={this.props.toggleSourcesModal}
          />

          {/* show a message if the screen is smaller than md, otherwise show the other content */}
          <MobileMessage/>

          <Container id="printoutContainer" className="d-none d-md-block">
            <Row className="mt-5">
              <h1>Snapshot<br/></h1>
            </Row>
            <Row className="mt-1">
              <Col sm={1} className="mt-2">
                <span className="subheading ml-n3">{date}</span>
              </Col>
              <Col xs={4} sm={4} lg={2}>
                <Button id="btnExportReport" className="no-print" variant="turquoiseText" onClick={this.exportReport}>
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    className="mr-2"
                  />
                  Export as PDF
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col id="printoutMap" sm={8}>
                <div
                  className="base-container"
                  ref={this.mapRef1}
                />
                <img id='loadingImg' className = "loadingImg" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'}/>
              </Col>
              <Col sm={4}>
                <h2>Legend</h2>
                <div id='legend'></div>
              </Col>
            </Row>

            <Row className='mt-5'>
              <Col xs={8}>
                <p><b>Disclaimer</b></p>
                <p>The information contained in this map has been provided in good faith. Whilst all effort has been made to ensure the accuracy and completeness of this information the data providers take no responsibility for errors or omissions nor any loss or damage that may result from the use of this information.</p>
              </Col>
            </Row>

          </Container>

        
      </React.Fragment>
    );
  }
}

export default Printout;
