import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ClcModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

componentDidMount(){
    //console.log("Modal mounted");
  }

  componentWillUnmount() {
    //console.log("Modal will unmount")
  }

  render() {

    return (
      <React.Fragment>
        <Modal
          show={this.props.showModal}
          onHide={this.props.closeModal}
          centered
          className="text-center"
        >
          <Modal.Body>{this.props.modalContent}</Modal.Body>
          <Modal.Footer className="ml-auto mr-auto">
            <Button id="btnRefineSearch" className='clcButton' variant="secondary" onClick={this.props.closeModal}>
              Refine search
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ClcModal;

