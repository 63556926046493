import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Nav, Button } from 'react-bootstrap';
import Pdf from "./Activate_UserGuide.pdf";
import Pdf_internal from "./Activate_UserGuide_internal.pdf";

class Header extends Component {

  render() {
    let logoutButton;
    if (this.props.isLoggedIn){
      logoutButton = (
        <li className="nav-item">
          <Button
            className="headerButton no-print"
            onClick={this.props.logout}
          >Log out</Button>
        </li>
      )
    }

    let links;
    if (!['report', 'printout'].includes(this.props.page)){
      links = (
        <React.Fragment>
          <li className="nav-item mt-2">
            <Link className="" to="/#" onClick={() => this.props.resetSettings(true)}>
              <p className="headerText">Home</p>
            </Link>
          </li>
          <li className="nav-item mt-2">
            {this.props.mode === 'internal' ?
              <Link to={{ pathname: Pdf_internal }} target="_blank">
                <p className="headerText">User Guide</p>
              </Link>
            :
              <Link to={{ pathname: Pdf }} target="_blank">
                <p className="headerText">User Guide</p>
              </Link>
            }
          </li>
          <li className="nav-item mt-2">
            <Link className="" to="#" onClick={this.props.toggleSourcesModal}>
              <p className="headerText">Data Sources</p>
            </Link>
          </li>
          <li className="nav-item mt-2">
            <Link to={{ pathname: "mailto:cl.enquiries@crownland.nsw.gov.au?subject=Activate Feedback"}} target="_blank">
              <p className="headerText">Give Feedback</p>
            </Link>
          </li>
        </React.Fragment>
      )
    }

    // The hidden parameters in the header are a band-aid attempt to force the report
    // to contain the layers and aliases, which sometimes get omitted
    return (
      <Nav id="header" className="navbar">

        <img id="logo" alt="logo" src={process.env.PUBLIC_URL + '/logo.png'} />

        <a id="clcHeader" className="navbar-brand" href="https://www.industry.nsw.gov.au/lands/what-we-do/crown-land-commissioner" target="_blank" rel="noreferrer"></a>

        <Link id="activateTitleWrapper" className="navbar-brand" to="/#" onClick={() => this.props.resetSettings(true)}>
          <h1 id="activateTitle" className="headerText">Activate</h1>
        </Link>

        <ul id="navbarRight" className="nav navbar-nav flex-row justify-content-start flex-nowrap d-none d-md-flex">          
          {links}
          {logoutButton}
        </ul>
      </Nav>
    )
  }
}

export default Header ;
