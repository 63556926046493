// These options feed the Nearby search dropdown. The XXXlayer value must match
// an equivalent entry in the layerConfig file, where its URL is specified. To
// add a new layer, add a new <option> in the `nearbyLayer` select box in
// the FindForm.js file then add its values below

const nearbyConfig = () => {
  return {
    poiLayer: {
      id: "poi",
      geometryType: "point",
      alias: "Points of interest",
      url: "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_POI/MapServer/0",
      typeField: "poiType",
      values: [
            {value:'Airport', alias:'Airport'},
            {value:'Ambulance Station', alias:'Ambulance Station'},
            {value:'Athletics Track', alias:'Athletics Track'},
            {value:'BMX Track', alias:'BMX Track'},
            {value:'Bus Interchange', alias:'Bus Interchange'},
            {value:'Camping Ground', alias:'Camping Ground'},
            {value:'Cemetery', alias:'Cemetery'},
            {value:'Child Care Centre', alias:'Child Care Centre'},
            {value:'Childrens Hospital', alias:'Childrens Hospital'},
            {value:'Community Facility', alias:'Community Facility'},
            {value:'Community Home', alias:'Community Home'},
            {value:'Community Medical Centre', alias:'Community Medical Centre'},
            {value:'Cycling Track', alias:'Cycling Track'},
            {value:'Dog Track', alias:'Dog Track'},
            {value:'Education Facility', alias:'Education Facility'},
            {value:'Fire Station', alias:'Fire Station'},
            {value:'Firestation - Bush', alias:'Firestation - Bush'},
            {value:'General Hospital', alias:'General Hospital'},
            {value:'Golf Course', alias:'Golf Course'},
            {value:'Helipad', alias:'Helipad'},
            {value:'Marina', alias:'Marina'},
            {value:'Motor Racing Track', alias:'Motor Racing Track'},
            {value:'Nursing Home', alias:'Nursing Home'},
            {value:'Place Of Worship', alias:'Place Of Worship'},
            {value:'Police Station', alias:'Police Station'},
            {value:'Post Office', alias:'Post Office'},
            {value:'Power Station - Fuel Driven', alias:'Power Station - Fuel Driven'},
            {value:'Power Station - Hydro', alias:'Power Station - Hydro'},
            {value:'Power Station - Solar', alias:'Power Station - Solar'},
            {value:'Power Station - Wind', alias:'Power Station - Wind'},
            {value:'Racecourse', alias:'Racecourse'},
            {value:'Railway Station', alias:'Railway Station'},
            {value:'Retirement Village', alias:'Retirement Village'},
            {value:'Roadside Rest Area', alias:'Roadside Rest Area'},
            {value:'Rubbish Depot', alias:'Rubbish Depot'},
            {value:'SES Facility', alias:'SES Facility'},
            {value:'Shopping Centre', alias:'Shopping Centre'},
            {value:'Showground', alias:'Showground'},
            {value:'Solar Power Station', alias:'Solar Power Station'},
            {value:'Sports Centre', alias:'Sports Centre'},
            {value:'Sports Court', alias:'Sports Court'},
            {value:'Sports Field', alias:'Sports Field'},
            {value:'Tourist Attraction', alias:'Tourist Attraction'},
            {value:'Tourist Information Centre', alias:'Tourist Information Centre'},
            {value:'Tourist Park / Home Village', alias:'Tourist Park / Home Village'},
            {value:'Training Track', alias:'Training Track'},
            {value:'Transmission Station', alias:'Transmission Station'},
            {value:'Transport Interchange', alias:'Transport Interchange'},
            {value:'Trotting Track', alias:'Trotting Track'},
            {value:'Wharf', alias:'Wharf'},
            {value:'Wind Power Station', alias:'Wind Power Station'},
            {value:'Zoo', alias:'Zoo'}
          ]
      },
    npwsLayer: {
      id: "npws",
      geometryType: "polygon",
      alias: "National Parks",
      url: "https://mapprod2.environment.nsw.gov.au/arcgis/rest/services/Common/Admin_3857/MapServer/19",
      typeField: "TYPE",
      values: [
            {value:'ABORIGINAL AREA', alias:'Aboriginal Area'},
            {value:'CCA ZONE 1 NATIONAL PARK', alias:'CCA Zone 1 National Park'},
            {value:'CCA ZONE 2 ABORIGINAL AREA', alias:'CCA Zone 2 Aboriginal Area'},
            {value:'CCA ZONE 3 STATE CONSERVATION AREA', alias:'CCA Zone 3 State Conservation Area'},
            {value:'HISTORIC SITE', alias:'Historic Site'},
            {value:'KARST CONSERVATION RESERVE', alias:'Karst Conservation Reserve'},
            {value:'NATIONAL PARK', alias:'National Park'},
            {value:'NATURE RESERVE', alias:'Nature Reserve'},
            {value:'REGIONAL PARK', alias:'Regional Park'},
            {value:'STATE CONSERVATION AREA', alias:'State Conservation Area'}
          ]
      }
  }
}

export { nearbyConfig }
