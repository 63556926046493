import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getUrlParameter, exportCSVFile, addFilterTitle } from "./modules/utils";
import { mapConfig } from './config/mapConfig.js';
import { layerConfig } from './config/layerConfig.js';
import { nearbyConfig } from './config/nearbyConfig.js';
import { searchConfig } from "./config/searchConfig";
import Header  from "./components/Header";
import Pluralize from 'pluralize';
import './Report.css';
import {loadExploreMenuLayers} from "./modules/exploreMenuLayers";
import MobileMessage from './components/MobileMessage';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.22' })

class Report extends Component {
  constructor(props) {
    super(props);
    this.mapRef1 = React.createRef();
    this.mapRef2 = React.createRef();
    this.state = {
      mode: 'internal',
      areaMin: null,
      areaMax: null,
      cadids: [],
      classSubtype: null,
      clcLayer: null,
      features: [],
      featureTables: [],
      fer: null,
      filters: {},
      nearbyDistance: null,
      nearbyLayer: null,
      nearbyType: null,
      nearbyTypeAlias: null,
      selectedGeocode: [],
      statusType: null,
      view: null,
      visibleLayers: [],
      visibleFeatures: []
    };

    this.handleMapLoad = this.handleMapLoad.bind(this);
    this.handleSelectedPolygons = this.handleSelectedPolygons.bind(this);
    this.handleNonSelectedPolygons = this.handleNonSelectedPolygons.bind(this);
    this.loadVisibleLayers = this.loadVisibleLayers.bind(this);
    this.setVisibleLayers = this.setVisibleLayers.bind(this);
    this.displayVisibleLayerTable = this.displayVisibleLayerTable.bind(this);
    this.runReportNearbyQuery = this.runReportNearbyQuery.bind(this);
    this.addReportGeocode = this.addReportGeocode.bind(this);
    this.addGeocodePolygons = this.addGeocodePolygons.bind(this);
    this.addGeocodePolylines = this.addGeocodePolylines.bind(this);
    this.createCLCtable = this.createCLCtable.bind(this);
    this.exportToCSVwrapper = this.exportToCSVwrapper.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.exportAllTablesToCSV = this.exportAllTablesToCSV.bind(this);

  }

  componentDidMount(){

    const { REACT_APP_ACTIVATE_MODE } = process.env;
    console.log("internal vs public:", REACT_APP_ACTIVATE_MODE)
    if (REACT_APP_ACTIVATE_MODE === 'public'){
      this.setState({mode: 'public'})
    }

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(['esri/Map', 'esri/WebMap', 'esri/views/MapView', "esri/core/watchUtils", "esri/widgets/Legend", "esri/layers/WMTSLayer", "esri/Basemap", "esri/layers/MapImageLayer"], { css: true })
      .then(([Map, WebMap, MapView, watchUtils, Legend, WMTSLayer, Basemap, MapImageLayer]) => {
          // Set the basemap from the URL parameter, or if that's absent/fails use satellite as the default
          let basemap = null;
          if (getUrlParameter("basemap")) {
            basemap = Basemap.fromId(getUrlParameter("basemap"))
          }
          if (basemap === null) {
            basemap = Basemap.fromId('satellite');
          }

          let assetMap = new Map({
            basemap: basemap
          });

          const { REACT_APP_ACTIVATE_MODE } = process.env;
          console.log("internal vs public:", REACT_APP_ACTIVATE_MODE)
          let contextMap;
          if (REACT_APP_ACTIVATE_MODE === 'public'){
            contextMap = new WebMap({
              portalItem: {
                  id: mapConfig().public.webmapId						
              }					
            });
          } else {
            contextMap = new WebMap({
              portalItem: {
                id: mapConfig().internal.webmapId,
                portal: {
                  url: mapConfig().webMapBaseUrl
                }			  
              },
              basemap: basemap
            });

            // Manually add the nearmap and image layers if applicable
            let layers = getUrlParameter('layers');
            if (layers) {
              layers = layers.split(",");
              if (layers.includes("nearmap")) {
                let nearmapLayer1 = new WMTSLayer(layerConfig().nearmap);
                nearmapLayer1.visible = true;
                assetMap.add(nearmapLayer1);
                let nearmapLayer2 = new WMTSLayer(layerConfig().nearmap);
                nearmapLayer2.visible = true;
                contextMap.add(nearmapLayer2);
              }

              let imageLayers = layerConfig().imageLayers;
              imageLayers.forEach(imageLayerDef => {
                if (layers.includes(imageLayerDef.id)) {
                  let imageLayer1 = new MapImageLayer(imageLayerDef);
                  assetMap.add(imageLayer1);
                  let imageLayer2 = new MapImageLayer(imageLayerDef);
                  contextMap.add(imageLayer2);
                }
              });
            }
          }

          // If the lat/long/zoom are provided in the URL, use them when instantiating the map
          const lat = getUrlParameter("latitude");
          const lng = getUrlParameter("longitude");
          const zoom = getUrlParameter("zoom");
          this.setState({
            cadids: getUrlParameter("cadids") || []
          });
          if (getUrlParameter("statusType")) {
            this.setState({statusType: getUrlParameter("statusType")})
          }
          if (getUrlParameter("classSubtype")) {
            this.setState({classSubtype: getUrlParameter("classSubtype")})
          }
          if (getUrlParameter("nearbyDistance")) {
            this.setState({nearbyDistance: getUrlParameter("nearbyDistance")})
          }
          if (getUrlParameter("nearbyLayer")) {
            this.setState({nearbyLayer: getUrlParameter("nearbyLayer")})
          }
          if (getUrlParameter("nearbyType")) {
            this.setState({nearbyType: getUrlParameter("nearbyType")})
          }
          if (getUrlParameter("nearbyTypeAlias")) {
            this.setState({nearbyTypeAlias: getUrlParameter("nearbyTypeAlias")})
          }
          if (getUrlParameter("fer")) {
            this.setState({fer: getUrlParameter("fer")})
          }
          if (getUrlParameter("areaMin")) {
            this.setState({areaMin: parseFloat(getUrlParameter("areaMin"))})
          }
          if (getUrlParameter("areaMax")) {
            this.setState({areaMax: parseFloat(getUrlParameter("areaMax"))})
          }
          if (getUrlParameter("layers")) {
            let visibleLayers = getUrlParameter("layers");

            // Also set filters if any are found
            if (visibleLayers.indexOf(":") === -1){
              this.setState({visibleLayers})
            } else {
              let params = visibleLayers.split(",");
              let visibleLayers2;
              let filters = {}
              for (let x = 0; x < params.length; x++){
                let param = params[x];
                let layerName = param.substr(0, param.indexOf(":"));
                if (visibleLayers2){
                  visibleLayers2 += "," + layerName;
                } else {
                  visibleLayers2 = layerName;
                }
                let filter = param.substr(param.indexOf(":")  +1, param.length).replaceAll("+eq+", "=").replaceAll("+neq+", "<>");
                filters[layerName] = filter;
              }
              this.setState({filters, visibleLayers: visibleLayers2});              
            }
          }

          this.assetView = new MapView({
              container: this.mapRef1.current,
              map: assetMap,
              ui: { components: ["attribution"] }
          });

          this.contextView = new MapView({
              container: this.mapRef2.current,
              map: contextMap,
              ui: { components: ["attribution"] }
          });
          loadExploreMenuLayers(this.contextView);

          if (lat && lng && zoom) {
            this.assetView.center = [parseFloat(lng), parseFloat(lat)];
            this.assetView.zoom = parseInt(zoom);
            this.contextView.center = [lng, lat];
            this.contextView.zoom = zoom;
          } else {
            this.assetView.extent = mapConfig().extent;
            this.contextView.extent = mapConfig().extent;
          }

          new Legend({
            view: this.assetView,
            container: "legend"
          });

          new Legend({
            view: this.contextView,
            container: "legend2"
          });

          // Show/hide the loading indicator
          watchUtils.whenTrue(this.assetView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg').style.display = 'block';
            } catch(error){}
          });

          watchUtils.whenFalse(this.assetView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg').style.display = 'none';
            } catch(error){}
          });

          watchUtils.whenTrue(this.contextView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg2').style.display = 'block';
            } catch(error){}
          });

          watchUtils.whenFalse(this.contextView, "updating", function(evt) {
            try{
              document.getElementById('loadingImg2').style.display = 'none';
            } catch(error){}
          });

          // Since the top map is just a Map it will load first. So wait for the slower WebMap
          // to load, then add layers to both maps. Revisit this if it becomes an issue
          this.contextView.when(this.handleMapLoad);

      });

  }

  componentWillUnmount() {
    console.log("Report page will unmount")
    if (this.assetView) {
      // destroy the map view
      this.assetView.destroy();
    }
    if (this.contextView) {
      // destroy the map view
      this.contextView.destroy();
    }
  }

  addReportGeocode(){
    try{
      loadModules(["esri/layers/FeatureLayer"], { css: true })
        .then(([FeatureLayer]) => {
          let poiType = getUrlParameter("poiType");
          let poiText = getUrlParameter("poiText") || "geocoded feature";
          let poiCadid = getUrlParameter("poiCadid");
          let poiTopoid = getUrlParameter("poiTopoid");
          let latitude = parseFloat(getUrlParameter("poiLat"));
          let longitude = parseFloat(getUrlParameter("poiLng"));

          if (latitude && longitude){
            let geocodePointsLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [
               {
                 geometry: {
                   type: "point",
                   x: longitude,
                   y: latitude
                 },
                 attributes: {
                   ObjectID: 1,
                   name: poiText
                 }
               }
              ],
              geometryType: "point",
              renderer: mapConfig().geocodePoints
            });
            this.assetView.map.add(geocodePointsLayer1);
            let geocodePointsLayer2 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [
              {
                 geometry: {
                   type: "point",
                   x: longitude,
                   y: latitude
                 },
                 attributes: {
                   ObjectID: 1,
                   name: poiText
                 }
               }
              ],
              geometryType: "point",
              renderer: mapConfig().geocodePoints
            });
            this.contextView.map.add(geocodePointsLayer2);
          } else if (['lga','lot'].indexOf(poiType) > -1) {
            this.geocodePolygonsLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polygon",
              renderer: mapConfig().geocodePolygons
            });
            this.assetView.map.add(this.geocodePolygonsLayer1);
            this.geocodePolygonsLayer2 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polygon",
              renderer: mapConfig().geocodePolygons
            });
            this.contextView.map.add(this.geocodePolygonsLayer2);

            if (poiType === "lga") {
              this.loadLgaPolygons(poiText)
            } else if (poiType === 'lot' && poiCadid) {
              this.loadLotPolygons(poiText, poiCadid)              
            }

          } else if (['watercourse'].indexOf(poiType) > -1) {
            this.geocodePolylinesLayer1 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polyline",
              renderer: mapConfig().geocodePolylines
            });
            this.assetView.map.add(this.geocodePolylinesLayer1);
            this.geocodePolylinesLayer2 = new FeatureLayer({
              title: poiText,
              objectIdField: "ObjectID",
              fields: [
                {
                  name: "ObjectID",
                  alias: "ObjectID",
                  type: "oid"
               }
              ],
              spatialReference: { wkid: 4326 },
              source: [],
              geometryType: "polyline",
              renderer: mapConfig().geocodePolylines
            });
            this.contextView.map.add(this.geocodePolylinesLayer2);
            this.loadWatercourseLines(poiText, poiTopoid)

          }

      })
    } catch (err) {
      console.log("There was a problem adding the geocode feature")
    }
    
  }

  loadWatercourseLines = async (poiText, poiTopoid) => {
    const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);

    console.log("load watercourse lines")
    let watercourseURL = searchConfig().search.watercourse.url;
    let queryTask_watercourse = new QueryTask(watercourseURL);
    let query_watercourse = new Query();
    query_watercourse.where = searchConfig().search.watercourse.searchField+"='" + poiText + "'";
    query_watercourse.returnGeometry = true;
    query_watercourse.outFields = ["*"];

    queryTask_watercourse.execute(query_watercourse).then(this.addGeocodePolylines).catch(this.handleQueryFail);

  }

  loadLgaPolygons = async (poiText) => {
    const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);

    let lgaURL = searchConfig().search.lga.url;
    let queryTask_lga = new QueryTask(lgaURL);
    let query_lga = new Query();
    query_lga.where = searchConfig().search.lga.searchField+"='" + poiText + "'";
    query_lga.returnGeometry = true;
    query_lga.outFields = ["*"];

    queryTask_lga.execute(query_lga).then(this.addGeocodePolygons).catch(this.handleQueryFail);

  }

  loadLotPolygons = async (poiText, poiCadid) => {
    const [Polygon,webMercatorUtils, Graphic ] = await loadModules(["esri/geometry/Polygon", "esri/geometry/support/webMercatorUtils", "esri/Graphic"]);
    console.log("Need to retrieve lot boundary", poiText, poiCadid);
    let boundaryUrl =  searchConfig().search.boundary.url + poiCadid + "&Type=lot";
    let boundaryResp = await fetch(boundaryUrl);
    const boundaryResult = await boundaryResp.json();
    if (boundaryResult) {

      try {
        let polygonGeom = new Polygon(boundaryResult[0].geometry);

        if(polygonGeom.spatialReference.isWebMercator){
          polygonGeom= webMercatorUtils.webMercatorToGeographic(polygonGeom);
        }

        let addEdits = {
          addFeatures: []
        };

        let lotGraphic = new Graphic({
          geometry: polygonGeom
        })
        addEdits.addFeatures.push(lotGraphic)
        this.geocodePolygonsLayer1.applyEdits(addEdits);
        this.geocodePolygonsLayer2.applyEdits(addEdits);  
        

      } catch (err) {
        console.log("error","There was a problem fetching the geocode for lot/DP")
      }
    }
  }

  addGeocodePolygons = (results) => {
    console.log("add geocode features to", this.geocodePolygonsLayer1)
    let addEdits = {
      addFeatures: results.features
    };
    this.geocodePolygonsLayer1.applyEdits(addEdits);
    this.geocodePolygonsLayer2.applyEdits(addEdits);
  }

  addGeocodePolylines = (results) => {
    console.log("add geocode polylines to", this.geocodePolylinesLayer1)
    let addEdits = {
      addFeatures: results.features
    };
    this.geocodePolylinesLayer1.applyEdits(addEdits);
    this.geocodePolylinesLayer2.applyEdits(addEdits);
  }

  runReportNearbyQuery(){
    if (this.state.nearbyType === null || this.state.nearbyTypeAlias === null || this.state.nearbyDistance === null || this.state.nearbyDistance === null){
      console.log("There was a problem running the nearby query on the report")
      return;
    }

    loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"], { css: true })
      .then(([QueryTask, Query]) => {

        let where = nearbyConfig()[this.state.nearbyLayer].typeField + "='" + this.state.nearbyType + "'";
        let queryTask = new QueryTask(nearbyConfig()[this.state.nearbyLayer].url);
        let query = new Query();
        query.where = where;
        query.returnGeometry = true;
        query.geometry = this.assetView.extent.extent.expand(5);
        // query.maxAllowableOffset = this.setMaxOffset();

        queryTask.execute(query).then(this.handleReportNearbyQueryResponse).catch(this.handleQueryFail);

    })
  }

  handleReportNearbyQueryResponse = (response) => {
    console.log("Handle nearby query response on the report", response)

    loadModules(["esri/layers/FeatureLayer", "esri/Graphic"], { css: true })
      .then(([FeatureLayer, Graphic]) => {

        let nearbyGeometryType = nearbyConfig()[this.state.nearbyLayer].geometryType;
        let nearbyLayer, nearbyLayer2;
        if (nearbyGeometryType === 'point'){
          console.log("nearby layer is point")
          nearbyLayer = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "point",
            renderer: mapConfig().nearbyPoints
          });
          nearbyLayer2 = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "point",
            renderer: mapConfig().nearbyPoints
          });

        } else if (nearbyGeometryType === 'polygon') {
          console.log("nearby layer is polygon")
          nearbyLayer = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "polygon",
            renderer: mapConfig().nearbyPolygons
          });
          nearbyLayer2 = new FeatureLayer({
            title: Pluralize.plural(this.state.nearbyTypeAlias),
            listMode: "show",
            legendEnabled: true,
            objectIdField: "ObjectID",
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
             }
            ],
            spatialReference: { wkid: 4326 },
            source: [],
            geometryType: "polygon",
            renderer: mapConfig().nearbyPolygons
          });
        }

        let addEdits = {
          addFeatures: []
        };

        response.features.forEach(spatialFeature => {
          let nearbyGraphic = new Graphic({
            geometry: spatialFeature.geometry,
            attributes: spatialFeature.attributes,
          })
          addEdits.addFeatures.push(nearbyGraphic)
        });
        nearbyLayer.applyEdits(addEdits);
        this.assetView.map.add(nearbyLayer);
        nearbyLayer2.applyEdits(addEdits);
        this.contextView.map.add(nearbyLayer2);
    })
  }

  handleQueryFail(err) {
    console.log("error","There was a problem running the query", err)
  }

  loadVisibleLayers(){
    console.log("load visible layers")
    this.contextView.map.allLayers.forEach(lyr => this.setVisibleLayers(lyr))
  }

  setVisibleLayers(lyr){
    // console.log("running set visible layers for", lyr.title)
    try{
      if (lyr.type === 'group') {
        lyr.visible = true;
        return;
      } else if (lyr.type !== 'feature' && lyr.type !== 'map-image') {
        return;
      }

      lyr.visible = false;
      if (this.state.visibleLayers.length > 0) {
        lyr.visible = (this.state.visibleLayers.split(",").indexOf(lyr.id) > -1);
      }

      // If this layer also has a popup template configured, query its visible features for display in the lower table
      if (lyr.visible && lyr.popupTemplate) {
        let outFields;
        if (lyr.popupTemplate.fieldInfos){
          outFields = lyr.popupTemplate.fieldInfos.map(field => {return field.fieldName});
        } else if (lyr.popupTemplate.content && lyr.popupTemplate.content.length) {
          lyr.popupTemplate.content.forEach(content => {
            if (content.fieldInfos){
              outFields = content.fieldInfos.map(field => {return field.fieldName});
              console.log(lyr.title, outFields)
            }
          })
        }

        if (outFields){
          let query = {
            geometry: this.contextView.extent,
            returnGeometry: true,
            outFields: outFields
          }

          // Apply any applicable filters
          let filter = this.state.filters[lyr.id];
          if (filter){
            query.where = filter;
          }
          
          lyr.queryFeatures(query).then(this.displayVisibleLayerTable);
        } else {
          console.error("Unable to find fields for layer", lyr.title)
        }
      }
    } catch(err) {
      console.log("problem with layer visibility", err.message)
    }
  }

  exportAllTablesToCSV(){
    // Export the CLC polygons
    let features = this.state.features;
    let title = "Crown Lands polygons";
    let layer = this.state.clcLayer;
    // Bind the layer to the features for later retrieval
    features.forEach(feature => {
      feature.layer = layer;
    })
    this.exportToCSV(layer, features, title);

    // Export all other tables
    this.state.visibleFeatures.forEach(visibleFeaturesArray => {
      let layer = visibleFeaturesArray[0][0].layer;
      let title = layer.title;
      let features = visibleFeaturesArray[0];
      this.exportToCSV(layer, features, title);
    })

  }

  exportToCSVwrapper(evt){
    try{
      let tableId = evt.target.dataset.tableid;
      let features;
      let title;
      let layer;
      if (tableId === 'tblCrownPolygons'){
        features = this.state.features;
        title = "Crown Lands polygons";
        layer = this.state.clcLayer;
        // Bind the layer to the features for later retrieval
        features.forEach(feature => {
          feature.layer = layer;
        })
      } else {
        features = this.state.visibleFeatures.find(o => o[0][0].layer.id === tableId)[0];
        layer = features[0].layer
        title = layer.title;
      }

      this.exportToCSV(layer, features, title);
    } catch(err){

    }
  }


  exportToCSV(layer, features, title){
    try{
      if (features.length < 1){
        console.log("No features found");
        return;
      }
      
      // The FeatureTable object is attached to the layer, so retrieve its currently visible fields
      let visibleFields = layer.featureTable.grid.columns.items.filter(field => {
        return field.hidden === false;
      })
      let fields = visibleFields.map(field => {
        return field.header || field.name;
      })

      let headers = fields.map(field => {
        return field;
      })

      let rows = [];
      let types = layer.source.sourceJSON.types;
      let allFields = layer.source.sourceJSON.fields;
      features.forEach(feature => {
        let row = {}
        visibleFields.forEach(field => {
          let attribute = field.name;
          try{
            if (!feature.layer.featureTable.columns.items.find(o => o.name === attribute).hidden){

              // Check for matching subtypes
              if (types && types.length > 0) {
                let domains = layer.source.sourceJSON.types.filter(type => {return type.domains[attribute] !== null});
                let domain = domains.find(o => o.id === feature.attributes[attribute]);
                if (domain) {
                  row[attribute] = domain.name;
                }
              

                // Check for matching domains on a subtype
                if (!row[attribute]){
                  types.forEach(type => {
                    if (type.domains && type.domains[attribute] && type.domains[attribute]['codedValues']){
                      if (type.domains[attribute].codedValues.find(o => o.code === feature.attributes[attribute])){
                        row[attribute] = type.domains[attribute].codedValues.find(o => o.code === feature.attributes[attribute]).name;
                      }
                    }
                  });
                }
              }

              // Check for matching domains not on a subtype
              if (!row[attribute]){
                allFields.forEach(fld => {
                  if (fld.domain && fld.domain.type === 'codedValue'){
                    fld.domain.codedValues.forEach(codedValue => {
                      console.log(fld.name, codedValue.code, codedValue.name)
                    })
                    let codedValue = fld.domain.codedValues.find(o => o.code === feature.attributes[attribute]);
                    if (codedValue) {
                      row[attribute] = codedValue.name;
                    }
                  }
                });
              }

              // If there is no match to a domain or subtype, just show the raw value
              if (!row[attribute]){

                // Format dates into human-readable values
                let fieldType = allFields.find(o => o.name === attribute).type;
                if (fieldType === 'esriFieldTypeDate') {
                  let d = new Date(feature.attributes[attribute]);
                  let year = d.getFullYear();
                  let month = ("0" + (d.getMonth() + 1)).slice(-2);
                  let day = ("0" + (d.getDate())).slice(-2);
                  let hours = ("0" + (d.getHours())).slice(-2);
                  let mins = ("0" + (d.getMinutes())).slice(-2);
                  let formattedDate = year + "-" + month + "-" + day + " " + hours  + ":" + mins;
                  row[attribute] = formattedDate
                } else {
                  row[attribute] = feature.attributes[attribute];
                }
                
              }
                            
            }
          } catch(err){
            console.error("There was a problem exporting the row to CSV", err)
            // row[attribute] = feature.attributes[attribute];
          }
        })
        rows.push(row)
      })
      
      exportCSVFile(headers, rows, title);
    } catch(err){
      console.error("There was a problem exporting to CSV", err)
    }
  }

  async handleMapLoad(view) {
    //console.log("handle context map load");

    // Set layer visibility on the context view
    // this.contextView.map.allLayers.forEach(lyr => this.loadVisibleLayers(lyr));
    this.loadVisibleLayers();

    const [QueryTask, Query, FeatureLayer] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query", "esri/layers/FeatureLayer"]);

    console.log("create report layers")
    this.selectedCLClayer = new FeatureLayer(mapConfig().reportSelectedPolygons);
    this.nonSelectedCLClayer = new FeatureLayer(mapConfig().reportNonSelectedPolygons);
    this.selectedCLClayer2 = new FeatureLayer(mapConfig().reportSelectedPolygons);
    this.nonSelectedCLClayer2 = new FeatureLayer(mapConfig().reportNonSelectedPolygons);

    // load the CLC layers
    this.assetView.map.add(this.nonSelectedCLClayer);
    this.assetView.map.add(this.selectedCLClayer);
    // this.contextView.map.add(this.nonSelectedCLClayer2);
    this.contextView.map.add(this.selectedCLClayer2);

    // Query for the selected Crown polygons
    let url;
    this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
    let queryTask = new QueryTask(url)
    let query = new Query();
    query.returnGeometry = true;
    let outFields = ["*"]
    this.state.mode === 'public' ? outFields = layerConfig().public.clcLayer.outFields : outFields = layerConfig().internal.clcLayer.outFields;
    query.outFields = outFields;
    let idField;
    this.state.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
    query.where = idField + " in (" + this.state.cadids.toLocaleString() + ")";
    queryTask.execute(query).then(this.handleSelectedPolygons).catch(this.promiseRejected);

    // Query for the non-selected Crown polygons
    let query2 = new Query();
    query2.returnGeometry = true;
    query2.geometry = this.assetView.extent.expand(1.5);
    query2.outFields = outFields;
    query2.where = idField + " not in (" + this.state.cadids.toLocaleString() + ")";
    queryTask.execute(query2).then(this.handleNonSelectedPolygons).catch(this.promiseRejected);

    // add the geocode features if applicable
    if (getUrlParameter("poiText")) {
      this.addReportGeocode();
    }

    // Run the nearby query
    if (this.state.nearbyTypeAlias){
      this.runReportNearbyQuery()
    }

    // Restore scrolling
    document.body.style.overflowY = 'auto';

  }

  async displayVisibleLayerTable(results) {
    // Display this layer's values in a table below the Context map
    if (results.features.length === 0) {
      return;
    }
    let features = results.features;
    console.log('display visible features table')
    
    let visibleFeatures = this.state.visibleFeatures;
    visibleFeatures.push([features]);
    this.setState({visibleFeatures: visibleFeatures})

    const [FeatureTable] = await loadModules(["esri/widgets/FeatureTable"]);

    // get this layer's object ID field
    let oidFieldName = 'OBJECTID';
    try{
      oidFieldName = features[0].layer.fields.find(o => o.type === 'oid').name;
    } catch(err){
      console.error("can't find object ID field, defaulting to OBJECID", err);
    }
    
    // filter the layer based on the object IDs within the view extent
    let oids = features.map(feature => {
      return feature.attributes[oidFieldName];
    })
    let fLayer = features[0].layer;

    // If there is a filter on the layer, update its title
    if (this.state.filters[fLayer.id]) {
      let filter = this.state.filters[fLayer.id]
      let title = addFilterTitle(filter, fLayer);

      // update the layer's title in the state, to include the filter details
      // (the forEach in this section was previously map - changed to avoid JSX compilation error. Revert if this causes issues)
      let visibleFeatures = this.state.visibleFeatures;
      visibleFeatures.forEach(visibleLayer => {
        visibleLayer.forEach(features => {
          features.forEach(feature => {
            if (feature.layer.title === fLayer.title) {
              feature.layer.title = title;
            }
          })
        })
      })
      this.setState({visibleFeatures})
      
    }
    fLayer.definitionExpression = oidFieldName + " in (" + oids + ")";
    let fieldConfigs;
    if (fLayer.popupTemplate.fieldInfos){
      fieldConfigs = fLayer.popupTemplate.fieldInfos.map(field => {
        field.name = field.fieldName;
        return field
      })
    } else if (fLayer.popupTemplate.content && fLayer.popupTemplate.content.length){
      fLayer.popupTemplate.content.forEach(content => {
        if (content.fieldInfos){
          fieldConfigs = content.fieldInfos.map(field => {
            field.name = field.fieldName;
            return field
          })
        }
      })
    }
    if (fieldConfigs){
      let fTable = new FeatureTable({
        layer: fLayer,
        container: fLayer.id,
        fieldConfigs: fieldConfigs,
        visibleElements: {
          header: true,
          menu: true,
          menuItems: {
            clearSelection: false,
            refreshData: false,
            toggleColumns: true
          },
          selectionColumn: false
      }
      })
      fLayer.featureTable = fTable;
      console.log("created table", fTable)
    } else {
      console.error("unable to create table for layer", fLayer.title)
    }
  }

  async handleSelectedPolygons(response) {
    // console.log("handle selected polygons", response.features)
    this.setState({features: response.features});

    // Add the features to the map
    if (response.features && response.features.length > 0) {
      const [Graphic] = await loadModules(["esri/Graphic"]);
      let polygonGraphics = [];
      response.features.forEach(feature => {
        let polygonGraphic = new Graphic({
          geometry: {
            rings: feature.geometry.rings,
            spatialReference: feature.geometry.spatialReference,
            type: "polygon"
          },
          attributes: feature.attributes
        });
        polygonGraphics.push(polygonGraphic);
      });

      const addEditPolygons = {
        addFeatures: polygonGraphics
      }

      this.selectedCLClayer.applyEdits(addEditPolygons).then(this.createCLCtable);
      this.selectedCLClayer2.applyEdits(addEditPolygons);

    }
  }

  async createCLCtable(){
    console.log("create CLC table")
    const [FeatureTable,FeatureLayer] = await loadModules(["esri/widgets/FeatureTable", "esri/layers/FeatureLayer"]);
    let url;
    this.state.mode === 'public' ? url = layerConfig().public.clcLayer.url : url = layerConfig().internal.clcLayer.url;
    let fLayer = new FeatureLayer(url)
    fLayer.definitionExpression = "cadid in (" + this.state.cadids + ")";
    fLayer.numFeat = this.state.cadids.length;
    let fieldConfigs;
    this.state.mode === 'public' ? fieldConfigs = layerConfig().public.clcLayer.fieldConfigs : fieldConfigs = layerConfig().internal.clcLayer.fieldConfigs;

    let fTable = new FeatureTable({
      layer: fLayer,
      container: "tblCrownPolygons",
      // view: this.assetView,
      fieldConfigs: fieldConfigs,
      visibleElements: {
        header: true,
        menu: true,
        menuItems: {
          clearSelection: false,
          refreshData: false,
          toggleColumns: true
        },
        selectionColumn: false
     }
    })
    fLayer.featureTable = fTable;
    this.setState({clcLayer: fLayer})
  }

  async handleNonSelectedPolygons(response) {
    //console.log("handle non-selected polygons", response.features)

    // Add the features to the map
    if (response.features && response.features.length > 0) {
      const [Graphic] = await loadModules(["esri/Graphic"]);
      let polygonGraphics = [];
      response.features.forEach(feature => {
        let polygonGraphic = new Graphic({
          geometry: {
            rings: feature.geometry.rings,
            spatialReference: feature.geometry.spatialReference,
            type: "polygon"
          },
          attributes: feature.attributes
        });
        polygonGraphics.push(polygonGraphic);
      });

      const addEditPolygons = {
        addFeatures: polygonGraphics
      }

      this.nonSelectedCLClayer.applyEdits(addEditPolygons);
      this.nonSelectedCLClayer2.applyEdits(addEditPolygons);
    }
  }

  promiseRejected(error) {
    console.log("error, promise rejected", error)
  }

  exportReport = () =>  {
    window.print();
  }

  render() {

    var today = new Date(), date = today.getDate() + "/" + (today.getMonth() + 1 + "/" + today.getFullYear());
    let selectionCriteria = null;
    let nearbyCriteria = null;
    // let tblCrownPolygons = null;
    let tblVisibleFeatures = null;

    if (this.state.classSubtype || this.state.fer || this.state.areaMin || this.state.areaMax) {
      selectionCriteria =
        <React.Fragment>
          <Col sm={4} className="pl-0">
            <h4 className="resultsHeading">FILTER CRITERIA</h4>
            {this.state.classSubtype && this.state.classSubtype !== 'Any' ? 
              <p className="subheading">Class Subtype: {this.state.classSubtype}</p>
            : null}
            {this.state.statusType !== null && this.state.statusType !== 'Any' ?
              <p className="subheading">Status Type: {this.state.statusType}</p>
            : null}
            {this.state.fer !== null ?
              <p className="subheading">Functional Economic Region Driver: {this.state.fer}</p>
            : null}
            {this.state.areaMin !== null ?
              <p className="subheading">Minimum area: {this.state.areaMin.toLocaleString()} sqm</p>
            : null}
            {this.state.areaMax !== null ?
              <p className="subheading">Maximum area: {this.state.areaMax.toLocaleString()} sqm</p>
            : null}
          </Col>
        </React.Fragment>
    }

    if (this.state.nearbyTypeAlias && this.state.nearbyDistance) {
      nearbyCriteria =
        <React.Fragment>
          <Col sm={4} className="pl-0">
            <h4 className="resultsHeading">PROXIMITY</h4>
            <span className="subheading">Within {this.state.nearbyDistance} km of {Pluralize.plural(this.state.nearbyTypeAlias)}</span>
          </Col>
        </React.Fragment>
    } 

    tblVisibleFeatures = this.state.visibleFeatures.map((featuresArray, fid) =>
      featuresArray.length > 0 ?
        <React.Fragment>
          <Row key={"row" + fid} className="mt-5 border-top pagebreak">
            <Col md={12} className='pl-0'>
              <h4 className="mt-3">{featuresArray[0][0].layer.title}</h4>
            </Col>
          </Row>
          <Row key={"row3" + fid} className="mt-n3">
            <div key={fid} id={featuresArray[0][0].layer.id} style={{'height': 120 + featuresArray[0].length * 34 + 'px'}}>
            </div>
          </Row>
          <Row key={"row2" + fid}>
            <Col md={10} className='pl-0 mt-2'>
              <span className="spanNumFeat">({featuresArray[0].length} {Pluralize("feature", featuresArray[0].length)})</span>
            </Col>
            <Col md={2}>
              <Button
                key={"button" + fid}
                className="btn btn-default float-right mr-n2 no-print"
                variant="turquoiseText"
                data-tableid={featuresArray[0][0].layer.id}
                // data-fields={Object.keys(featuresArray[0][0].attributes).toLocaleString()}
                onClick={this.exportToCSVwrapper}
              >
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="mr-2"
                />
                Export as CSV
             </Button>
            </Col>
          </Row>
          
        </React.Fragment>

     : null
    )

    let height = 120 + (this.state.features.length * 34);
    const divStyle = {
      height: height + 'px',
    };

    return (
      <React.Fragment>
        <Header
          page = "report"
          isLoggedIn={this.props.isLoggedIn}
          logout = {this.props.logout}
          resetSettings = {this.props.resetSettings}
          toggleSourcesModal={this.props.toggleSourcesModal}
        />
        
        {/* show a message if the screen is smaller than md, otherwise show the other content */}
        <MobileMessage/>

        <Container id="reportContainer" className="d-none d-md-block">
          <Row className="mt-5">
            <h1>Report<br/></h1>
          </Row>
          <Row className="mt-1">
            <Col sm={1} className="mt-2">
              <span className="subheading ml-n3">{date}</span>
            </Col>
            <Col xs={4} sm={4} lg={2}>
              <Button id="btnExportReport" className="no-print" variant="turquoiseText" onClick={this.exportReport}>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="mr-2"
                />
                Export as PDF
              </Button>
            </Col>
            <Col xs={6} sm={6} lg={4}>
              <Button id="btnExportAllAsCSV" className="no-print" variant="turquoiseText" onClick={this.exportAllTablesToCSV}>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="mr-2"
                />
                Export all tables as CSV
              </Button>
            </Col>
          </Row>

          <Row sm={12} className="mt-2">
            <h2>Crown Estate</h2>
          </Row>

          <Row className="mt-2">
            <Col id="reportMap" sm={8}>
              <div
                className="base-container"
                ref={this.mapRef1}
              />
              <img id='loadingImg' className = "loadingImg" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'}/>
            </Col>
            <Col sm={4}>
              <h2>Legend</h2>
              <div id='legend'></div>
            </Col>
           </Row>

           {{selectionCriteria} || {selectionCriteria} ? (
             <Row className="mt-4">
               {selectionCriteria}
               {nearbyCriteria}
             </Row>
            ) : null }

          {this.state.features.length > 0 ? 
            <React.Fragment>
              <Row className="mt-5 border-top pagebreak">
                <Col md={12} className='pl-0'>
                  <h4 className="mt-3">Selected Crown Estate Areas</h4>
                </Col>
              </Row>
              <Row key="tblCrownPolygons" className="mt-n3">
                <div id="tblCrownPolygons" style={divStyle}></div>
              </Row>
              <Row>
                <Col md={10} className='pl-0 mt-2'>
                  <span className="spanNumFeat">({this.state.features.length} {Pluralize("feature", this.state.features.length)})</span>
                </Col>
                <Col md={2}>
                  <Button
                    key="buttonCrownPolygons"
                    className="btn btn-default float-right mr-n2 no-print"
                    variant="turquoiseText"
                    data-tableid="tblCrownPolygons"
                    onClick={this.exportToCSVwrapper}
                  >
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className="mr-2"
                    />
                    Export as CSV
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          : null}

           {/* SECOND SECTION */ }

          <Row sm={12} className="mt-5 pagebreak">
            <h2>Context</h2>
          </Row>

          <Row className="mt-2">
            <Col id="contextMap" sm={8}>
              <div
                className="base-container"
                ref={this.mapRef2}
              />
              <img id='loadingImg2' className = "loadingImg" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'}/>
            </Col>
            <Col sm={4}>
              <h2>Legend</h2>
              <div id='legend2'></div>
            </Col>
           </Row>

          {tblVisibleFeatures}

          <Row className='mt-5'>
              <Col xs={8}>
                <p><b>Disclaimer</b></p>
                <p>The information contained in this map has been provided in good faith. Whilst all effort has been made to ensure the accuracy and completeness of this information the data providers take no responsibility for errors or omissions nor any loss or damage that may result from the use of this information.</p>
              </Col>
            </Row>

          <Row className='mt-5'>
            <Button className="no-print" variant="turquoiseText" onClick={this.exportReport}>
              <FontAwesomeIcon
                icon={faArrowDown}
                className="mr-2"
              />
              Export as PDF
            </Button>
          </Row>

        </Container>
      </React.Fragment>
    );
  }
}

export default Report;
