import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';

class Filter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // fieldName: null,
        // operator: null,
        // value: null
      }
      this.updateState = this.updateState.bind(this);
    }
  
    componentDidMount(){
        console.log("filter mounted")
    }
  
    componentWillUnmount() {
        console.log("filter will unmount")
    }

    updateState(event){
        try{
            event.preventDefault();
        } catch(error){
            // Ignore
        }

        let params = event;
        
        // If the field name is changing, refresh the operator
        if (event.data && event.data.name === 'fieldName'){
            this.props.filter.operator = this.props.setOperator(event.value)[0];
            this.props.filter.value = '';
        }

        params.filter = this.props.filter;
        this.props.updateFilter(params)
        
    }

    render(){

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              fontSize: 14,
              fontWeight: 100,
              color: '#323232'
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
        }

        // Temporarily removing date fields from the filter - too complicated
        let nonDateFields = this.props.fields.filter(field => {
            return field.type !== 'date';
        })
        //const fieldOptions = this.props.fields.map(field => {
        const fieldOptions = nonDateFields.map(field => {
            return { value: field.name, label: field.alias, data: {name: 'fieldName'}}
        })

        let fieldSelect = (
            <Select
                value={fieldOptions.find(o => o.label === this.props.filter.fieldName)}
                onChange={this.updateState}
                options={fieldOptions}
                className="react-select"
                classNamePrefix="react-select"
                // force dropdowns to have high z-index
                menuPortalTarget={document.body}
                styles={ customStyles }
            />
        );

        let operators = this.props.setOperator(this.props.filter.fieldName).map(operator => {
            return { value: operator, label: operator, data: {name: 'operator'}}
        });
        let operator = (
            <Select
                value={operators.find(o => o.label === this.props.filter.operator)}
                onChange={this.updateState}
                options={operators}
                className="react-select"
                classNamePrefix="react-select"
                // force dropdowns to have high z-index
                menuPortalTarget={document.body}
                styles={ customStyles }
            />
        )

        let value;
    
        if (this.props.filter.uniqueValues && this.props.filter.uniqueValues[this.props.filter.fieldName] && !this.props.filter.exceededTransferLimit[this.props.filter.fieldName]){
            let valueOptions = this.props.filter.uniqueValues[this.props.filter.fieldName].map(codedValue => {
                return { value: codedValue, label: codedValue, data: {name: 'value'}}
            })
            value = (
                <Select
                    value={valueOptions.find(o => o.label === this.props.filter.value)}
                    onChange={this.updateState}
                    options={valueOptions}
                    placeholder="Choose the value"
                    className="react-select"
                    classNamePrefix="react-select"
                    // force dropdowns to have high z-index
                    menuPortalTarget={document.body}
                    styles={ customStyles }
                />
            );

        } else {
            if (this.props.filter.fieldType === 'string'){
                value = <Form.Control type="text" name="value" autoComplete="off" onChange={this.updateState} className='bordered filterPlaceholder clcInput' value={this.props.filter.value} placeholder="Enter the value"/>;
            } else if (['integer', 'small-integer'].indexOf(this.props.filter.fieldType) > -1){
                value = <Form.Control type="number" name="value" autoComplete="off" onChange={this.updateState} className='bordered filterPlaceholder clcInput' value={this.props.filter.value}  placeholder="Enter the value"/>;
            } else if (this.props.filter.fieldType === 'double'){
                value = <Form.Control type="number" name="value" autoComplete="off" onChange={this.updateState} className='bordered filterPlaceholder clcInput' min="0" step="0.25" value={this.props.filter.value}  placeholder="Enter the value"/>
            } else if (this.props.filter.fieldType === 'date'){
                value = <Form.Control type="date" name="value" autoComplete="off" onChange={this.updateState} className='bordered filterPlaceholder clcInput' value={this.props.filter.value}  placeholder="Enter the date"/>;
            }
        }

        return (
            <React.Fragment>
                <Form.Group className={"card pt-1 pl-2 pr-2 pb-2 filter " + (this.props.filter.valid ? 'valid' : 'invalid')}>
                    <Form.Row>
                        <Col xs={12} className="text-right">
                            <Button
                                onClick={this.props.deleteFilter}
                                data-filterid={this.props.filter.id}
                                variant='gray'
                                className="pr-0 mr-0"
                            >
                                <img className=""
                                    src="./img/close_grey.svg"
                                    // onMouseOver={e => (e.currentTarget.src = "./img/clear.svg")}
                                    // onMouseOut={e => (e.currentTarget.src ="./img/clear.svg")}
                                    alt="clear"
                                />
                            </Button>
                            
                        </Col>
                    </Form.Row>
                    <Form.Row className="pl-2 pr-2 pb-2 pt-0">
                        <Col sm={8}>
                            {fieldSelect}
                        </Col>
                        <Col sm={4}>
                            {operator}
                        </Col>
                    </Form.Row>
                    <Form.Row className="p-2">
                        <Col sm={12}>
                            {value}
                        </Col>
                    </Form.Row>
                </Form.Group>

            </React.Fragment>
        )
    }
}


export { Filter };