/* This is the sidebar on the Explore page */

import React, { Component } from 'react';

import { Row, Form } from 'react-bootstrap';
import {layerConfig} from '../config/layerConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class ExploreMenu extends Component {

  constructor(props) {
    super(props);
  
    this.createLayerControl = this.createLayerControl.bind(this);
  } 

  componentDidMount(){
      // console.log("Explore Layers mounted")
  }

  componentWillUnmount() {
      // console.log("Explore Layers will unmount")
  }

  toggleLayerVisibility(layerConfig){
    try{
      let foundLayer = this.props.map.layers.items.find(o => o.title === layerConfig.title);
      if (foundLayer.visible){
        foundLayer.visible = false;
      } else {
        foundLayer.visible = true;
      }
    } catch(err){
      console.error("There was a problem toggling layer visibility", layerConfig)
    }
  }

  createLayerControl(layerConfig) {
    // Create the checkbox for this layer
    if (!this.props.map) {return null;}
    let foundLayer = this.props.map.layers.items.find(o => o.title === layerConfig.title);
    if (foundLayer) {
      let metadata;
      if (foundLayer.metadata) {
        metadata = (
          <a href={foundLayer.metadata} target="_blank" rel="noreferrer" className='exploreLayerLink'><FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2"/></a>
        )
      }
      return (
        <Row key={"check_" + layerConfig.title}>
          <Form.Check 
            type="checkbox"
            id={`default-${layerConfig.title}`}
            label={layerConfig.title}         
            onChange={() => this.toggleLayerVisibility(layerConfig)}
            defaultChecked={foundLayer.visible}
          />
          {metadata}
        </Row>
      )
      } else {
        return null;
      }
  }

  render() {
    if (!this.props.map || !this.props.isLoaded) {return null;}

    let controls = layerConfig().exploreLayers.map(layerConfig => {
      let subLayerHtml = layerConfig.childLayers.map(subLayer => {
        return this.createLayerControl(subLayer)
      })
      let layerHtml = (
        <React.Fragment key={"title_" + layerConfig.title}>
          <hr/>
          <Row>
            <h5>{layerConfig.title}</h5>
          </Row>
          {subLayerHtml}
        </React.Fragment>
      )
      return layerHtml;
    })

    return (
      <form id="frmExploreMenu" className='ml-3'>
        <p className="instructionalText">Turn on each <strong>Map Layer</strong> to see where the Crown Estate is located and how it is currently used. Click on areas to view usage information, and <strong>Create Snapshot</strong> to open a new page and share.</p>
        {controls}
      </form>
    );
  }
}

export { ExploreMenu };
