import React, { Component } from 'react';
import { MainMap } from "./components/MainMap";
import { Sidebar } from "./components/Sidebar";
import Header  from "./components/Header";
import { getUrlParameter } from "./modules/utils";
import ClcModal  from "./components/Modal";
import Sources from './components/Sources';
import MobileMessage from './components/MobileMessage';

class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickMap: null
    };

  }

  componentDidMount(){
    //console.log("Explore page mounted")
    let quickMap=getUrlParameter("quickmap");
    if(quickMap){
      this.setState({quickMap:quickMap});
    }
  }

  componentWillUnmount() {
    //console.log("Explore page will unmount")
  }

  render() {

    return (
      <React.Fragment>
        <Header
          areaMax = {this.props.areaMax}
          areaMin = {this.props.areaMin}
          classSubtype = {this.props.classSubtype}
          features={this.props.features}
          fer={this.props.fer}
          geocodeForReport={this.props.geocodeForReport}
          isLoggedIn={this.props.isLoggedIn}
          logout = {this.props.logout}
          map = {this.props.map}
          nearbyDistance = {this.props.nearbyDistance}
          nearbyLayer = {this.props.nearbyLayer}
          nearbyType = {this.props.nearbyType}
          nearbyTypeAlias={this.props.nearbyTypeAlias}
          page = "explore"
          resetSettings = {this.props.resetSettings}
          searchPending={this.props.searchPending}
          statusType = {this.props.statusType}
          toggleSourcesModal={this.props.toggleSourcesModal}
          view = {this.props.view}
          // visibleLayers = {this.props.visibleLayers}
        />
        
        {/* show a message if the screen is smaller than md, otherwise show the other content */}
        <MobileMessage/>
        
        <div className="wrapper d-none d-md-flex">
          <div id="sidebar" className={this.props.mapClass}>
            <Sidebar
              addRemoveChanged = {this.props.addRemoveChanged}
              addRemoveMode = {this.props.addRemoveMode}
              areaMax = {this.props.areaMax}
              areaMin = {this.props.areaMin}
              autoRunSearch = {this.props.autoRunSearch}
              classSubtype = {this.props.classSubtype}
              clearFeatures = {this.props.clearFeatures}
              clearGeocodeResults = {this.props.clearGeocodeResults}
              displayGeocodeResult = {this.props.displayGeocodeResult}
              exceededTransferLimit={this.props.exceededTransferLimit}
              extentChanged={this.props.extentChanged}
              features={this.props.features}
              fer={this.props.fer}
              geocodeForReport={this.props.geocodeForReport}
              geocodeLatLong={this.props.geocodeLatLong}
              geocodeOptions={this.props.geocodeOptions}
              handleGeocodeOptionsUpdate={this.props.handleGeocodeOptionsUpdate}
              handleGeocodeChange = {this.props.handleGeocodeChange}
              handleNearbyLayerChange={this.props.handleNearbyLayerChange}
              handleSearchSubmit={this.props.handleSearchSubmit}
              handleSelectChange={this.props.handleSelectChange}
              history = {this.props.history}
              isLoaded = {this.props.isLoaded}
              map={this.props.map}
              mapClass = {this.props.mapClass}
              mode = {this.props.mode}
              overlayLayer={this.props.overlayLayer}
              nearbyBuffers = {this.props.nearbyBuffers}
              nearbyDistance={this.props.nearbyDistance}
              nearbyLayer = {this.props.nearbyLayer}
              nearbyType={this.props.nearbyType}
              nearbyTypeAlias={this.props.nearbyTypeAlias}
              nearbyTypes={this.props.nearbyTypes}
              overlapFeatureCount={this.props.overlapFeatureCount}
              page = "explore"
              paginationCurrentPage={this.props.paginationCurrentPage}
              queryResponseCount = {this.props.queryResponseCount}
              removeAllSearchResults = {this.props.removeAllSearchResults}
              removeFromSearchResults = {this.props.removeFromSearchResults}
              reserve={this.props.reserve}
              resetSettings = {this.props.resetSettings}
              searchMessages={this.props.searchMessages}
              searchPending={this.props.searchPending}
              searchReady={this.props.searchReady}
              selectedGeocode={this.props.selectedGeocode}
              setSidebarMode={this.props.setSidebarMode}
              sidebarMode={this.props.sidebarMode}
              statusType={this.props.statusType}
              toggleAddRemoveMode={this.props.toggleAddRemoveMode}
              toggleSidebar={this.props.toggleSidebar}
              toggleState={this.props.toggleState}
              updatePagination={this.props.updatePagination}
              useOverlayLayer={this.props.useOverlayLayer}
              view = {this.props.view}
              zoomToGeoCode={this.props.zoomToGeoCode}
              // visibleLayers = {this.props.visibleLayers}
            />
          </div>

          <div id="content" className={this.props.mapClass}>
            <ClcModal
              closeModal = {this.props.closeModal}
              modalContent = {this.props.modalContent}
              showModal = {this.props.modalOpen}
            />
            <Sources
              toggleSourcesModal={this.props.toggleSourcesModal}
              showSourcesModal={this.props.showSourcesModal}
            ></Sources>
            <MainMap
              agolPrivileges={this.props.agolPrivileges}
              clearOverlayLayer={this.props.clearOverlayLayer}
              displayWidgets = {this.props.displayWidgets}
              features={this.props.features}
              geocodeForReport={this.props.geocodeForReport}
              isLoaded = {this.props.isLoaded}
              map = {this.props.map}
              mapIsUpdating={this.props.mapIsUpdating}
              mode={this.props.mode}
              nearbyDistance={this.props.nearbyDistance}
              nearbyPointsLayer={this.props.nearbyPointsLayer}
              nearbyPolygonsLayer={this.props.nearbyPolygonsLayer}
              nearbyLayer = {this.props.nearbyLayer}
              nearbyType={this.props.nearbyType}
              nearbyTypeAlias={this.props.nearbyTypeAlias}
              nearbyTypes={this.props.nearbyTypes}
              overlayLayer={this.props.overlayLayer}
              page = "explore"
              quickMap={this.state.quickMap}
              saveMapToState = {this.props.saveMapToState}
              saveViewToState = {this.props.saveViewToState}
              searchPending = {this.props.searchPending}
              token={this.props.token}
              updateLoadedStatus = {this.props.updateLoadedStatus}
              updateOverlayLayer={this.props.updateOverlayLayer}
              userId={this.props.userId}
              // updateVisibleLayers = {this.props.updateVisibleLayers}
              view = {this.props.view}
              // visibleLayers = {this.props.visibleLayers}

            />
            {this.props.mapIsUpdating ? 
              <img id='loadingImg' className = "loadingImg" alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'}/>
            : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Explore;
