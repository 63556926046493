import React, { Component } from 'react';

class MeasureTool extends Component {
    constructor(props) {
      super(props);
      this.state = {
        
      }
      this.distanceMeasurement = this.distanceMeasurement.bind(this);
      this.areaMeasurement = this.areaMeasurement.bind(this);
      this.clearMeasurements = this.clearMeasurements.bind(this);
  
    }
  
    componentDidMount(){
        //console.log("measure tool mounted")
    }
  
    componentWillUnmount() {
        //console.log("measure tool will unmount")
    }

    // Measurement
    distanceMeasurement() {
        this.props.view.measurement.activeTool = "distance";
        this.props.changeActiveMeasureTool ('distance');
    }
    areaMeasurement() {
        this.props.view.measurement.activeTool = "area";
        this.props.changeActiveMeasureTool ('area');
    }
    clearMeasurements() {
        this.props.view.measurement.clear();
        this.props.changeActiveMeasureTool (null);
    }

    render(){
        return (
            <div id="MeasureToolDiv" className={"esri-component esri-widget customTool " + (this.props.searchPending ? 'hidden' : '')}>
                <button
                    id="measureDistance"
                    className={"esri-widget--button esri-interactive esri-icon-measure-line " + (this.props.activeMeasureTool === "distance" ? 'active' : '')}
                    title="Distance Measurement Tool"
                    onClick={this.distanceMeasurement}>
                </button>
                <button
                    id="measureArea"
                    className={"esri-widget--button esri-interactive esri-icon-measure-area " + (this.props.activeMeasureTool === "area" ? 'active' : '')}
                    title="Area Measurement Tool"
                    onClick={this.areaMeasurement}>
                </button>
                <button id="measureClear" className="esri-widget--button esri-interactive esri-icon-trash" title="Clear Measurements" onClick={this.clearMeasurements}></button>
            </div>
        )
    }
}


export { MeasureTool };