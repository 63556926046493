/* This is the search bar used on the Find and Explore panels */

import React, { Component } from 'react';
import { searchConfig } from "../config/searchConfig";
import { layerConfig } from "../config/layerConfig";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.22' })

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // geocodeOptions: [],
      // selected: []
    }

    this.processGeocode=this.processGeocode.bind(this);
    this.handleSearch=this.handleSearch.bind(this);

  }

  componentDidMount(){
      //console.log("search form mounted", this.props)
  }

  componentWillUnmount() {
      //console.log("search form will unmount")
  }

  handleSearch = async (searchString) => {

    this.setState({ isLoading: true });
    this.props.handleGeocodeOptionsUpdate([])
    let searchSuggestions = [];
    this.setState({
      isLoading: true
    })

    const [QueryTask, Query] = await loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]);

    //--------------------------------------------------------------------------
    //                    CADID Query
    //--------------------------------------------------------------------------

    // For performance reasons, a CADID search only runs if the length of the
    // string is 9 characters. Otherwise the server freezes up

    // TESTING
    if (searchString.length === 9){
      try{
        //console.log("running CLC query")
        this.setState({isLoading: true});
        
        let clcUrl;
        this.props.mode === 'public' ? clcUrl = layerConfig().public.clcLayer.url : clcUrl = layerConfig().internal.clcLayer.url;
        let queryTask_clc = new QueryTask({url: clcUrl, requestOptions: {timeout: 2000}});
        let query_clc = new Query();
        let idField;
        this.props.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
        query_clc.where = idField + " = " + searchString;
        query_clc.returnGeometry = true;
        query_clc.outFields = [idField];
        
        let response_clc = await queryTask_clc.execute(query_clc);
        if(response_clc){
          let clcResults = [];

            response_clc.features.forEach(feature => {
              try {
                let suggestion={...feature.attributes};
                suggestion.geometry=feature.geometry;
                suggestion.text= ""+feature.attributes[idField]+ " (CADID)";
                suggestion.suggestionType="clc";
                let alreadyExistingSuggestions=this.props.geocodeOptions.filter((alreadyExisting)=>{
                  return (suggestion.text === alreadyExisting.text)  && (suggestion[idField]  === alreadyExisting[idField]) && (suggestion.suggestionType === alreadyExisting.suggestionType)
                });

                if(!alreadyExistingSuggestions  || alreadyExistingSuggestions.length === 0){
                  this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
                  clcResults.push(suggestion);
                }
              } catch (err) {
                console.error("error","There was a problem fetching the geocode for LGA")
              }
            });

            if(clcResults.length>0){
              searchSuggestions.push(...clcResults);
              // this.setState({geocodeOptions: [...this.state.geocodeOptions, searchSuggestions]})
              this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
            }
        }
      } catch(err){
        console.error("There was a problem fetching the CLC polygon", err)
        this.setState({isLoading: false})
      }
    }

    //--------------------------------------------------------------------------
    //                    Address Query
    //--------------------------------------------------------------------------
    try{
      this.setState({isLoading: true});
      let addressUrl =searchConfig().search.address.url + searchString + "&noOfRecords=5";
      let addressResp = await fetch(addressUrl);
      const addressResults = await addressResp.json();
      if (addressResults) {

        addressResults.forEach(suggestion => {
          try {
            suggestion.text = "" + suggestion[searchConfig().search.address.searchField] + " (ADDRESS)";
            suggestion.suggestionType="address";
            // this.setState({ geocodeOptions: [...this.state.geocodeOptions, suggestion] })
            this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
          } catch (err) {
            console.error("error","There was a problem fetching the geocode")
          }
        });

        if(addressResults.length>0){
          searchSuggestions.push(...addressResults);
          // this.setState({geocodeOptions: [...this.state.geocodeOptions, searchSuggestions]});
          this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
        }

      }
    } catch(err){
      console.error("There was a problem fetching the address", err)
      this.setState({isLoading: false})
    }

// END TESTING

    //--------------------------------------------------------------------------
    //                    POI Query
    //--------------------------------------------------------------------------
    try{
      this.setState({isLoading: true});
      let poiUrl = searchConfig().search.poi.url + searchString + "&noOfRecords=5";
      let poiResp = await fetch(poiUrl);
      const poiResults = await poiResp.json();
      if (poiResults) {

        poiResults.forEach(suggestion => {
          try {
            suggestion.text = "" + suggestion[searchConfig().search.poi.searchField];
            suggestion.suggestionType="poi";

            // this.setState({ geocodeOptions: [...this.state.geocodeOptions, suggestion] })
            this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
          } catch (err) {
            console.error("error","There was a problem fetching the geocode")
          }
        });

        if(poiResults.length>0){
          searchSuggestions.push(...poiResults);
          // this.setState({
          //   geocodeOptions: [...this.state.geocodeOptions, searchSuggestions],
          // })
          this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
        }
      }
    } catch(err){
      console.error("There was a problem fetching the POI")
      this.setState({isLoading: false})
    }

// TESTING

    //--------------------------------------------------------------------------
    //                    LotDP Query
    //--------------------------------------------------------------------------
    try{
      this.setState({isLoading: true});
      let lotUrl =  searchConfig().search.lot.url + searchString + "&noOfRecords=1000";
      let lotResp = await fetch(lotUrl);
      const lotDPResults = await lotResp.json();
      if (lotDPResults) {
        let sortedResults = lotDPResults.sort((a, b) => (a.lot > b.lot) ? 1 : -1)

        let uniqueLotResults=[];
        sortedResults.forEach(suggestion => {
          try {
            suggestion.text = "" + suggestion[searchConfig().search.lot.searchField]+ " (LOT/DP)";
            suggestion.suggestionType="lot";

            let alreadyExistingSuggestions=this.props.geocodeOptions.filter((alreadyExisting)=>{
              return (suggestion.text === alreadyExisting.text) && (suggestion[searchConfig().search.lot.uniqueIDField] === alreadyExisting[searchConfig().search.lot.uniqueIDField])
            });

            if(!alreadyExistingSuggestions  || alreadyExistingSuggestions.length === 0){
              // this.setState({ geocodeOptions: [...this.state.geocodeOptions, suggestion] });
              this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
              uniqueLotResults.push(suggestion);
            }
          } catch (err) {
            console.error("error","There was a problem fetching the geocode")
          }
        });

        if(uniqueLotResults.length>0){
          searchSuggestions.push(...uniqueLotResults);
          // this.setState({
          //   geocodeOptions: [...this.state.geocodeOptions,searchSuggestions],
          // })
          this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
        }

      }
    } catch(err){
      console.error("There was a problem fetching the Lot/DP", err)
      this.setState({isLoading: false})
    }
    //--------------------------------------------------------------------------
    //                    LGA Query
    //--------------------------------------------------------------------------
    try{
      this.setState({isLoading: true});
      let lgaURL = searchConfig().search.lga.url;
      let queryTask_lga = new QueryTask({url: lgaURL, requestOptions: {timeout: 2000}});
      let query_lga = new Query();
      query_lga.where = searchConfig().search.lga.searchField+" LIKE '%" + searchString.toUpperCase() + "%'";
      query_lga.returnGeometry = true;
      query_lga.num = 5;
      query_lga.outFields = ["*"];
      query_lga.returnCentroid = true;

     let response_lga=await queryTask_lga.execute(query_lga);
     if(response_lga){
        let lgaeResults = [];

          response_lga.features.forEach(feature => {
            try {
              let suggestion={...feature.attributes};
              suggestion.geometry=feature.geometry;
              suggestion.text= ""+feature.attributes[searchConfig().search.lga.searchField]+ " (LGA)";
              suggestion.suggestionType="lga";
              let alreadyExistingSuggestions=this.props.geocodeOptions.filter((alreadyExisting)=>{
                return (suggestion.text === alreadyExisting.text)  && (suggestion[searchConfig().search.lga.uniqueIDField]  === alreadyExisting[searchConfig().search.lga.uniqueIDField]) && (suggestion.suggestionType === alreadyExisting.suggestionType)
            });

            if(!alreadyExistingSuggestions  || alreadyExistingSuggestions.length === 0){
            //  this.setState({ geocodeOptions: [...this.state.geocodeOptions, suggestion] });
            this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
             lgaeResults.push(suggestion);
            }
            } catch (err) {
              console.error("error","There was a problem fetching the geocode for LGA")
            }
          });

          if(lgaeResults.length>0){
            searchSuggestions.push(...lgaeResults);
            // this.setState({
            //   geocodeOptions: [...this.state.geocodeOptions, searchSuggestions],
            // })
            this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
          }
      }
    } catch(err){
      console.error("There was a problem fetching the LGA", err)
      this.setState({isLoading: false})
    }

    //--------------------------------------------------------------------------
    //                    WaterCourse Query
    //--------------------------------------------------------------------------

    try{
      this.setState({isLoading: true});
      let waterCourseURL = searchConfig().search.watercourse.url;
      let queryTask = new QueryTask({url: waterCourseURL, requestOptions: {timeout: 2000}});
      let query = new Query();
      query.where = searchConfig().search.watercourse.searchField+"  LIKE '%" + searchString.toUpperCase() + "%'";
      query.returnGeometry = true;
      query.num = 5 ;
      query.outFields = ["*"];
      query.returnCentroid = true;

     let response=await queryTask.execute(query);
     if(response){
        let waterCourseResults = [];
        response.features.forEach(feature => {
          try {
            let suggestion={...feature.attributes};
            suggestion.geometry=feature.geometry;
            suggestion.text= ""+feature.attributes[searchConfig().search.watercourse.searchField]+ " (WATERCOURSE)";
            suggestion.suggestionType="watercourse";
            let alreadyExistingSuggestions=this.props.geocodeOptions.filter((alreadyExisting)=>{
                return (suggestion.text === alreadyExisting.text) && (suggestion[searchConfig().search.watercourse.uniqueIDField]  === alreadyExisting[searchConfig().search.watercourse.uniqueIDField]) &&  (suggestion.suggestionType === alreadyExisting.suggestionType)
            });
            if(!alreadyExistingSuggestions  || alreadyExistingSuggestions.length === 0){
              // this.setState({ geocodeOptions: [...this.state.geocodeOptions, suggestion] });
              this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, suggestion]);
              waterCourseResults.push(suggestion);
            }
          } catch (err) {
            console.error("error","There was a problem fetching the geocode for watercourse")
          }
        });


        if(waterCourseResults.length>0){
          searchSuggestions.push(...waterCourseResults);
          // this.setState({
          //   geocodeOptions: [...this.state.geocodeOptions, searchSuggestions],
          // })
          this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);
        }
      }

      this.setState({
        // geocodeOptions: [...this.state.geocodeOptions, searchSuggestions],
        isLoading: false
      })
      this.props.handleGeocodeOptionsUpdate([...this.props.geocodeOptions, searchSuggestions]);

    } catch(err){
      console.error("There was a problem fetching the watercourse", err)
      this.setState({isLoading: false})
    }

  // END TESTING

  }
  

  processGeocode = async (evt) => {

    //console.log("processing geoCode in Search bar", evt);
    let evtSelected = evt[0];
    if (evtSelected) {
      const [Polygon,Polyline,webMercatorUtils ] = await loadModules(["esri/geometry/Polygon", "esri/geometry/Polyline", "esri/geometry/support/webMercatorUtils"]);

      //---------------POI---------------------------------------------
      if (evtSelected.suggestionType === "poi" && evtSelected.geometry) {
        let geom = JSON.parse(evtSelected.geometry);
        if (geom.x && geom.y) {
          evtSelected.longitude = geom.x;
          evtSelected.latitude = geom.y;
          evtSelected.name = evtSelected[searchConfig().search.poi.searchField];
          evtSelected.type="point";
          this.props.handleGeocodeChange(evtSelected);
        }
      }

      else if (evtSelected.suggestionType === "clc" && evtSelected.geometry) {
        let geom = evtSelected.geometry;
        if (geom) {
          if (geom.spatialReference.isGeographic) {
            geom = webMercatorUtils.geographicToWebMercator(geom);
          }
          evtSelected.geometry=geom;
          let idField;
          this.props.mode === 'public' ? idField = layerConfig().public.clcLayer.idField : idField = layerConfig().internal.clcLayer.idField;
          evtSelected.name = "CADID: " + evtSelected[idField];
          if(geom.extent){
            evtSelected.extent=geom.extent;
          }
          evtSelected.type="polygon";
          this.props.handleGeocodeChange(evtSelected);
        }
      }

      //---------------LOT---------------------------------------------
      else if (evtSelected.suggestionType === "lot" && evtSelected.cadId) {
        let boundaryUrl =  searchConfig().search.boundary.url + evtSelected.cadId + "&Type=lot";
        let boundaryResp = await fetch(boundaryUrl);
        const boundaryResult = await boundaryResp.json();
        if (boundaryResult) {
          try {
            let polygonGeom = new Polygon(boundaryResult[0].geometry);
            if(polygonGeom.spatialReference.isWebMercator){
              polygonGeom= webMercatorUtils.webMercatorToGeographic(polygonGeom);
            }
            if(polygonGeom.extent){
              evtSelected.extent=polygonGeom.extent;
            }
            evtSelected.name = evtSelected[searchConfig().search.lot.searchField];
            evtSelected.geometry=polygonGeom;
            evtSelected.type="polygon";
          } catch (err) {
            console.error("error","There was a problem fetching the geocode for lot/DP")
          }
          this.props.handleGeocodeChange(evtSelected);
        }
      }

      //---------------ADDRESS---------------------------------------------
      else if (evtSelected.suggestionType === "address" && evtSelected.GURASID){
        let boundaryUrl =searchConfig().search.addressdetail.url + evtSelected.GURASID;
        let boundaryResp = await fetch(boundaryUrl);
        const boundaryResult = await boundaryResp.json();
        if (boundaryResult) {
          try {
            evtSelected.longitude = boundaryResult.x;
            evtSelected.latitude = boundaryResult.y;
            evtSelected.name = evtSelected[searchConfig().search.address.searchField];
            evtSelected.type="point";
          } catch (err) {
            console.error("error","There was a problem fetching the geocode for address")
          }
          this.props.handleGeocodeChange(evtSelected);
        }
      }

      //---------------WATERCOURSE---------------------------------------------
      else if(evtSelected.suggestionType === "watercourse" && evtSelected.geometry) {
        let polylineGeom = new Polyline(evtSelected.geometry);
        if(polylineGeom.spatialReference.isWebMercator){
          polylineGeom= webMercatorUtils.webMercatorToGeographic(polylineGeom);
        }
        if(polylineGeom.extent){
          evtSelected.extent=polylineGeom.extent;
        }
        evtSelected.name=evtSelected[searchConfig().search.watercourse.searchField];
        evtSelected.geometry=polylineGeom;
        evtSelected.type="polyline";
        this.props.handleGeocodeChange(evtSelected);
      }

      //---------------LGA---------------------------------------------
      else if(evtSelected.suggestionType === "lga" && evtSelected.geometry){
        let polygonGeom = new Polygon(evtSelected.geometry);
        if(polygonGeom.spatialReference.isWebMercator){
          polygonGeom= webMercatorUtils.webMercatorToGeographic(polygonGeom);
        }
        if(polygonGeom.extent){
          evtSelected.extent=polygonGeom.extent;
        }
        evtSelected.name=evtSelected[searchConfig().search.lga.searchField];
        evtSelected.geometry=polygonGeom;
        evtSelected.type="polygon";
        this.props.handleGeocodeChange(evtSelected);
      }
    } else {
      // console.log("reset the geocode to null");
      this.props.handleGeocodeChange()
    }
  }


  render() {

    const filterByCallback = (option, props) => (
      (option.poiName && option.poiName.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ) ||
      (option.lot &&  option.lot.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.address &&  option.address.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.hydronamestring &&  option.hydronamestring.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.attributes && option.attributes.hydronamestring &&  option.attributes.hydronamestring.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.name &&  option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.lganame &&  option.lganame.toLowerCase().indexOf(props.text.toLowerCase()) !== -1) ||
      (option.cadid && option.cadid.toString().indexOf(props.text.toLowerCase()) !== -1)
    );

    return (
      
      <React.Fragment>
        <div id="searchPane" className="mt-4">
          {/* <h2>Search for a location in NSW:</h2> */}
          <div id="searchDiv" className="form-control mt-4 mb-1">
            
            <AsyncTypeahead
              // key={this.props.key}
              id="search-typeahead"
              allowNew
              isLoading={this.state.isLoading}
              labelKey="text"
              placeholder="Search for a location..."
              clearButton={true}
              filterBy={filterByCallback}
              onSearch={this.handleSearch}
              onChange={this.processGeocode}
              options={this.props.geocodeOptions}
              selected={this.props.selectedGeocode ? [this.props.selectedGeocode] : []}
            />
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export { SearchBar };
